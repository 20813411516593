import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import {languageSlice, languagesSlice} from "./slices/language";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  language: languageSlice.reducer,
  languages: languagesSlice.reducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;