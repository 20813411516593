import { createSlice } from "@reduxjs/toolkit";

const language = localStorage.getItem('i18nextLng');
const initialState = language ? language : 'en';
const languages = [
  { src : '/assets/flags/flag1.png', id:1 , name : 'France' , lang:'fr'},
  { src : '/assets/flags/flag2.png', id:2 , name : 'England', lang:'en'},
  { src : '/assets/flags/flag3.png', id:3 , name : 'Spain', lang:'sp'},
  { src : '/assets/flags/flag4.png', id:4 , name : 'Brazil', lang:'pt'},
  { src : '/assets/flags/flag5.png', id:5 , name : 'Italy', lang:'it'},
  { src : '/assets/flags/flag6.png', id:6 , name : 'Germany', lang:'gr'},
  { src : '/assets/flags/flag7.png', id:7 , name : 'Russia', lang:'ru'},
  { src : '/assets/flags/flag8.png', id:8 , name : 'China', lang:'ch'},
  { src : '/assets/flags/flag9.png', id:9 , name : 'Japan', lang:'jp'},
  { src : '/assets/flags/flag10.png', id:10 , name : 'India', lang:'in'},  
];

export const languageSlice = createSlice({
  name: "currentLanguage",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      return  action.payload;
    }
  },
});

export const languagesSlice = createSlice({
  name: "languages",
  initialState: languages,
  reducers: {
    setLang: (state, action) => {
      return { languages: action.payload };
    }
  },
});

const { reducer, actions } = languageSlice;

export const { setLanguage } = actions
export default reducer;