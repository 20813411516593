import React from 'react';
import store from "./store";
import './index.css';
import './lang/i18t'
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18next from './lang/i18t'
import { Provider } from 'react-redux';
import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById('root');
const clientId = process.env.REACT_APP_GOOGLE_ID;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <Provider store={store}>
      <I18nextProvider i18next={i18next}>
        <Router>
          <App />
        </Router>
      </I18nextProvider>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();