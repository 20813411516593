export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));    
    const AUTHORIZATION = process.env.REACT_APP_JWT_HEADER
    if (user && user.accessToken) {
      if (AUTHORIZATION === 'Authorization') {
        return { Authorization: 'Bearer ' + user.accessToken };
      } else {
        return { 'X-Jwt-Assertion': 'Bearer ' + user.accessToken };
      }
       
    } else if (token && token.accessToken) {
      if (AUTHORIZATION === 'Authorization') {
        return { Authorization: 'Bearer ' + token.accessToken };        
      } else {
        return { 'X-Jwt-Assertion': 'Bearer ' + token.accessToken };
      }
    } else {
      return {};
    }
  }