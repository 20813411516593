import React from 'react'
import Modal from '@mui/material/Modal';
import { Card } from '@mui/material';





const style = (theme) => ({

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  bgcolor: '#171717',
  color: '#FFF',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY: 'auto',
  p: 4,
      [theme.breakpoints.down("lg")]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 500,
        p: 4,
      },
      [theme.breakpoints.down("md")]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 500,
        p: 4,
      },
      [theme.breakpoints.down("mds")]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 400,
        p: 4,
      },
      [theme.breakpoints.down("sm")]: {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 400,
        p: 2,
      }

});

function ModalBanner(props) {

  const imageBasePath = window.location.protocol + "//" + window.location.host + "/assets/images/banner/";

  const handleClose = () => {
    props.setOpenBanner(false);
  }
  


    return (
        <Modal
          open={props.open}
          onClose={handleClose}
          sx={{padding: 0}}
        >        
          <Card sx={style} className="card-modal">
          
            <div>
              <img style={{cursor: 'pointer'}} src={imageBasePath + props.bannerUrl} alt='Banner Live' width={'400px'} onClick={handleClose} />
            </div>
            </Card>
        </Modal>      
    )
  };

export default ModalBanner;
