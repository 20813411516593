import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ButtonWithTimer from '../charts/timerbutton';
import { t } from 'i18next'
import paymentService from '../../services/payment.service';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export default function ReceivedDonationContribution() {
  const MySwal = withReactContent(Swal)
    const newAlert = (title, message, icon) => {
        MySwal.fire({
            title: '<strong>' + title + '</strong>',
            html: '<i>' + message + '</i>',
            icon
            });
  };
  
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [expired, setExpired] = useState(false);
  const [donations, setDonations] = useState();
  const [donationTransaction, setDonationTransaction] = useState('');
  
  useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('user')))    
    }, []);

  const { donationRequestId } = useParams();

  const [donationInfo, setDonationInfo] = useState({
    totalAmount: 0,
    paymentAmount: 0,
    walletId: '',
    username: '',
    donationContributionId: ''
  })

  const getDonors = () => {
    setExpired(false);
    paymentService.getDonors(donationRequestId).then(
      (response) => {
          setDonations(response)

      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          if (error.response && error.response.status === 401) {
              dispatch(logout());
            }
      }
      ); 
  }

  useEffect(() => {
    getDonors();  
  }, [donationRequestId]);


  const navigate = useNavigate();
  
  const gotoDonationsRequest = (userPackageId) => {
    navigate(`/donation/donationRequest`);
  };  

  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  // Função para abrir o modal
  const openModal = () => setIsOpen(true);

  // Função para fechar o modal
  const closeModal = () => setIsOpen(false);

  const currencyFormatter = (value, digits) => {
    if (!Number(value)) return '';    
    const amount = (value).toFixed(digits);    
    const formattedAmount = amount.replace(',', '.');
    
    return `${formattedAmount}`;
  };
  return (
    <>
    <div className='p-lg-5 p-3'>

      <div className='blackCardLG rounded-4 tableOverflow'>
        <p className='font64 text-white mb-4'>{t('DONATIONS RECEIVED')}</p>
        <table className="table w-100">
          <thead>
            <tr  >
              <th scope="col">{t('USERNAME')}</th>
              <th scope="col">{t('AMOUNT USDT')}</th>
              <th scope="col">{t('AMOUNT ENUN')}</th>            
              <th scope="col">{t('RECEIVED AMOUNT ENUN')}</th>
              <th scope="col">{t('HASH')}</th>
              <th scope="col">{t('STATUS')}</th>
            </tr>
          </thead>
          <tbody>
            {donations?.map((donation, index) => (
              <tr key={index}>
                <td>{donation.fromUsername}</td>
                <td>{currencyFormatter(donation.totalAmount, 2)}</td>
                <td>{currencyFormatter(donation.paymentAmount, 8)}</td>
                <td>{donation.payedAmount ? currencyFormatter(donation.payedAmount, 8) : '0.00000000'}</td>
                <td>{donation.transactionAddress ? <a href={`https://bscscan.com/tx/${donation.transactionAddress}`} className="access-badge" target='blank'   >{t('DETAILS')} </a> : null}</td>
                {donation.status === 'PENDING' ? (
                  <td >
                  {expired ? 
                    <p className='ExpiredStatus mb-0'>
                    {t('EXPIRED')  }</p> :
                    <p className='PendingStatus mb-0'>
                      {t(donation.status)}
                    </p>}
                  </td>
                ): donation.status === 'COMPLETED' ? (
                  <td ><p className='CompletedStatus mb-0'

                >{t(donation.status)}</p></td>
                ): donation.status === 'PARTIAL' ? (
                  <td ><p className='PartialStatus mb-0'
                >{t(donation.status)}</p></td>
                ): (
                  <td ><p className='ExpiredStatus mb-0'
                  >{t(donation.status)}</p></td>
                )}
              </tr>
            ))}
          </tbody>
        </table>        
      </div>
      <div className='d-flex justify-content-center align-items-center flex-column mb-3'>  
        <button className="font-24 mt-5 text-white disabledButton rounded-pill px-5 border-0 py-2" onClick={gotoDonationsRequest}     >{t('BACK')}</button>             
      </div>
    </div> 
</>
  )
}
