import {  Route, Routes } from 'react-router-dom';

import SideBar from '../pages/sideBar/page'
import TopBar from '../pages/topBar/page'
import Dashboard from '../pages/dashboard/page'
import Community from '../pages/mycommunity/page'
import Statement from '../pages/statement/page'
import ManualDonation from '../pages/donationmade/page'
import Donation from '../pages/donation/donatioCounter'
import UserProfile from '../pages/userprofile/page'

import React from 'react'
import DonationTransactionList from '../pages/donation/page';
import DonationRequest from '../pages/donationReceived/donationRequest';
import ReceivedDonationContribution from '../pages/donationReceived/receivedDonationContribution';

export default function UserRoutes() {
  return (
    <div>
      <div className='d-flex '>
        <SideBar />                
        <div className='d-flex flex-column remainingWidth main-wrapper'>
          <TopBar />
          <Routes>            
            <Route path="/" element={<Dashboard/> } />
            <Route path="/dashboard"  element={<Dashboard/> } />
            <Route path="/donation" element={<Donation />} />
            <Route path='/donation/donationRequest' element={<DonationRequest />} />
            <Route path='/donation/donationRequest/:donationRequestId' element={<ReceivedDonationContribution />} />
            <Route path='/donation/manual/:donationTransactionId' element={<ManualDonation />} />
            <Route path='/donation/donationTransaction/:userPackageId' element={<DonationTransactionList />} />


            {/*<Route path='/automatic/donation' element={<AutoDonation />} />*/}


            <Route path="/statement" element={<Statement />} /> 
            <Route path="/community" element={<Community/>} />
            <Route path="/profile" element={<UserProfile />} />   

            {/* <Route path="/financial" element={<Finance />} />
            <Route path="/financialPage" element={<Financealpage />} /> */            

            /*{/* routes for crypto */}
            {/* <Route path="/localCrypto" element={<LocalCrypto />} />
            <Route path="/localCrypto/publish" element={<CryptoPublish />} />
            <Route path="/localCrypto/profile" element={<Advertisor />} />
            <Route path="/localCrypto/swap" element={<Swap />} />
            <Route path="/localCrypto/traderProfile" element={<TraderProfile />} />
            <Route path="/localCrypto/tradePage" element={<TradePage />} /> */}
            
            <Route path='*' element={<Dashboard/>} /> {/* This route matches if no other routes match */}
          </Routes>
        </div>                  
      </div>      
    </div>
  )
}
