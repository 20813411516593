import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import paymentService from '../../services/payment.service';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/auth';


export default function DonationTransactionList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatDate = (dateStr) => {
    const date = new Date(dateStr + '-03:00');
    return date.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
};
  const [inputValue, setInputValue] = useState('');

  const [donationTransactionList, setDonationTransactionList] = useState([]);
  const { userPackageId } = useParams();

  useEffect(() => {
    paymentService.getDonationTransactionList(userPackageId).then(
      (response) => {
          setDonationTransactionList(response)

      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          if (error.response && error.response.status === 401) {
              dispatch(logout());
            }
      }
      );   
  }, []);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const goToDonations = (donationTransactionId) => {
    navigate(`/donation/manual/${donationTransactionId}`);
  };

  const currencyFormatter = (value, digits) => {
    if (!Number(value)) return '';
      const amount = new Intl.NumberFormat('en', {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,                      
      }).format(value);
      return `${amount}`;
  };

  const handleDonationRequest = (oldDate) => {
    const oldDatePlusFifteen = new Date(oldDate);
    oldDatePlusFifteen.setDate(oldDatePlusFifteen.getDate() + 15);
    return new Date() >= oldDatePlusFifteen;
  }
  return (
    <div className='mb-100'>

      <div className='blackCardLG rounded-4 tableOverflow'>
        <p className='font64 text-white mb-4'>{t('PACKAGE DETAILS')}</p>
        <table className="table w-100">
          <thead>
            <tr  >
              <th scope="col">{t('START DATE')}</th>
              <th scope="col">{t('AMOUNT USDT')}</th>
              <th scope="col">{t('END DATE')}</th>
              <th scope="col">{t('ACTION')}</th>
              <th scope="col">{t('STATUS')}</th>
            </tr>
          </thead>
          <tbody>
            {donationTransactionList.map((data, index) => (
              <tr key={index}>
                <td>{formatDate(data.startedAt)}</td>
                <td>{currencyFormatter(data.donationAmount, 2)}</td>
                <td>{data.endAt ? formatDate(data.endAt) : null}</td>
                <td>
                {data.status === "PENDING" || data.status === 'PARTIAL' ? (
                        <button onClick={() => goToDonations(data.donationTransactionId)} className='donateBTN ' >{t('DONATE')}</button>) : (
                          data.status === 'COMPLETED' ? (
                            <button 
                              onClick={() => goToDonations(data.donationTransactionId)}
                              className='donateBTN ' >{t('VIEW')}</button> ) : (
                              null
                            ))
                      }
                </td>
                <td ><p className={
                  data.status === 'COMPLETED' ? 'CompletedStatus mb-0' :
                    data.status === 'PARTIAL DONATION' ? 'PartialStatus mb-0' :
                      data.status === 'PENDING' ? 'PendingStatus mb-0' :
                        ''}>{t(data.status)}</p></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>    
    </div>



    
  )
}
