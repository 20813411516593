import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources: {
      en:{
        translation:{   
          'Copied': 'Copied',
          'Copy Key': 'Copy Key',
          'MFA updated successfully!': 'MFA updated successfully!',
          'Invalid MFA code. Try again.': 'Invalid MFA code. Try again.',
          'User data updated successfully!': 'User data updated successfully!',
          'Invalid wallet! Please try again!': 'Invalid wallet! Please try again!',       
          "Invalid hash! Please type or paste only the transaction hash!": 'Invalid hash! Please type or paste only the transaction hash!',
          "Notifications": "Notifications",
          "You received $ ": "You received $ ",
           " for referring ": " for referring ",
          "Banner_URL": "banner-2024-09-23-en.jpg",
          "Create New Package": "Create New Package",
          "Terms and Conditions": "Terms and Conditions",
          "lang": 'en',
          "I accept the": "I accept the",
          "terms and conditions": "terms and conditions.",
          "NEXT DONATION": "NEXT DONATION",
          "NEXT DONATION AT": "NEXT DONATION AT",
          "AWAITING DONOR": "AWAITING DONOR",
          "PARTLY RECEIVED": "PARTLY RECEIVED",
          "BACK": "BACK",
          "Unauthorized! Please login again.": "Unauthorized! Please login again.",
          "Invalid or expired recovery code.": "Invalid or expired recovery code.",
          "All fields are required.": "All fields are required.",
          "The password must be between 6 and 20 characters.": "The password must be between 6 and 20 characters.",
          "The passwords do not match.": "The passwords do not match.",
          "Password recovery link sent to the registered email!": "Password recovery link sent to the registered email!",
          "(If you don't find the email, please check your Spam/Junk folder.)": "(If you don't find the email, please check your Spam/Junk folder.)",
          "Processing...": "Processing...",
          "BONUS": "BONUS",
          "PROFIT": "PROFIT",
          "Yes": "Yes",
          "No": "No",
          "ACTIVE": "ACTIVE",
          "You must complete your donation within a maximum of 25 minutes.": "You must complete your donation within a maximum of 25 minutes.",
          "User Profile": "User Profile",
          "LEVEL": "Level",
          "DATE/TIME": "DATE/TIME",
          "START DATE": "Start Date",
          "END DATE": "End Date",
          "SAVE": "SAVE",
          "Password updated successfully!": "Password updated successfully!",
          "Invalid password. Try again.": "Invalid password. Try again.",
          "NETWORK BONUS": 'Network Bonus',
          "An unexpected error has occurred. Please try again.": "An unexpected error has occurred. Please try again.",
          "Transaction hash already used!": "Transaction hash already used!",
          "MY PACKAGES": "MY PACKAGES",
          "ACTION": "ACTION",
          "VIEW": "VIEW",
          "PARTIAL": "PARTIAL",
          "DETAILS": "DETAILS",
          "PACKAGE DETAILS": "PACKAGE DETAILS",
          "Transaction validated successfully!": "Transaction validated successfully!",
          "Continue with Google":  "Continue with Google",
          "Hello": "Hello",
          "Error: Incorrect username and/or password.": "Incorrect username and/or password.",
          Dashboard: "Dashboard",
          "Donate manually": "Donate manually",
          "Donate automatically": "Donate automatically",
          STATEMENT: "STATEMENT",
          "MAKE YOUR DONATION": "MAKE YOUR DONATION",
          "Choose your donation": "Choose your donation",
          Donation: "Donation",
          Donate: 'Donate',
          "Your donation": "Your donation",
          "Donation Return": "Donation Return",
          "Active Donations": "Active Donations",
          Progress: "Progress",
          "Donations Received": "Donations Received",
          "Donations Made": "Donations Made",
          Community: "Community",
          Donations: "Donations",
          "Refferal link": "Referral Link",
          Statement: "Statement",
          "Showing data": "Showing data",
          entries: "entries",
          "Donations Received": "Donations Received",
          "Network Commissions": "Network Commissions",
          "Donations Made": "Donations Made",
          "Date/Time": "Date/Time",
          User: "User",
          Access: "Access",
          "Hash Transaction": "Hash Transaction",
          Valor: "Value",
          Directs: "Directs",
          Indirect: "Indirect",
          Assets: "Active",
          Inactive: "Inactive",
          Total: "Total",
          "My Community": "My Community",
          User: "User",
          Name: "Name",
          Country: "Country",
          WhatsApp: "WhatsApp",
          Telegram: "Telegram",
          Discord: "Discord",
          Help: "Help",
          Reviews: "Reviews",
          All: "All",
          Positive: "Positive",
          Negative: "Negative",
          "Safe and reliable,Polite and friendly,Quick transaction, Patient": "Safe and reliable, Polite and friendly, Quick transaction, Patient",
          COIN: "COIN",
          PRICE: "PRICE",
          Payment: "Payment",
          INFO: "INFO",
          Information: "Information",
          "Available / Limit": "Available / Limit",
          "Online Sale Advertisement": "Online Sale Advertisement",
          "Online Purchase Advertisement": "Online Purchase Advertisement",
          ONLINE: "ONLINE",
          "ENTERED IN": "ENTERED IN",
          Minutes: "Minutes",
          "Average Release Time": "Average Release Time",
          "Completion Fee": "Completion Fee",
          Trades: "Trades",
          Sell: "Sell",
          Buy: "Buy",
          "All negotiations": "All negotiations",
          negatives: "negatives",
          Positives: "Positives",
          "Positive Ratings": "Positive Ratings",
          back: "back",
          Telegram: "Telegram",
          Whatsapp: "Whatsapp",
          Email: "Email",
          Home: "Home",
          About: "About",
          Blog: "Blog",
          STATUS: "STATUS",
          "MANUAL DONATION": "MANUAL DONATION",
          "AMOUNT ENUN": "AMOUNT ENUN",
          "AMOUNT USDT": "AMOUNT USDT",
          LOGIN: "LOGIN",
          "WALLET ENUN": "WALLET ENUN",
          "After completing your donation, please provide the transaction address below.": "After completing your donation, please provide the transaction address below.",
          "Transaction hash": "Transaction hash",
          Finish: "Finish",
          Donations: "Donations",
          "Referral Link": "Referral Link",
          "Average Payment Time": "Average Payment Time",
          "Social Media": "Social Media",
          "Online Advertisement": "Online Advertisement",
          Comments: "Comments",
          "Fiat Currency Name": "Fiat Currency Name",
          "Fiat Currency Symbol": "Fiat Currency Symbol",
          Register: "Register",
          "Country Name": "Country Name",
          REGISTER: "REGISTER",
          "Available/Limit": "Available/Limit",
          "publish new ad": "publish new ad",
          "LOCAL CRYPTO": "LOCAL CRYPTO",
          "LOCAL CRYPTO P2P": "LOCAL CRYPTO P2P",
          "I have met the requirements": "I have met the requirements",
          "Social network 2": "Social network 2",
          orders: "orders",
          completed: "completed",
          Information: "Information",
          "Trading requirements": "Trading requirements",
          "Minimum Donation": "Minimum Donation",
          "Social network 1": "Social network 1",
          enabled: "enabled",
          "desired amount": "desired amount",
          ENUM: "ENUM",
          BUY: "BUY",
          SELL: "SELL",
          Send: "Send",
          "Financial password": "Financial password",
          Password: "Password",
          "My Wallet": "My Wallet",
          "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.",
          "Learn how to connect": "Learn how to connect",
          "I still don't have a wallet": "I still don't have a wallet",
          "connect wallet": "connect wallet",
          "FINANCIAL DATA": "FINANCIAL DATA",
          Observations: 'Observations',
          'Define the Total Value and Payment Method': 'Define the Total Value and Payment Method',
          'Define type and price': 'Define type and price',
          'Publish Regular Advertisement': 'Publish Regular Advertisement',
          previous: 'previous',
          Next: 'Next',
          'Select the payment method': 'Select the payment method',
          PicPay: 'PicPay',
          'Transfer with specific bank': 'Transfer with specific bank',
          'Bank Transfer': 'Bank Transfer',
          Recommended: 'Recommended',
          Trubit: 'Trubit',
          'Itaú Brazil': 'Itaú Brazil',
          Confirm: 'Confirm',
          'Payment institution': 'Payment institution',
          'Token NameToken contract': 'Token NameToken contract',
          REGISTER: 'REGISTER',
          COMMUNITY: 'COMMUNITY',
          HELP: 'HELP',
          DISCORD: 'DISCORD',
          TELEGRAM: 'TELEGRAM',
          COUNTRY: 'COUNTRY',
          WHATSAPP: 'WHATSAPP',
          NAME: 'NAME',
          USER: 'USER',
          'Showing data': 'Showing data',
          entries: 'entries',
          'MY COMMUNITY': 'MY COMMUNITY',
          Telegram: 'Telegram',
          'Local Crypto': 'Local Crypto',
          'Financial data': 'Financial data',
          'My community': 'My community',
          Statement: 'Statement',
          Dashboard: 'Dashboard',
          Donation: 'Donation',
          'Confirm donation': 'Confirm donation',
          'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': 'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.',
          'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': 'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.',
          'Chosen donation in the amount of': 'Chosen donation in the amount of',
          'Fiat Currency Name': 'Fiat Currency Name',
          'Donate automatically': 'Donate automatically',
          'Choose your donation': 'Choose your donation',
          "DONATE": "DONATE",
          "COMPLETED": "COMPLETED",
          "MAKE DONATION": "MAKE DONATION",
          "LOGIN": "LOGIN",
          "AMOUNT USDT": "AMOUNT USDT",
          "AMOUNT ENUN": "AMOUNT ENUN",
          "MANUAL DONATION": "MANUAL DONATION",
          "STATUS": "STATUS",
          "MAKE YOUR DONATION": "MAKE YOUR DONATION",
          Directs: 'Directs',
          INDIRECT: 'Indirect',
          ASSETS: 'Active',
          INACTIVE: 'Inactive',
          TOTAL: 'Total',
          COMMUNITY: 'Community',
          HELP: 'Help',
          DISCORD: 'Discord',
          'Forgot your password?': 'Forgot your password?',
          Enter: 'Enter',
          'CREATE ACCOUNT': 'CREATE ACCOUNT',
          'NEW LOGIN': 'NEW LOGIN',
          WELCOME: 'WELCOME',
          'LOG INTO YOUR ACCOUNT': 'LOG INTO YOUR ACCOUNT',
          SEND: 'SEND',
          'Please provide your registered email to send the password reset instructions.': 'Please provide your registered email to send the password reset instructions.',
          'Reset your password': 'Reset your password',
          Registration: 'Registration',
          QUALIFICATION: 'QUALIFICATION',
          'DONATIONS RECEIVED': 'DONATIONS RECEIVED',
          'DONATIONS MADE': 'DONATIONS MADE',
          'COMMUNITY GAINS': 'COMMUNITY GAINS',
          'TOTAL WINS': 'TOTAL WINS',
          'BALANCE AVAILABLE': 'BALANCE AVAILABLE',
          'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.',
          'connect wallet': 'Connect wallet',
          'Learn how to connect': 'Learn how to connect',
          "I still don't have a wallet": "I still don't have a wallet",
          'Financial password': 'Financial password',
          Password: 'Password',
          'FINANCIAL DATA': 'FINANCIAL DATA',
          Send: 'Send',
          'REGISTRATION DATA': 'REGISTRATION DATA',
          SECURITY: 'SECURITY',
          'Start by connecting to one of the wallets below.': 'Start by connecting to one of the wallets below.',
          TIKTOK: 'TIKTOK',
          PHONE: 'PHONE',
          'validate Email': 'validate Email',
          FACEBOOK: 'FACEBOOK',
          DISCORD: 'DISCORD',
          INSTAGRAM: 'INSTAGRAM',
          'LAST NAME': 'LAST NAME',
          LOGIN: 'LOGIN',
          'Enter the 2FA code': 'Enter the 2FA code',
          'Manually copy the code': 'Manually copy the code',
          'Download and install the Google Authenticator app.': 'Download and install the Google Authenticator app.',
          'Scan the QR CODE': 'Scan the QR CODE',
          'Two-factor authentication (2FA)': 'Two-factor authentication (2FA)',
          'CONFIRM PASSWORD': 'CONFIRM PASSWORD',
          'NEW PASSWORD': 'NEW PASSWORD',
          'CURRENT PASSWORD': 'CURRENT PASSWORD',
          "My Wallet": "My Wallet",
          "FINANCIAL": "FINANCIAL",
          "My Profile": "My Profile",
          "Change Password": "Change Password",
          "Log Out": "Log Out",
        },
      },
      pt: {
        translation: {
          'Copied': 'Copiado',
          'Copy Key': 'Copiar Chave',
          'MFA updated successfully!': '2FA atualizado com sucesso!',
          'Invalid MFA code. Try again.': 'Código 2FA inválido. Tente novamente.',
          'User data updated successfully!': 'Dados do usuário atualizados com sucesso!',
          'Invalid wallet! Please try again!': 'Carteira inválida! Por favor, tente novamente!',
          "Invalid hash! Please type or paste only the transaction hash!": 'Hash invalida! Por favor, digite ou cole apenas a hash da transação!',
          "Notifications": "Notificações",
          "BONUS RECEIVED": "BÔNUS RECEBIDO",
          "You received $ ": "Você recebeu $ ",
           " for referring ": " pela indicação de ",
          "Banner_URL": "banner-2024-09-23-pt.jpg",
          "Create New Package": "Criar Novo Pacote",
          "Terms and Conditions": "Termos e Condições",
          "lang": 'pt',
          "I accept the": "Concordo com os",
          "terms and conditions": "termos e condições.",
          "NEXT DONATION": "PRÓXIMA DOAÇÃO",
          "NEXT DONATION AT": "PRÓXIMA DOAÇÃO EM",
          "AWAITING DONOR": "AGUARDANDO DOADOR",
          "PARTLY RECEIVED": "PARCIALMENTE RECEBIDO",
          "PENDING BONUS": "BÔNUS PENDENTE",
          "RECEIVE": "RECEBER",
          "BACK": "VOLTAR",
          "RECEIVED AMOUNT ENUN": "VALOR RECEBIDO ENUN",
          "Please fill in your ENUN wallet address.": "Por favor, preencha o endereço da sua carteira ENUN.",
          "Unauthorized! Please login again.": "Não autorizado! Por favor, entre novamente.",
          "Invalid or expired recovery code.": "Código de recuperação inválido ou expirado.",
          "All fields are required.": "Todos os campos são obrigatórios.",
          "The password must be between 6 and 20 characters.": "A senha precisa ter entre 6 e 20 caracteres.",
          "The passwords do not match.": "As senhas não coincidem.",
          "Password recovery link sent to the registered email!": "Link de recuperação de senha enviado para o e-mail registrado!",
          "(If you don't find the email, please check your Spam/Junk folder.)": "(Se você não encontrar o e-mail, por favor, verifique a sua caixa de Spam/Lixo eletrônico.)",
          "Email not found!": "Email não encontrado.",
          "Processing...": "Processando...",
          "Amount USDT": "Valor USDT",
          "Amount ENUN": "Valor ENUN",
          "USERNAME": "USUÁRIO",
          "BONUS": "BÔNUS",
          "PROFIT": "LUCRO",
          "Yes": "Sim",
          "No": "Não",
          "ACTIVE": "ATIVO",
          "You must complete your donation within a maximum of 25 minutes.": "Você deve concluir sua doação em no máximo 25 minutos.",
          "Referral Link": "Link de Indicação",
          "User Profile": "Perfil do Usuário",
          "LEVEL": "NÍVEL",
          "DATE/TIME": "DATA/HORA",
          "START DATE": "Início",
          "END DATE": "Fim",
          "WAITING": "AGUARDANDO",
          "EXPIRED": "EXPIRADO",
          "PENDING": "PENDENTE",
          "COMPLETED": "COMPLETO",
          "SAVE": "SALVAR",
          "Data updated successfully!" : "Dados atualizados com sucesso!",
          "Security Information": "Informações de Segurança",
          "Username": "Usuário",
          "Last Name": "Sobrenome",
          "ENUN Wallet": "Carteira ENUN",
          "Password updated successfully!": "Senha atualizada com sucesso!",
          "Invalid password. Try again.": "Senha incorreta. Tente novamente.",
          "NETWORK BONUS": 'BÔNUS DE REDE',
          "An unexpected error has occurred. Please try again.": "Ocorreu um erro inesperado. Por favor, tente novamente.",
          "Transaction hash already used!": "A hash da transação já está sendo utilizada!",
          "MY PACKAGES": "MEUS PACOTES",
          "ACTION": "AÇÃO",
          "VIEW": "VISUALIZAR",
          "PARTIAL": "PARCIAL",
          "DETAILS": "DETALHES",
          "PACKAGE DETAILS": "DETALHES DO PACOTE",
          "Transaction validated successfully!": "Transação validada com sucesso!",
          "Copied!": "Copiado!",
          "Continue with Google": "Continuar com o Google",
          "language": "idioma",
          "Already have an account?": "Ja possui um cadastro?",
          "Sign in": "Entre",
          "Success!": "Sucesso!",
          "Error!": "Erro!",
          "Email already registered.": "E-mail já cadastrado.",
          "Username already registered.": "Usuário já cadastrado.",
          "Registration successfully completed!": "Cadastro realizado com sucesso!",
          "Only letters and numbers are accepted.": "Somente letras e números são aceitos.",
          "This field is required!": "Este campo é obrigatório.",
          "The passwords do not match.": "As senhas não coincidem.",
          "Hello": "Olá",
          "Error: Incorrect username and/or password.": "Usuário e/ou senha incorretos.",
          Dashboard: "Painel de Controle",
          "Donate manually": "Doar manualmente",
          "Donate automatically": "Doar automaticamente",
          STATEMENT: "EXTRATO",
          "MAKE YOUR DONATION": "FAÇA SUA DOAÇÃO",
          "Choose your donation": "Escolha sua doação",
          Donation: "DOAÇÃO",
          Donate: 'Doar',
          "Your donation": "Sua doação",
          "Donation Return": "Retorno da doação",
          "Active Donations": "Doações Ativas",
          Progress: "Progresso",
          "Donations Received": "Doações Recebidas",
          "Donations Made": "Doações Realizadas",
          Community: "Comunidade",
          Donations: "Doações",
          "Refferal link": "Link de Indicação",
          Donation: "Doação",
          Statement: "Declaração",          
          "Network Commissions": "Comissões de Rede",
          "Date/Time": "Data/Hora",
          User: "Usuário",
          Access: "Acesso",
          "Hash Transaction": "Transação de Hash",
          Valor: "Valor",
          Directs: "Diretos",
          Indirect: "Indiretos",
          Assets: "Ativos",
          Inactive: "Inativos",
          Total: "Total",
          "My Community": "Minha Comunidade",
          "My community": "Minha Comunidade",
          User: "Usuário",
          Name: "Nome",
          Country: "País",
          WhatsApp: "WhatsApp",
          Telegram: "Telegrama",
          Discord: "Discórdia",
          Help: "Ajuda",
          Reviews: "Revisões",
          All: "Tudo",
          Positive: "Positivo",
          Negative: "Negativo",
          "Safe and reliable,Polite and friendly,Quick transaction, Patient":
            "Seguro e confiável, Educado e amigável, Transação rápida, Paciente",
          COIN: "MOEDA",
          PRICE: "PREÇO",
          Payment: "Pagamento",
          INFO: "INFO",
          Information: "Informação",
          "Available / Limit": "Disponível / Limite",
          "Online Sale Advertisement": "Anúncio de Venda Online",
          "Online Purchase Advertisement": "Anúncio de Compra Online",
          ONLINE: "ONLINE",
          "ENTERED IN": "ENTROU EM",
          Minutes: "Minutos",
          "Average Release Time": "Tempo Médio de Liberação",
          "Completion Fee": "Taxa de Conclusão",
          Trades: "Negociações",
          Sell: "Vender",
          Buy: "Comprar",
          "All negotiations": "Todas as negociações",
          negatives: "negativos",
          Positives: "Positivos",
          "Positive Ratings": "Avaliações Positivas",
          back: "voltar",
          Telegram: "Telegrama",
          Whatsapp: "Whatsapp",
          Email: "E-mail",
          Home: "Início",
          About: "Sobre",
          Blog: "Blog",
          STATUS: "STATUS",
          "MANUAL DONATION": "DOAÇÃO MANUAL",
          "AMOUNT ENUN": "VALOR ENUN",
          "AMOUNT USDT": "VALOR USDT",
          LOGIN: "ENTRAR",
          "WALLET ENUN": "CARTEIRA ENUN",
          "After completing your donation, please provide the transaction address below.":
            "Após completar sua doação, por favor, forneça o endereço da transação abaixo.",
          "txn hash": "Hash da transação",
          Finish: "Finalizar",
          Donations: "Doações",
          "Referral Link": "Link de Referência",
          "Average Payment Time": "Tempo Médio de Pagamento",
          "Social Media": "Mídias Sociais",
          "Online Advertisement": "Anúncio Online",
          Comments: "Comentários",
          "Fiat Currency Name": "Nome da Moeda Fiat",
          "Fiat Currency Symbol": "Símbolo da Moeda Fiat",
          Register: "Registrar",
          "Country Name": "Nome do País",
          REGISTER: "REGISTRAR",
          "Available/Limit": "Disponível/Limite",
          "publish new ad": "publicar novo anúncio",
          "LOCAL CRYPTO": "CRYPTO LOCAL",
          "LOCAL CRYPTO P2P": "CRYPTO LOCAL P2P",
          "I have met the requirements": "Eu atendi aos requisitos",
          "Social network 2": "Rede Social 2",
          orders: "pedidos",
          completed: "completado",
          Information: "Informações",
          "Trading requirements": "Requisitos de Negociação",
          "Minimum Donation": "Doação Mínima",
          "Social network 1": "Rede Social 1",
          enabled: "ativado",
          "desired amount": "valor desejado",
          ENUM: "ENUM",
          BUY: "COMPRAR",
          SELL: "VENDER",
          Send: "Enviar",
          "Financial password": "Senha Financeira",
          Password: "Senha",
          "My Wallet": "Minha Carteira",
          "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.":
            "Comece conectando-se a uma das carteiras abaixo. Certifique-se de armazenar com segurança suas chaves privadas ou frases de semente. Nunca compartilhe com ninguém.",
          "Learn how to connect": "Aprenda como se conectar",
          "I still don't have a wallet": "Ainda não tenho uma carteira",
          "connect wallet": "conectar carteira",
          "FINANCIAL DATA": "DADOS FINANCEIROS",
          Observations: 'Observações',
          'Define the Total Value and Payment Method': 'Definir o Valor Total e Método de Pagamento',
          'Define type and price': 'Definir tipo e preço',
          'Publish Regular Advertisement': 'Publicar Anúncio Regular',
          previous: 'Anterior',
          Next: 'Próximo',
          'Select the payment method': 'Selecionar o método de pagamento',
          PicPay: 'PicPay',
          'Transfer with specific bank': 'Transferência com banco específico',
          'Bank Transfer': 'Transferência Bancária',
          Recommended: 'Recomendado',
          Trubit: 'Trubit',
          'Itaú Brazil': 'Itaú Brasil',
          Confirm: 'Confirmar',
          'Payment institution': 'Instituição de Pagamento',
          'Token NameToken contract': 'Nome do Token / Contrato do Token',
          REGISTER: 'REGISTRO',
          COMMUNITY: 'COMUNIDADE',
          HELP: 'Ajuda',
          DISCORD: 'Discord',
          TELEGRAM: 'Telegram',
          COUNTRY: 'País',
          WHATSAPP: 'WhatsApp',
          NAME: 'Nome',
          USER: 'Usuário',          
          'MY COMMUNITY': 'Minha Comunidade',
          COMMUNITY: 'Comunidade',
          HELP: 'Ajuda',
          DISCORD: 'Discord',
          TELEGRAM: 'Telegram',
          COUNTRY: 'País',
          WHATSAPP: 'WhatsApp',
          NAME: 'Nome',
          USER: 'Usuário',
          "Showing data": "Mostrando registros de",
          entries: "registros",
          to: "até",
          of: "de",
          Telegram: 'Telegram',
          'Local Crypto': 'Local Crypto',
          'Financial data': 'Dados Financeiros',
          'My community': 'Minha Comunidade',
          Statement: 'Extrato',
          Dashboard: 'Painel',
          Donation: 'Doação',
          'Confirm donation': 'Confirmar doação',
          'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': 'Atenção: Ao confirmar abaixo, você está ciente de que está fazendo a doação através do sistema Enjoy Union, e concorda com os termos e condições.',
          'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': 'Para fazer uma doação automática, você precisa ter sua Carteira Conectada ativada. Desta forma, com apenas um clique, sua doação será feita automaticamente e você poderá conferir no painel de declarações.',
          'Chosen donation in the amount of': 'Doação escolhida no valor de',
          'Fiat Currency Name': 'Nome da Moeda Fiat',
          'Donate automatically': 'Doar automaticamente',
          'Choose your donation': 'Escolha sua doação',
          "DONATE": "DOAR",
          "COMPLETED": "FINALIZADA",
          "MAKE DONATION": "FAZER DOAÇÃO",
          "LOGIN": "ENTRAR",
          "AMOUNT USDT": "VALOR USDT",
          "AMOUNT ENUN": "VALOR ENUN",
          "MANUAL DONATION": "DOAÇÃO MANUAL",
          "STATUS": "STATUS",
          "MAKE YOUR DONATION": "FAÇA SUA DOAÇÃO",
          Directs: 'Diretos',
          INDIRECT: 'Indiretos',
          ASSETS: 'Ativos',
          INACTIVE: 'Inativos',
          TOTAL: 'Total',
          COMMUNITY: 'COMUNIDADE',
          HELP: 'Ajuda',
          DISCORD: 'Discord',
          Registration: 'CADASTRO',
          "NAME": 'NOME',
          "LOGIN": 'SOBRENOME',
          "COUNTRY": 'PAIS',
          "EMAIL": 'EMAIL',
          "WHATSAPP": 'WHATSAPP',
          "PASSWORD": 'SENHA',
          "CONFIRM PASSWORD": 'CONFIRMAR SENHA',
          REGISTER: 'CADASTRAR',
          "Please provide your registered email to send the password reset instructions.": "Informe seu e-mail cadastrado para enviarmos as instruções de redefinição de senha",
          "Reset your password": "Redefina sua senha",
          "WELCOME": "",
          "LOG INTO YOUR ACCONT": "",
          "NEW LOGIN": "",
          "Online Advertisement": "Anúncio Online",
          "Comments": "comentários",
            "REGISTRATION DATA": "",
            "SECURITY": "SEGURANÇA",
            "FINANCIAL": "FINANCEIROS",
          'Forgot your password?': 'Esqueceu sua senha?',
          Enter: 'Entrar',
          'CREATE ACCOUNT': 'Criar conta',
          'NEW LOGIN': 'Novo login',
          WELCOME: 'Bem-vindo',
          'LOG INTO YOUR ACCOUNT': 'Faça login na sua conta',
          SEND: 'Enviar',
          'Please provide your registered email to send the password reset instructions.': 'Por favor, forneça seu email registrado para enviar as instruções de redefinição de senha.',
          'Reset your password': 'Redefinir sua senha',
          Registration: 'Registro',
          QUALIFICATION: 'QUALIFICAÇÃO',
          'DONATIONS RECEIVED': 'DOAÇÕES RECEBIDAS',
          'DONATIONS MADE': 'DOAÇÕES REALIZADAS',
          'COMMUNITY GAINS': 'GANHOS DA COMUNIDADE',
          'TOTAL WINS': 'TOTAL DE GANHOS',
          'BALANCE AVAILABLE': 'Saldo Disponível',
          'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'Comece conectando-se a uma das carteiras abaixo. Certifique-se de armazenar com segurança suas chaves privadas ou frases de semente. Nunca as compartilhe com ninguém.',
          'connect wallet': 'Conectar carteira',
          'Learn how to connect': 'Aprenda como conectar',
          "I still don't have a wallet": "Ainda não tenho uma carteira",
          'Financial password': 'Senha financeira',
          Password: 'Senha',
          'FINANCIAL DATA': 'Dados Financeiros',
          Send: 'Enviar',
          'REGISTRATION DATA': 'Dados de Registro',
          SECURITY: 'Segurança',
          'Start by connecting to one of the wallets below.': 'Comece conectando-se a uma das carteiras abaixo.',
          TIKTOK: 'TIKTOK',
          PHONE: 'Telefone',
          'validate Email': 'Validar E-mail',
          FACEBOOK: 'FACEBOOK',
          DISCORD: 'DISCORD',
          INSTAGRAM: 'INSTAGRAM',
          'LAST NAME': 'Sobrenome',
          LOGIN: 'Login',
          'Enter the 2FA code': 'Digite o código 2FA',
          'Manually copy the code': 'Copiar o código manualmente',
          'Download and install the Google Authenticator app.': 'Baixe e instale o aplicativo Google Authenticator.',
          'Scan the QR CODE': 'Escanear o CÓDIGO QR',
          'Two-factor authentication (2FA)': 'Autenticação de dois fatores (2FA)',
          'CONFIRM PASSWORD': 'Confirmar Senha',
          "Recovery Code": "Código de Recuperação",
          'NEW PASSWORD': 'Nova Senha',
          'Confirm New Password': 'Confirmar Senha',
          'New Password': 'Nova Senha',
          'CURRENT PASSWORD': 'Senha Atual',
          "My Wallet": "minha carteira",
          "FINANCIAL": "FINANCEIRO",
          "My Profile": "Meu perfil",
          "Change Password": "Alterar a senha",
          "Log Out": "Sair",
          "": "",
          "SPONSOR": "PATROCINADOR",
          "DONATIONS": "DOAÇÕES"
        },
      },
      sp1:{
        translation:{
          
        }
      },
      sp: {
        translation:{
          'Copied': '¡Copiado',
          'Copy Key': 'Copiar Clave',
          'MFA updated successfully!': '¡2FA actualizado con éxito!',
          'Invalid MFA code. Try again.': 'Código 2FA inválido. Inténtalo de nuevo.',
          'User data updated successfully!': '¡Datos del usuario actualizados con éxito!',
          'Invalid wallet! Please try again!': '¡Cartera inválida! ¡Por favor, inténtelo de nuevo!',
          "Invalid hash! Please type or paste only the transaction hash!": '¡Hash inválido! ¡Por favor, escriba o pegue solo el hash de la transacción!',
          "Notifications": "Notificaciones",
          "BONUS RECEIVED": "BONO RECIBIDO",
          "You received $ ": "Recibiste $ ",
           " for referring ": " por la indicación de ",
          "Banner_URL": "banner-2024-09-23-es.jpg",
          "Create New Package": "Crear Nuevo Paquete.",
          "Terms and Conditions": "Términos y Condiciones",
          "lang": 'sp',
          "I accept the": "Acepto los",
          "terms and conditions": "términos y condiciones.",
          "NEXT DONATION": "PRÓXIMA DONACIÓN",
          "NEXT DONATION AT": "PRÓXIMA DONACIÓN EN",
          "AWAITING DONOR": "ESPERANDO DONANTE",
          "PARTLY RECEIVED": "PARCIALMENTE RECIBIDO",
          "PENDING BONUS": "BONO PENDIENTE",
          "RECEIVE": "RECEBER",
          "BACK": "VOLVER",
          "RECEIVED AMOUNT ENUN": "CANTIDAD RECIBIDA ENUN",
          "Please fill in your ENUN wallet address.": "Por favor complete la dirección de su billetera ENUN.",
          "Unauthorized! Please login again.": "¡No autorizado! Por favor inicia sesión nuevamente.",
          "Invalid or expired recovery code.": "Código de recuperación inválido o expirado.",
          "All fields are required.": "Todos los campos son obligatorios.",
          "The password must be between 6 and 20 characters.": "La contraseña debe tener entre 6 y 20 caracteres.",
          "The passwords do not match.": "Las contraseñas no coinciden.",
          "Password recovery link sent to the registered email!": "¡Enlace de recuperación de contraseña enviado al correo electrónico registrado!",
          "(If you don\'t find the email, please check your Spam/Junk folder.)": "(Si no encuentras el correo electrónico, por favor, revisa tu carpeta de Spam/Correo no deseado.)",
          "Email not found!": "Correo electrónico no encontrado.",
          "Processing...": "Procesando...",
          "Amount USDT": "Cantidade USDT",
          "Amount ENUN": "Cantidade ENUN",
          'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': 'Atención: Al confirmar a continuación, usted es consciente de que está realizando la donación a través del sistema Enjoy Union y acepta los términos y condiciones.',
          'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': 'Para realizar una donación automática, debes tener activada tu Connected Wallet. De esta manera, con un solo clic, tu donación se realizará automáticamente y podrás consultarla en el panel de declaraciones.',
          "You must complete your donation within a maximum of 25 minutes.": "Debes completar tu donación en un máximo de 25 minutos.",
          "User Profile": "Perfil del Usuario",
          "DATE/TIME": "FECHA/HORA",
          "START DATE": "Fecha Inicio",
          "END DATE": "Fecha Final",
          "WAITING": "ESPERANDO",
          "SAVE": "GUARDAR",
          "Data updated successfully!" : "¡Datos actualizados exitosamente!",
          "Security Information": "Información de Seguridad",
          "Username": "Usuario",
          "Last Name": "Apellido",
          "ENUN Wallet": "Cartera ENUN",
          "Password updated successfully!": "¡Contraseña actualizada exitosamente!",
          "Invalid password. Try again.": "Contraseña incorrecta. Inténtalo de nuevo.",
          Statement: 'Estado',
          "STATEMENT": "ESTADO DE CUENTA",
          "NETWORK BONUS": 'GANANCIAS DE REDE',
          "An unexpected error has occurred. Please try again.": "Ha ocurrido un error inesperado. Inténtalo de nuevo.",
          "Transaction hash already used!": "¡El hash de transacción ya se está utilizando!",
          "MY PACKAGES": "MIS PAQUETES",
          "ACTION": "Acción",
          "VIEW": "VER",
          "COMPLETED": "FINALIZADO",
          "PARTIAL": "PARCIAL",
          "DETAILS": "DETALLES",
          "PACKAGE DETAILS": "DETALLES DEL PAQUETE",
          "Transaction validated successfully!": "¡Transacción validada exitosamente!",
          "Copied!": "¡Copiado!",
          "Continue with Google": "Continuar con Google",
          "language": "idioma",
          "Already have an account?": "¿Ya tienes una cuenta?",
          "Sign in": "Iniciar sesión",
          "Success!": "¡Éxito!",
          "Error!": "¡Error!",
          "Email already registered.": "Correo electrónico ya registrado.",
          "Username already registered.": "Nombre de usuario ya registrado.",
          "Registration successfully completed!": "¡Registro completado exitosamente!",
          "Only letters and numbers are accepted.": "Sólo se aceptan letras y números.",
          "This field is required!": "¡Este campo es obligatorio!",
          "The passwords do not match.": "Las contraseñas no coinciden..",
          "Hello": "Hola",
          "Page 1": "Página1",
          "ENJOYUNION": "ENJOYUNION",
          "CLUB": "CLUB",
          "REGISTRATION": "REGISTRO",
          "SPONSOR": "PATROCINADOR",
          "NAME": "NOMBRE",
          "LAST NAME": "APELLIDO",
          "SIGN IN": "INICIO DE SESIÓN",
          "COUNTRY": "PAÍS",
          "EMAIL": "CORREO ELECTRÓNICO",
          "WHATSAPP": "WHATSAPP",
          "PASSWORD": "CONTRASEÑA",
          "CONFIRM PASSWORD": "CONFIRMAR CONTRASEÑA",
          "Recovery Code": "Código de Recuperación",
          'NEW PASSWORD': 'Nueva Contraseña',
          'New Password': 'Nueva Contraseña',
          'Confirm New Password': 'Confirmar Contraseña',
          "[REGISTER]": "[REGISTRARSE]",
          "Page 2": "Página2",
          "WELCOME": "BIENVENIDA",
          "NEW SIGN IN": "NUEVO INICIO DE SESIÓN",
          "[Create account]": "[Crear cuenta]",
          "SIGN IN TO YOUR ACCOUNT": "INICIAR SESIÓN EN TU CUENTA",
          "USERNAME": "NOMBRE DE USUARIO",
          "PASSWORD": "CONTRASEÑA",
          "[Enter]": "[Entrar]",
          "Forgot your password?": "¿Olvidaste tu contraseña?",
          "[Continue with Google]": "[Continuar con Google]",
          "[Continue with Facebook]": "[Continuar con Facebook]",
          "[Continue with Apple]": "[Continuar con Apple]",
          "Page 3": "Página3",
          "RESET YOUR PASSWORD": "RESTABLECER TU CONTRASEÑA",
          "Enter your registered email so we can send you instructions to reset your password": "Ingresa tu correo electrónico registrado para que podamos enviarte instrucciones para restablecer tu contraseña",
          "EMAIL": "CORREO ELECTRÓNICO",
          "[Send]": "[Enviar]",
          "Page 4": "Página4",
          "RATING": "CALIFICACIÓN",
          "DONATIONS RECEIVED": "DONACIONES RECIBIDAS",
          "DONATIONS MADE": "DONACIONES REALIZADAS",
          "COMMUNITY EARNINGS": "GANANCIAS DE LA COMUNIDAD",
          "COMMUNITY GAINS": "GANANCIAS DEL EQUIPO",
          "TOTAL EARNINGS": "GANANCIAS TOTALES",
          "AVAILABLE BALANCE": "SALDO DISPONIBLE",
          "Donations": "Donaciones",
          "68%": "68%",
          "$4,000.00": "$4,000.00",
          "Progress limit": "Límite de progreso",
          "Community": "Comunidad",
          "90%": "90%",
          "$1,000.00": "$1,000.00",
          "Donations made": "Donaciones realizadas",
          "8": "8",
          "Donations received": "Donaciones recibidas",
          "6": "6",
          "Referral Link": "Enlace de Referencia",
          "https://enjoyunion.club/invite/enjoyunion": "https://enjoyunion.club/invite/enjoyunion",
          "Active donations": "Donaciones activas",
          "Donation return": "Retorno de donación",
          "$1,500.00": "$1,500.00",
          "Missing": "Faltan",
          "01": "01",
          "DAY": "DÍA",
          "For your donation": "Para tu donación",
          "Your donation": "Tu donación",
          "$0.00": "$0.00",
          "DONATE": "DONAR",
          "Page 5": "Página5",
          "EXTRACT": "EXTRACTO",
          "DONATIONS RECEIVED": "DONACIONES RECIBIDAS",
          "DONATIONS MADE": "DONACIONES REALIZADAS",
          "NETWORK COMMISSIONS": "COMISIONES DE RED",
          "WITHDRAWALS": "RETIROS",
          "USER": "USUARIO",
          "TRANSACTION HASH": "HASH DE TRANSACCIÓN",
          "VALUE": "VALOR",
          "Page 6": "Página6",
          "NETWORK COMMISSIONS": "COMISIONES DE RED",
          "WITHDRAWALS": "RETIROS",
          "USER": "USUARIO",
          "LEVEL": "NIVEL",
          "COMMISSION": "COMISIÓN",
          "TOTAL": "TOTAL",
          "Page 7": "Página7",
          "Account status": "Estado de cuenta",
          "NETWORK FEES": "TARIFAS DE RED",
          "WITHDRAWALS": "RETIROS",
          "USER": "USUARIO",
          "TRANSACTION HASH": "HASH DE TRANSACCIÓN",
          "VALUE": "VALOR",
          "Page 8": "Página8",
          "MY COMMUNITY": "MI COMUNIDAD",
          "My community": "Mi Comunidad",
          "Directs": "Directos",
          "INDIRECT": "Indirectos",
          "ASSETS": "Activos",
          "INACTIVE": "Inactivos",
          "TOTAL": "Total",
          "USER": "USUARIO",
          "NAME": "NOMBRE",
          "COUNTRY": "PAÍS",
          "WHATSAPP": "WHATSAPP",
          "TELEGRAM": "TELEGRAM",
          "DISCORD": "DISCORD",
          "HELP": "AYUDA",
          "COMMUNITY": "COMUNIDAD",
          "Page 9": "Página9",
          "MY COMMUNITY": "MI COMUNIDAD",
          "USER": "USUARIO",
          "NAME": "NOMBRE",
          "COUNTRY": "PAÍS",
          "WHATSAPP": "WHATSAPP",
          "TELEGRAM": "TELEGRAM",
          "DISCORD": "DISCORD",
          "HELP": "AYUDA",
          "COMMUNITY": "COMUNIDAD",
          "Page 10": "Página10",
          "FINANCIAL DATA": "DATOS FINANCIEROS",
          "my wallet | enun | bep - 20": "mi billetera | enun | bep - 20",
          "0x3801410DcEa87EFA2414ECC866EcA": "0x3801410DcEa87EFA2414ECC866EcA",
          "password": "contraseña",
          "financial password": "contraseña financiera",
          "2FA": "2fa",
          "123890": "123890",
          "save": "guardar",
          "connect wallet": "conectar billetera",
          "Start by connecting to one of the wallets below. Be sure to securely store your private keys or recovery phrases and never share them with anyone.": "Comienza conectándote a una de las billeteras a continuación. Asegúrate de guardar tus claves privadas o frases de recuperación de forma segura, y nunca las compartas con nadie.",
          "I don't have a wallet yet": "Todavía no tengo una billetera",
          "learn how to connect": "aprende cómo conectar",
          "Page 11": "Página11",
          "REGISTRATION DETAILS": "DATOS DE REGISTRO",
          "SECURITY": "SEGURIDAD",
          "FINANCE": "FINANZAS",
          "SIGN IN": "INICIO DE SESIÓN",
          "João Victor": "João Victor",
          "LAST NAME": "APELLIDO",
          "EMAIL": "CORREO ELECTRÓNICO",
          "JOAO@TESTE123.COM": "JOAO@TESTE123.COM",
          "[validate email]": "[validar correo electrónico]",
          "INSTAGRAM": "INSTAGRAM",
          "DE CASTILHO": "DE CASTILHO",
          "PHONE": "TELÉFONO",
          "+55 12 992007383": "+55 12 992007383",
          "DISCORD": "DISCORD",
          "FACEBOOK": "FACEBOOK",
          "VK": "VK",
          "TIKTOK": "TIKTOK",
          "Page 12": "Página12",
          "REGISTRATION DETAILS": "DATOS DE REGISTRO",
          "REGISTRATION": "REGISTRO",
          "SECURITY": "SEGURIDAD",
          "FINANCE": "FINANZAS",
          "SIGN IN": "INICIO DE SESIÓN",
          "João Victor": "João Victor",
          "LAST NAME": "APELLIDO",
          "João Victor": "João Victor",
          "INSTAGRAM": "INSTAGRAM",
          "DE CASTILHO": "DE CASTILHO",
          "DISCORD": "DISCORD",
          "(empty field)": "(campo vacío)",
          "FACEBOOK": "FACEBOOK",
          "(empty field)": "(campo vacío)",
          "VK": "VK",
          "(empty field)": "(campo vacío)",
          "TIKTOK": "TIKTOK",
          "(empty field)": "(campo vacío)",
          "EMAIL": "CORREO ELECTRÓNICO",
          "JOAO@TESTE123.COM": "JOAO@TESTE123.COM",
          "[validate email]": "[validar correo electrónico]",
          "Please verify the code sent to your email and enter it in the field below:": "verifica el código enviado a tu correo electrónico e ingrésalo en el campo a continuación:",
          "[Send]": "[enviar]",
          "PHONE": "TELÉFONO",
          "+55 12 992007383": "+55 12 992007383",
          "Page 13": "Página13",
          "SECURITY DETAILS": "DATOS DE SEGURIDAD",
          "Registration Details": "datos de registro",
          "Security": "Seguridad",
          "Financial": "financiero",
          "Current Password": "contraseña actual",
          "New Password": "Nueva contraseña",
          "Confirm Password": "confirmar contraseña",
          "Two-Factor Authentication (2FA)": "autenticación de dos factores (2FA)",
          "Scan QR Code": "Escanear el CÓDIGO QR",
          "Download and install Google Authenticator app": "Descargar e instalar la aplicación autenticadora de Google",
          "[Download on] App Store [Get on] Google Play": "[Descargar en] App Store [Obtener en] Google Play",
          "Copy MANUAL CODE": "copiar el CÓDIGO MANUAL",
          "SAAJAL02014U3": "SAAJAL02014U3",
          "Enter 2FA Code": "Ingresa el código 2FA",
          "Save": "guardar",
          "Page 14": "Página14",
          "MAKE YOUR DONATION": "HACER TU DONACIÓN",
          "Choose your donation": "Elige tu donación",
          "$5": "$5",
          "Donate manually Donate automatically": "Donar manualmente Donar automáticamente",
          "Page 15": "Página15",
          "MAKE YOUR DONATION": "HACER TU DONACIÓN",
          "Choose your donation": "Elige tu donación",
          "$5": "$5",
          "Donate manually Donate automatically": "Donar manualmente Donar automáticamente",
          "Selected donation amount": "Donación elegida por un monto de",
          "$5.00": "$5.00",
          "(8.62068966 ENUM)": "(8.62068966 ENUM)",
          "Attention: By confirming below, you acknowledge that you are making a donation through the Enjoy Union system and agree to the terms and conditions.": "Atención: Al confirmar abajo, reconoces que estás haciendo una donación a través del sistema Enjoy Union y aceptas los términos y condiciones.",
          "Confirm donation": "confirmar donación",
          "Page 16": "Página16",
          "PROCEED WITH DONATION": "PROSEGUIR CON LA DONACIÓN",
          "SIGN IN VALUE USDT VALUE ENUM MANUAL DONATION STATUS": "INICIO DE SESIÓN VALOR USDT VALOR ENUM DONACIÓN MANUAL ESTADO",
          "Victor $2,000 3,448.2758 donate Completed": "Víctor $2,000 3,448.2758 donar Completado",
          "Floyd Miles $2,000 3,448.2758 donate Partial": "Floyd Miles $2,000 3,448.2758 donar Parcial",
          "Ronald Richards $2,000 3,448.2758 donate Pending": "Ronald Richards $2,000 3,448.2758 donar Pendiente",
          "CANCEL DONATION": "CANCELAR DONACIÓN",
          "You need to complete your donation within a maximum period of 25 minutes.": "Es necesario completar tu donación dentro de un período máximo de 25 minutos.",
          "Page 17": "Página17",
          "PROCEED WITH DONATION": "PROSEGUIR CON LA DONACIÓN",
          "SIGN IN: VICTOR": "INICIO DE SESIÓN: VICTOR",
          "VALUE USDT: $2,000": "VALOR USDT: $2,000",
          "VALUE ENUM: 3,448.2758 ENUM": "VALOR ENUM: 3,448.2758 ENUM",
          "WALLET ENUM: 0x1B92334dB8d3B07E0031F5...": "BILLETERA ENUM: 0x1B92334dB8d3B07E0031F5...",
          "After completing your donation, report the transaction hash below.": "Después de completar tu donación, informa la dirección de transacción a continuación.",
          "Transaction hash:": "hash de transacción:",
          "[finish]": "[finalizar]",
          "Page 18": "Página18",
          "MAKE A DONATION": "HACER UNA DONACIÓN",
          "SIGN IN: VICTOR": "INICIO DE SESIÓN: VICTOR",
          "VALUE USDT: $2,000": "VALOR USDT: $2,000",
          "VALUE ENUM: 3,448.2758 ENUM": "VALOR ENUM: 3,448.2758 ENUM",
          "WALLET ENUM: 0x1B92334dB8d3B07E0031F5...": "BILLETERA ENUM: 0x1B92334dB8d3B07E0031F5...",
          "After completing your donation, report the transaction hash below.": "Después de completar tu donación, informa la dirección de transacción a continuación.",
          "Transaction hash:": "hash de transacción:",
          "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]": "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]",
          "[finish]": "[finalizar]",
          "Page 19": "Página19",
          "MAKE A DONATION": "HACER UNA DONACIÓN",
          "SIGN IN: VICTOR": "INICIO DE SESIÓN: VICTOR",
          "VALUE USDT: $2,000": "VALOR USDT: $2,000",
          "VALUE ENUM: 3,448.2758 ENUM": "VALOR ENUM: 3,448.2758 ENUM",
          "WALLET ENUM: 0x1B92334dB8d3B07E0031F5...": "BILLETERA ENUM: 0x1B92334dB8d3B07E0031F5...",
          "After completing your donation, report the transaction hash below.": "Después de completar tu donación, informa la dirección de transacción a continuación.",
          "Transaction hash:": "hash de transacción:",
          "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]": "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]",
          "[finish]": "[finalizar]",
          "Page 20": "Página20",
          "MAKE YOUR DONATION": "HACER TU DONACIÓN",
          "Choose your donation": "Elige tu donación",
          "$5": "$5",
          "[Donate manually] [Donate automatically]": "[Donar manualmente] [Donar automáticamente]",
          "Selected donation amount": "Donación elegida por un monto de",
          "$5.00": "$5.00",
          "(8.62068966 ENUM)": "(8.62068966 ENUM)",
          "To make the automatic donation, it is necessary to have your connected active wallet. This way, with a single click, your donation will be made automatically and you can verify it in the statements panel. Attention: By confirming below, you acknowledge that you are making a donation through the Enjoy Union system, accepting the terms and conditions.": "Para realizar la donación automática, es necesario tener tu billetera conectada activa. De esta manera, con un solo clic, tu donación se realizará automáticamente y podrás verificarla en el panel de extractos. Atención: Al confirmar abajo, reconoces que estás haciendo una donación a través del sistema Enjoy Union, aceptando los términos y condiciones.",
          "[confirm donation]": "[confirmar donación]",
          "Page 21": "Página21",
          "DONATION COMPLETED": "DONACIÓN COMPLETADA",
          "SIGN IN VALUE USDT VALUE ENUM TRANSACTION HASH STATUS": "INICIO DE SESIÓN VALOR USDT VALOR ENUM HASH DE TRANSACCIÓN ESTADO",
          "Victor $2.000 3.448,2758 [HASH] Completed": "Víctor $2.000 3.448,2758 [HASH] Completado",
          "Floyd Miles $2.000 3.448,2758 [HASH] Completed": "Floyd Miles $2.000 3.448,2758 [HASH] Completado",
          "Ronald Richards $2.000 3.448,2758 [HASH] Completed": "Ronald Richards $2.000 3.448,2758 [HASH] Completado",
          "Page 22": "Página22",
          "CRYPTO LOCAL P2P": "CRYPTO LOCAL P2P",
          "Buy | Sell": "comprar | vender",
          "[ENUM] [USDT] [BTC] [ETH] [BNB] [ADA] [BRL] � [Post new ad]": "[ENUM] [USDT] [BTC] [ETH] [BNB] [ADA] [BRL] � [Publicar nuevo anuncio]",
          "Available/Limit": "Disponible/Límite",
          "Advertiser Price Available/Limit Payment INFO": "Anunciante Precio Disponible/Límite Pago INFO",
          "João Victor 4.975 BRL 34,935.10 USDT PIX Information": "João Victor 4.975 BRL 34,935.10 USDT PIX Información",
          "368 orders 99.50% completed R$10,000.00 - R$150,000.00 99.73%": "368 pedidos 99.50% completado R$10,000.00 - R$150,000.00 99.73%",
          "The image shows a peer-to-peer cryptocurrency trading platform interface. The fields display the advertiser's name, the price in Brazilian reais (BRL), the available quantity or limit in USDT (Tether), and the payment method, which is PIX (a Brazilian instant payment system). The information button likely leads to more details about the advertiser's offer.": "La imagen muestra una interfaz de plataforma de comercio de criptomonedas de igual a igual (P2P). Los campos muestran el nombre del anunciante, el precio en reales brasileños (BRL), la cantidad disponible o el límite en USDT (Tether) y el método de pago, que es PIX (un sistema de pago instantáneo brasileño). Es probable que el botón de información conduzca a más detalles sobre la oferta del anunciante.",
          "Page 23": "Página23",
          "CRYPTO LOCAL P2P": "CRYPTO LOCAL P2P",
          "Trading Requirements": "Requisitos comerciales",
          "Minimum Donation $5": "Donación mínima $5",
          "2FA": "2FA",
          "Email": "Correo electrónico",
          "Social Network 1": "Red social 1",
          "Social Network 2": "Red social 2",
          "Enabled": "Habilitado",
          "I have complied with the requirements": "He cumplido con los requisitos",
          "Payment": "Pago",
          "PIX": "PIX",
          "Trade": "Comercio",
          "[Info]": "[Información]",
          "The button at the bottom says 'I have complied with the requirements.' The context suggests that this is a screen from a peer-to-peer cryptocurrency trading platform, where the user must meet certain requirements to participate in trading. The 'Enabled' status next to each requirement indicates that the user has met these conditions. 'PIX' refers to a payment method, likely specific to the region where the platform operates.": "El botón en la parte inferior dice 'He cumplido con los requisitos'. El contexto sugiere que esta es una pantalla de una plataforma de comercio de criptomonedas de igual a igual (P2P), donde el usuario debe cumplir con ciertos requisitos para participar en el comercio. El estado 'Habilitado' junto a cada requisito indica que el usuario ha cumplido estas condiciones. 'PIX' se refiere a un método de pago, probablemente específico para la región donde opera la plataforma.",
          "Page 24": "Página24",
          "Post Regular Ad": "Publicar anuncio normal",
          "Set Type and Price": "Definir tipo y precio",
          "I want to buy": "Quiero comprar",
          "Assets": "Activos",
          "USDT": "USDT",
          "With fiat currency": "Con moneda fiat",
          "→ BRL": "→ BRL",
          "Price type": "Tipo de precio",
          "Fixed": "Fijo",
          "Fixed": "Fijo",
          "The fixed price must be between 4.464 - 5.456": "El precio fijo debe estar entre 4.464 - 5.456",
          "Your price": "Tu precio",
          "R$ 4,960": "R$ 4.960",
          "More expensive order": "Pedido más caro",
          "R$ 4,985": "R$ 4.985",
          "Set total value and payment method": "Definir el valor total y el método de pago",
          "Comments": "Observaciones",
          "I want to buy": "Quiero comprar",
          "Next": "Siguiente",
          "The screen appears to be for a cryptocurrency trading platform where users can post ads to buy or sell digital assets like USDT (Tether) for fiat currency, in this case, the Brazilian Real (BRL). Users can set a fixed price for their transactions.": "La pantalla parece ser para una plataforma de comercio de criptomonedas donde los usuarios pueden publicar anuncios para comprar o vender activos digitales como USDT (Tether) por moneda fiat, en este caso, el Real brasileño (BRL). Los usuarios pueden establecer un precio fijo para sus transacciones.",
          "Page 25": "Página25",
          "Post regular ad": "Publicar anuncio normal",
          "Define type and price": "Definir tipo y precio",
          "Define total value and payment method": "Definir el valor total y el método de pago",
          "USDT": "USDT",
          "With fiat currency": "Con moneda fiat",
          "→ BRL": "→ BRL",
          "Price type": "Tipo de precio",
          "Fixed [Selected] Floating": "Fijo [Seleccionado] Flotante",
          "Fixed": "Fijo",
          "[Decrease button] 4,960 [Increase button]": "[Botón de disminución] 4.960 [Botón de aumento]",
          "The fixed price must be between 4.464 - 5.456": "El precio fijo debe estar entre 4.464 - 5.456",
          "Your price": "Tu precio",
          "R$ 4,960": "R$ 4.960",
          "More expensive order": "Pedido más caro",
          "R$ 4,985": "R$ 4.985",
          "Next": "Siguiente",
          "I want to sell": "Quiero vender",
          "This appears to be a user interface for posting an ad to buy or sell cryptocurrencies, with options to select the currency type, price, and payment method.": "Esto parece ser una interfaz de usuario para publicar un anuncio para comprar o vender criptomonedas, con opciones para seleccionar el tipo de moneda, el precio y el método de pago.",
          "Page 26": "Página26",
          "Post regular ad": "Publicar anuncio normal",
          "Define type and price": "Definir tipo y precio",
          "Define total value and payment method": "Definir el valor total y el método de pago",
          "USDT": "USDT",
          "With fiat currency": "Con moneda fiat",
          "Register": "Registrar",
          "[Input field for token name]": "[Campo de entrada para el nombre del token]",
          "[Input field for token contract]": "[Campo de entrada para el contrato del token]",
          "[Register button]": "[Botón de registro]",
          "Your price": "Tu precio",
          "R$ 4,960": "R$ 4.960",
          "More expensive order": "Pedido más caro",
          "R$ 4,985": "R$ 4.985",
          "Next": "Siguiente",
          "I want to sell": "Quiero vender",
          "This appears to be a user interface for posting an ad to buy or sell cryptocurrencies, with options to select the currency type, price, and payment method, as well as a section to register a new token.": "Esto parece ser una interfaz de usuario para publicar un anuncio para comprar o vender criptomonedas, con opciones para seleccionar el tipo de moneda, el precio y el método de pago, así como una sección para registrar un nuevo token.",
          "Page 27": "Página27",
          "Post regular ad": "Publicar anuncio normal",
          "Set total amount and payment method": "Establecer el monto total y el método de pago",
          "Comments": "Comentarios",
          "Define type and price": "Definir tipo y precio",
          "I want to buy": "Quiero comprar",
          "I want to sell": "Quiero vender",
          "Asset": "Activo",
          "USDT": "USDT",
          "With fiat currency": "Con moneda fiat",
          "Price type": "Tipo de precio",
          "Fixed": "lo hizo",
          "Floating": "Flotante",
          "Fixed": "lo hizo",
          "The fixed price must be between 4,464-5,456": "El precio fijo debe estar entre 4,464-5,456",
          "Your price": "Tu precio",
          "Next": "Siguiente",
          "More expensive order": "Pedido más caro",
          "Page 28": "Página28",
          "Post regular ad": "Publicar anuncio normal",
          "Define type and price": "Definir tipo y precio",
          "Set total amount and payment method": "Establecer el monto total y el método de pago",
          "Comments": "Comentarios",
          "I want to buy": "Quiero comprar",
          "I want to sell": "Quiero vender",
          "Asset": "Activo",
          "With fiat currency": "Con moneda fiat",
          "USDT": "USDT",
          "Register": "Registrar",
          "Fixed": "Fijo",
          "Floating": "Flotante",
          "Fixed": "Fijo",
          "4,960": "4,960",
          "The fixed price must be between 4,464-5,456": "El precio fijo debe estar entre 4,464-5,456",
          "Your price": "Tu precio",
          "Next": "Siguiente",
          "Order more": "Ordenar más",
          "Page 29": "Página29",
          "Post regular ad": "Publicar anuncio normal",
          "Define type and price": "Definir tipo y precio",
          "Set total amount and payment method": "Establecer el monto total y el método de pago",
          "Comments": "Comentarios",
          "I want to buy": "Quiero comprar",
          "I want to sell": "Quiero vender",
          "Asset": "Activo",
          "With fiat currency": "Con moneda fiat",
          "USDT": "USDT",
          "→": "→",
          "Name of fiat currency": "Nombre de la moneda fiduciaria",
          "Fiat currency acronym": "Acrónimo de moneda fiduciaria",
          "Fixed": "Fijo",
          "The fixed price must be": "El precio fijo debe ser",
          "Register": "REGISTRAR",
          "Your price": "Tu precio",
          "Next": "Siguiente",
          "More expensive order": "Pedido más caro",
          "Page 30": "Página30",
          "Buy": "compra",
          "Sell": "vender",
          "ENUN": "ENUN",
          "USDT": "USDT",
          "Desired quantity": "Cantidad deseada",
          "ENUN": "ENUN",
          "All regions": "Todas las regiones",
          "Price": "Precio",
          "Advertiser": "Anunciante",
          "João victor": "João victor",
          "368 orders | 99.50% completed": "368 pedidos | 99.50% completado",
          "199.73%": "199.73%",
          "Availability/Limit": "Disponibilidad/Límite",
          "Payment": "Pago",
          "Information": "Información",
          "João victor": "João victor",
          "368 orders | 99.50% completed": "368 pedidos | 99.50% completado",
          "Information": "Información",
          "199.73%": "199.73%",
          "João victor": "João victor",
          "368 orders | 99.50% completed": "368 pedidos | 99.50% completado",
          "Information": "Información",
          "199.73%": "199.73%",
          "João victor": "João victor",
          "368 orders | 99.50% completed": "368 pedidos | 99.50% completado",
          "Information": "Información",
          "199.73%": "199.73%",
          "John Victor": "John Victor",
          "Information": "Información",
          "368 orders": "368 pedidos",
          "99.50% completed": "99.50% completado",
          "199.73%": "199.73%",
          "Showing data": "Mostrando registros del",
          entries: "registros",
          to: "al",
          of: "de",
          Dashboard: 'Tablero',
          "Transaction hash": "Hash de transacción",
          Dashboard: 'Tablero',
          'Donate manually': 'Donar manualmente',
          'Donate automatically': 'Donar automáticamente',
          STATEMENT: 'DECLARACIÓN',
          'MAKE YOUR DONATION': 'HAGA SU DONACIÓN',
          'Choose your donation': 'Elija su donación',
          Donation: 'Donación',
          Donate: 'Donar',
          'Your donation': 'Su donación',
          'Donation Return': 'Retorno de donación',
          'Active Donations': 'Donaciones activas',
          Progress: 'Progreso',
          'Donations Received': 'Donaciones recibidas',
          'Donations Made': 'Donaciones realizadas',
          Community: 'Comunidad',
          Donations: 'Donaciones',
          'Refferal link': 'Enlace de referencia',
          Statement: 'Declaración',
          'Showing data': 'Mostrando datos',
          entries: 'entradas',
          'Network Commissions': 'Comisiones de red',
          'Date/Time': 'Fecha/Hora',
          User: 'Usuario',
          Access: 'Acceso',
          'Hash Transaction': 'Transacción hash',
          Valor: 'Valor',
          Directs: 'Directos',
          Indirect: 'Indirectos',
          Assets: 'Activos',
          Inactive: 'Inactivo',
          Total: 'Total',
          'My Community': 'Mi comunidad',
          Name: 'Nombre',
          Country: 'País',
          WhatsApp: 'WhatsApp',
          Telegram: 'Telegram',
          Discord: 'Discordia',
          Help: 'Ayuda',
          Reviews: 'Reseñas',
          All: 'Todo',
          Positive: 'Positivo',
          Negative: 'Negativo',
          'Safe and reliable,Polite and friendly,Quick transaction, Patient': 'Seguro y confiable, Educado y amigable, Transacción rápida, Paciente',
          COIN: 'MONEDA',
          PRICE: 'PRECIO',
          Payment: 'Pago',
          INFO: 'INFO',
          Information: 'Información',
          'Available / Limit': 'Disponible / Límite',
          'Online Sale Advertisement': 'Anuncio de venta en línea',
          'Online Purchase Advertisement': 'Anuncio de compra en línea',
          ONLINE: 'EN LÍNEA',
          'ENTERED IN': 'INGRESADO EN',
          Minutes: 'Minutos',
          'Average Release Time': 'Tiempo promedio de liberación',
          'Completion Fee': 'Tarifa de finalización',
          Trades: 'Negocios',
          Sell: 'Vender',
          Buy: 'Comprar',
          'All negotiations': 'Todas las negociaciones',
          negatives: 'negativos',
          Positives: 'Positivos',
          'Positive Ratings': 'Calificaciones positivas',
          back: 'volver',          
          Telegram: 'Telegram',
          Whatsapp: 'Whatsapp',
          Email: 'Correo electrónico',
          Home: 'Inicio',
          About: 'Acerca de',
          Blog: 'Blog',
          STATUS: 'Estado',
          'MANUAL DONATION': 'DONACIÓN MANUAL',
          'AMOUNT ENUN': 'CANTIDADE ENUN',
          'AMOUNT USDT': 'CANTIDADE USDT',
          LOGIN: 'INICIAR SESIÓN',
          'WALLET ENUN': 'CARTERA ENUN',
          'After completing your donation, please provide the transaction address below.': 'Después de completar su donación, proporcione la dirección de la transacción a continuación.',
          'txn hash': 'hash de transacción',
          Finish: 'Finalizar',
          'Referral Link': 'Enlace de Referencia',
          'Average Payment Time': 'Tiempo Promedio de Pago',
          'Social Media': 'Redes Sociales',
          'Online Advertisement': 'Anuncio en Línea',
          Comments: 'Comentarios',
          'Fiat Currency Name': 'Nombre de la Moneda Fiat',
          'Fiat Currency Symbol': 'Símbolo de la Moneda Fiat',
          Register: 'Registro',
          'Country Name': 'Nombre del País',
          REGISTER: 'REGISTRO',
          'Available/Limit': 'Disponible/Límite',
          'publish new ad': 'publicar nuevo anuncio',
          'LOCAL CRYPTO': 'CRYPTO LOCAL',
          'LOCAL CRYPTO P2P': 'CRYPTO LOCAL P2P',
          'I have met the requirements': 'He cumplido con los requisitos',
          'Social network 2': 'Red Social 2',
          orders: 'pedidos',
          completed: 'completado',
          Information: 'Informaciones',
          'Trading requirements': 'Requisitos de Negociación',
          'Minimum Donation': 'Donación Mínima',
          'Social network 1': 'Red Social 1',
          enabled: 'activado',
          'desired amount': 'valor deseado',
          ENUM: 'ENUM',
          BUY: 'COMPRAR',
          SELL: 'VENDER',
          Send: 'Enviar',
          'Financial password': 'Contraseña Financiera',
          Password: 'Contraseña',
          'My Wallet': 'Mi Cartera',
          'Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'Empiece conectándose a una de las carteras que se muestran a continuación. Asegúrese de almacenar de forma segura sus claves privadas o frases semilla. Nunca las comparta con nadie.',
          'Learn how to connect': 'Aprenda cómo conectar',
          "I still don't have a wallet": 'Todavía no tengo una cartera',
          'connect wallet': 'conectar cartera',
          'FINANCIAL DATA': 'DATOS FINANCIEROS',
          Observations: 'Observaciones',
          'Define the Total Value and Payment Method': 'Defina el Valor Total y Método de Pago',
          'Define type and price': 'Defina tipo y precio',
          'Publish Regular Advertisement': 'Publicar Anuncio Regular',
          previous: 'anterior',
          Next: 'Próximo',
          'Select the payment method': 'Seleccione el método de pago',
          PicPay: 'PicPay',
          'Transfer with specific bank': 'Transferencia con banco específico',
          'Bank Transfer': 'Transferencia bancaria',
          Recommended: 'Recomendado',
          Trubit: 'Trubit',
          'Itaú Brazil': 'Itaú Brasil',
          Confirm: 'Confirmar',
          'Payment institution': 'Institución de pago',
          'Token NameToken contract': 'Nombre del Token / Contrato del Token',
          COMMUNITY: 'COMUNIDAD',
          DISCORD: 'DISCORDIA',
          TELEGRAM: 'TELEGRAMA',
          COUNTRY: 'PAÍS',
          WHATSAPP: 'WHATSAPP',
          NAME: 'NOMBRE',
          USER: 'USUARIO',
          'Showing data': 'Mostrando datos',
        
        
          entries: 'entradas',
          'MY COMMUNITY': 'MI COMUNIDAD',
          'Forgot your password?': '¿Olvidaste tu contraseña?',
          Enter: 'Entrar',
          'CREATE ACCOUNT': 'CREAR CUENTA',
          'NEW LOGIN': 'NUEVO INICIO DE SESIÓN',
          WELCOME: 'BIENVENIDO',
          'LOG INTO YOUR ACCOUNT': 'INICIA SESIÓN EN TU CUENTA',
          SEND: 'ENVIAR',
          'Please provide your registered email to send the password reset instructions.': 'Proporcione su correo electrónico registrado para enviar las instrucciones de restablecimiento de contraseña.',
          'Reset your password': 'Restablecer tu contraseña',
          Registration: 'Registro',
          QUALIFICATION: 'CUALIFICACIÓN',
          'COMMUNITY GAINS': 'GANANCIAS DE LA COMUNIDAD',
          'TOTAL WINS': 'GANANCIAS TOTALES',
          'BALANCE AVAILABLE': 'SALDO DISPONIBLE',
          'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'Empiece conectándose a una de las carteras que se muestran a continuación. Asegúrese de almacenar de forma segura sus claves privadas o frases semilla. Nunca las comparta con nadie.',
          'connect wallet': 'Conectar cartera',
          'Learn how to connect': 'Aprenda cómo conectar',
          "I still don't have a wallet": 'Todavía no tengo una cartera',
          'Financial password': 'Contraseña financiera',
          Password: 'Contraseña',
          'FINANCIAL DATA': 'DATOS FINANCIEROS',
          Send: 'Enviar',
          'REGISTRATION DATA': 'DATOS DE REGISTRO',
          SECURITY: 'SEGURIDAD',
          'Start by connecting to one of the wallets below.': 'Empiece conectándose a una de las carteras que se muestran a continuación.',
          TIKTOK: 'TIKTOK',
          PHONE: 'TELÉFONO',
          'validate Email': 'Validar correo electrónico',
          FACEBOOK: 'FACEBOOK',
          DISCORD: 'DISCORDIA',
          INSTAGRAM: 'INSTAGRAM',
          'LAST NAME': 'APELLIDO',
          LOGIN: 'INICIAR SESIÓN',
          'Enter the 2FA code': 'Ingrese el código 2FA',
          'Manually copy the code': 'Copiar el código manualmente',
          'Download and install the Google Authenticator app.': 'Descargue e instale la aplicación Google Authenticator.',
          'Scan the QR CODE': 'Escanear el CÓDIGO QR',
          'Two-factor authentication (2FA)': 'Autenticación de dos factores (2FA)',
          'CONFIRM PASSWORD': 'CONFIRMAR CONTRASEÑA',
          'NEW PASSWORD': 'NUEVA CONTRASEÑA',
          'CURRENT PASSWORD': 'CONTRASEÑA ACTUAL',
          'My Profile': 'Mi perfil',
          'Change Password': 'Cambiar contraseña',
          'Log Out': 'Cerrar sesión'
        },
      },
      ch: {
        translation:{
          Dashboard: '仪表板',
          'Donate manually': '手动捐赠',
          'Donate automatically': '自动捐赠',
          STATEMENT: '报表',
          'MAKE YOUR DONATION': '进行捐赠',
          'Choose your donation': '选择您的捐赠',
          Donation: '捐赠',
          Donate: '捐赠',
          'Your donation': '您的捐赠',
          'Donation Return': '捐赠回报',
          'Active Donations': '活跃捐赠',
          Progress: '进展',
          'Donations Received': '收到的捐赠',
          'Donations Made': '已完成的捐赠',
          Community: '社区',
          Donations: '捐赠',
          'Refferal link': '推荐链接',
          Statement: '报表',
          'Showing data': '显示数据',
          entries: '条目',
          'Network Commissions': '网络佣金',
          'Date/Time': '日期/时间',
          User: '用户',
          Access: '访问',
          'Hash Transaction': '哈希交易',
          Valor: '价值',
          Directs: '直接',
          Indirect: '间接',
          Assets: '资产',
          Inactive: '不活跃',
          Total: '总计',
          'My Community': '我的社区',
          Name: '姓名',
          Country: '国家',
          WhatsApp: 'WhatsApp',
          Telegram: 'Telegram',
          Discord: 'Discord',
          Help: '帮助',
          Reviews: '评论',
          All: '所有',
          Positive: '积极',
          Negative: '消极',
          'Safe and reliable,Polite and friendly,Quick transaction, Patient': '安全可靠，礼貌友好，交易迅速，耐心',
          COIN: '货币',
          PRICE: '价格',
          Payment: '支付',
          INFO: '信息',
          Information: '信息',
          'Available / Limit': '可用 / 限制',
          'Online Sale Advertisement': '在线销售广告',
          'Online Purchase Advertisement': '在线购买广告',
          ONLINE: '在线',
          'ENTERED IN': '输入',
          Minutes: '分钟',
          'Average Release Time': '平均发布时间',
          'Completion Fee': '完成费用',
          Trades: '交易',
          Sell: '卖出',
          Buy: '购买',
          'All negotiations': '所有交易',
          negatives: '负面',
          Positives: '正面',
          'Positive Ratings': '正面评价',
          back: '返回',
          Telegram: 'Telegram',
          Whatsapp: 'Whatsapp',
          Email: '邮箱',
          Home: '首页',
          About: '关于',
          Blog: '博客',
          STATUS: '状态',
          'MANUAL DONATION': '手动捐赠',
          'AMOUNT ENUN': '金额 ENUN',
          'AMOUNT USDT': '金额 USDT',
          LOGIN: '登录',
          'WALLET ENUN': '钱包 ENUN',
          'After completing your donation, please provide the transaction address below.': '完成捐赠后，请在下方提供交易地址。',
          'txn hash': '交易哈希',
          Finish: '完成',
          'Referral Link': '推荐链接',
          'Average Payment Time': '平均支付时间',
          'Social Media': '社交媒体',
          'Online Advertisement': '在线广告',
          Comments: '评论',
          'Fiat Currency Name': '法定货币名称',
          'Fiat Currency Symbol': '法定货币符号',
          Register: '注册',
          'Country Name': '国家名称',
          REGISTER: '注册',
          'Available/Limit': '可用/限制',
          'publish new ad': '发布新广告',
          'LOCAL CRYPTO': '本地加密',
          'LOCAL CRYPTO P2P': '本地加密 P2P',
          'I have met the requirements': '我已满足要求',
          'Social network 2': '社交网络 2',
          orders: '订单',
          completed: '已完成',
          Information: '信息',
          'Trading requirements': '交易要求',
          'Minimum Donation': '最低捐赠',
          'Social network 1': '社交网络 1',
          enabled: '已启用',
          'desired amount': '期望金额',
          ENUM: '枚举',
          BUY: '购买',
          SELL: '出售',
          Send: '发送',
          'Financial password': '财务密码',
          Password: '密码',
          'My Wallet': '我的钱包',
          'Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.': '首先连接到下面的一个钱包。确保安全地存储您的私钥或种子短语。不要与任何人分享。',
          'Learn how to connect': '了解如何连接',
          "I still don't have a wallet": '我还没有钱包',
          'connect wallet': '连接钱包',
          'FINANCIAL DATA': '财务数据',
          Observations: '观察',
          'Define the Total Value and Payment Method': '定义总价值和支付方式',
          'Define type and price': '定义类型和价格',
          'Publish Regular Advertisement': '发布常规广告',
          previous: '上一页',
          Next: '下一页',
          'Select the payment method': '选择支付方式',
          PicPay: 'PicPay',
          'Transfer with specific bank': '使用特定银行转账',
          'Bank Transfer': '银行转账',
          Recommended: '推荐',
          Trubit: 'Trubit',
          'Itaú Brazil': 'Itaú 巴西',
          Confirm: '确认',
          'Payment institution': '支付机构',
          'Token NameToken contract': '代币名称 / 代币合约',
          COMMUNITY: '社区',
          HELP: '帮助',
          DISCORD: 'Discord',
          TELEGRAM: 'Telegram',
          COUNTRY: '国家',
          WHATSAPP: 'WhatsApp',
          NAME: '名称',
          USER: '用户',
          'Showing data': '显示数据',
          entries: '条目',
          Telegram: 'Telegram',
          'Local Crypto': '本地加密',
          'Financial data': '财务数据',
          'My community': '我的社区',
          Statement: '报表',
          Dashboard: '仪表板',
          Donation: '捐赠',
          'Confirm donation': '确认捐赠',
          'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': '注意：通过下面的确认，您知道您是通过 Enjoy Union 系统进行捐赠，并同意条款和条件。',
          'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': '要进行自动捐赠，您需要激活您的 Connect 钱包。这样，只需点击一次，您的捐赠将自动完成，并可以在报表面板中查看。',
          'Chosen donation in the amount of': '所选捐赠金额为',
          'Fiat Currency Name': '法定货币名称',
          'Donate automatically': '自动捐赠',
          'Choose your donation': '选择您的捐赠',
          "DONATE": "捐赠",
          "COMPLETED": "已完成",
          "MAKE DONATION": "进行捐赠",
          "LOGIN": "登录",
          "AMOUNT USDT": "金额 USDT",
          "AMOUNT ENUN": "金额 ENUN",
          "MANUAL DONATION": "手动捐赠",
          "STATUS": "状态",
          "MAKE YOUR DONATION": "进行捐赠",
          Directs: '直接',
          INDIRECT: '间接',
          ASSETS: '资产',
          INACTIVE: '不活跃',
          TOTAL: '总计',
          'MY COMMUNITY': '我的社区',
          'Forgot your password?': '忘记密码？',
          Enter: '输入',
          'CREATE ACCOUNT': '创建账户',
          'NEW LOGIN': '新登录',
          WELCOME: '欢迎',
          'LOG INTO YOUR ACCOUNT': '登录您的账户',
          SEND: '发送',
          'Please provide your registered email to send the password reset instructions.': '请提供您注册的电子邮件以发送密码重置说明。',
          'Reset your password': '重置密码',
          Registration: '注册',
          QUALIFICATION: '资格',
          'DONATIONS RECEIVED': '收到的捐赠',
          'DONATIONS MADE': '已完成的捐赠',
          'COMMUNITY GAINS': '社区收益',
          'TOTAL WINS': '总收益',
          'BALANCE AVAILABLE': '可用余额',
          'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': '首先连接到下面的一个钱包。确保安全地存储您的私钥或种子短语。不要与任何人分享。',
          'connect wallet': '连接钱包',
          'Learn how to connect': '学习如何连接',
          "I still don't have a wallet": '我还没有钱包',
          'Financial password': '财务密码',
          Password: '密码',
          'FINANCIAL DATA': '财务数据',
          Send: '发送',
          'REGISTRATION DATA': '注册数据',
          SECURITY: '安全',
          'Start by connecting to one of the wallets below.': '首先连接到下面的一个钱包。',
          TIKTOK: 'TIKTOK',
          PHONE: '电话',
          'validate Email': '验证电子邮件',
          FACEBOOK: 'Facebook',
          DISCORD: 'Discord',
          INSTAGRAM: 'Instagram',
          'LAST NAME': '姓氏',
          LOGIN: '登录',
          'Enter the 2FA code': '输入 2FA 代码',
          'Manually copy the code': '手动复制代码',
          'Download and install the Google Authenticator app.': '下载并安装 Google Authenticator 应用。',
          'Scan the QR CODE': '扫描 QR 码',
          'Two-factor authentication (2FA)': '两步验证 (2FA)',
          'CONFIRM PASSWORD': '确认密码',
          'NEW PASSWORD': '新密码',
          'CURRENT PASSWORD': '当前密码',
          'My Profile': '我的资料',
          'Change Password': '修改密码',
          'Log Out': '登出',
        },
      },
      in:{
        translation:{
          Dashboard: "डैशबोर्ड",
"Donate manually": "मैन्युअल दान करें",
"Donate automatically": "स्वचालित रूप से दान करें",
STATEMENT: "बयान",
"MAKE YOUR DONATION": "अपना दान करें",
"Choose your donation": "अपना दान चुनें",
Donation: "दान",
Donate: 'दान',
"Your donation": "आपका दान",
"Donation Return": "दान वापस",
"Active Donations": "सक्रिय दान",
Progress: "प्रगति",
"Donations Received": "प्राप्त दान",
"Donations Made": "किए गए दान",
Community: "समुदाय",
Donations: "दान",
"Refferal link": "रेफरल लिंक",
Statement: "बयान",
"Showing data": "डेटा दिखा रहा है",
entries: "एंट्रीज़",
"Donations Received": "प्राप्त दान",
"Network Commissions": "नेटवर्क कमीशन",
"Donations Made": "किए गए दान",
"Date/Time": "दिनांक/समय",
User: "उपयोगकर्ता",
Access: "पहुंच",
"Hash Transaction": "हैश लेन-देन",
Valor: "मूल्य",
Directs: "सीधे",
Indirect: "परोक्ष",
Assets: "संपत्ति",
Inactive: "निष्क्रिय",
Total: "कुल",
"My Community": "मेरा समुदाय",
User: "उपयोगकर्ता",
Name: "नाम",
Country: "देश",
WhatsApp: "व्हाट्सएप",
Telegram: "टेलीग्राम",
Discord: "डिस्कॉर्ड",
Help: "मदद",
Reviews: "समीक्षाएँ",
All: "सभी",
Positive: "सकारात्मक",
Negative: "नकारात्मक",
"Safe and reliable,Polite and friendly,Quick transaction, Patient": "सुरक्षित और विश्वसनीय, शिष्ट और अनुग्रहपूर्ण, त्वरित लेन-देन, धैर्यशील",
COIN: "सिक्का",
PRICE: "मूल्य",
Payment: "भुगतान",
INFO: "जानकारी",
Information: "जानकारी",
"Available / Limit": "उपलब्ध / सीमा",
"Online Sale Advertisement": "ऑनलाइन बिक्री विज्ञापन",
"Online Purchase Advertisement": "ऑनलाइन खरीद विज्ञापन",
ONLINE: "ऑनलाइन",
"ENTERED IN": "में प्रविष्ट",
Minutes: "मिनट",
"Average Release Time": "औसत रिलीज़ समय",
"Completion Fee": "पूर्णता शुल्क",
Trades: "व्यापार",
Sell: "बेचें",
Buy: "खरीदें",
"All negotiations": "सभी बातचीतें",
negatives: "नकारात्मक",
Positives: "सकारात्मक",
"Positive Ratings": "सकारात्मक रेटिंग्स",
back: "वापस",
Telegram: "टेलीग्राम",
Whatsapp: "व्हाट्सएप",
Email: "ईमेल",
Home: "होम",
About: "के बारे में",
Blog: "ब्लॉग",
STATUS: "स्थिति",
"MANUAL DONATION": "मैन्युअल दान",
"AMOUNT ENUN": "रकम ENUN",
"AMOUNT USDT": "राशि USDT",
LOGIN: "लॉग इन करें",
"WALLET ENUN": "वॉलेट ENUN",
"After completing your donation, please provide the transaction address below.": "अपने दान को पूरा करने के बाद, कृपया नीचे लेन-देन पता प्रदान करें।",
"txn hash": "लेन-देन हैश",
Finish: "समाप्त",
Donations: "दान",
"Referral Link": "रेफरल लिंक",
"Average Payment Time": "औसत भुगतान समय",
"Social Media": "सोशल मीडिया",
"Online Advertisement": "ऑनलाइन विज्ञापन",
Comments: "टिप्पणियाँ",
"Fiat Currency Name": "फिएट मुद्रा का नाम",
"Fiat Currency Symbol": "फिएट मुद्रा प्रतीक",
Register: "रजिस्टर करें",
"Country Name": "देश का नाम",
REGISTER: "रजिस्टर",
"Available/Limit": "उपलब्ध/सीमा",
"publish new ad": "नया विज्ञापन प्रकाशित करें",
"LOCAL CRYPTO": "स्थानीय क्रिप्टो",
"LOCAL CRYPTO P2P": "स्थानीय क्रिप्टो P2P",
"I have met the requirements": "मैंने आवश्यकताओं को पूरा किया है",
"Social network 2": "सोशल नेटवर्क 2",
orders: "आदेश",
completed: "पूर्ण",
Information: "जानकारी",
"Trading requirements": "व्यापार आवश्यकताएँ",
"Minimum Donation": "न्यूनतम दान",
"Social network 1": "सोशल नेटवर्क 1",
enabled: "सक्षम",
"desired amount": "वांछित मात्रा",
ENUM: "ENUM",
BUY: "खरीदें",
SELL: "बेचें",
Send: "भेजें",
"Financial password": "वित्तीय पासवर्ड",
Password: "पासवर्ड",
"My Wallet": "मेरा बटुआ",
"Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "नीचे दिए गए किसी भी वॉलेट से कनेक्ट करके शुरू करें। अपनी निजी कुंजी या बीज वाक्यों को सुरक्षित रूप से संग्रहित करने का सुनिश्चित करें। इन्हें किसी के साथ साझा न करें।",
"Learn how to connect": "कैसे कनेक्ट करें यह जानें",
"I still don't have a wallet": "मेरे पास अभी भी वॉलेट नहीं है",
"connect wallet": "वॉलेट कनेक्ट करें",
"FINANCIAL DATA": "वित्तीय आंकड़े",
Observations: 'अवलोकन',
'Define the Total Value and Payment Method': 'कुल मूल्य और भुगतान का तरीका निर्धारित करें',
'Define type and price': 'प्रकार और मूल्य निर्धारित करें',
'Publish Regular Advertisement': 'नियमित विज्ञापन प्रकाशित करें',
previous: 'पिछला',
Next: 'अगला',
'Select the payment method': 'भुगतान का तरीका चुनें',
PicPay: 'पिकपे',
'Transfer with specific bank': 'विशेष बैंक के साथ स्थानांतरण',
'Bank Transfer': 'बैंक ट्रांसफर',
Recommended: 'सिफारिश किया गया है',
Trubit: 'ट्रुबिट',
'Itaú Brazil': 'इटाउ ब्राजील',
Confirm: 'पुष्टि करें',
'Payment institution': 'भुगतान संस्था',
'Token NameToken contract': 'टोकन नामटोकन अनुबंध',
REGISTER: 'रजिस्टर करें',
COMMUNITY: 'समुदाय',
HELP: 'मदद',
DISCORD: 'डिस्कॉर्ड',
TELEGRAM: 'टेलीग्राम',
COUNTRY: 'देश',
WHATSAPP: 'व्हाट्सएप',
NAME: 'नाम',
USER: 'उपयोगकर्ता',
'Showing data': 'डेटा दिखा रहा है',
entries: 'एंट्रीज़',
'MY COMMUNITY': 'मेरा समुदाय',
Telegram: 'टेलीग्राम',
'Local Crypto': 'स्थानीय क्रिप्टो',
'Financial data': 'वित्तीय आंकड़े',
'My community': 'मेरा समुदाय',
Statement: 'बयान',
Dashboard: 'डैशबोर्ड',
Donation: 'दान',
'Confirm donation': 'दान की पुष्टि करें',
'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': 'ध्यान दें: नीचे पुष्टि करके, आप जानते हैं कि आप एन्जॉय यूनियन प्रणाली के माध्यम से दान कर रहे हैं, और आप नियम और शर्तों से सहमत हैं।',
'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': 'स्वचालित रूप से दान करने के लिए, आपको अपने कनेक्ट वॉलेट को सक्रिय करना होगा। इस तरह, सिर्फ एक क्लिक के साथ, आपका दान स्वचालित रूप से हो जाएगा, और आप इसे बयान पैनल में जांच सकते हैं।',
'Chosen donation in the amount of': 'राशि में चुना गया दान',
'Fiat Currency Name': 'फिएट मुद्रा का नाम',
'Donate automatically': 'स्वचालित रूप से दान करें',
'Choose your donation': 'अपना दान चुनें',
"DONATE": "दान करें",
"COMPLETED": "पूरा हुआ",
"MAKE DONATION": "दान करें",
"LOGIN": "लॉग इन करें",
"AMOUNT USDT": "राशि USDT",
"AMOUNT ENUN": "रकम ENUN",
"MANUAL DONATION": "मैन्युअल दान",
"STATUS": "स्थिति",
"MAKE YOUR DONATION": "अपना दान करें",
Directs: 'सीधे',
INDIRECT: 'परोक्ष',
ASSETS: 'संपत्ति',
INACTIVE: 'निष्क्रिय',
TOTAL: 'कुल',
COMMUNITY: 'समुदाय',
HELP: 'मदद',
DISCORD: 'डिस्कॉर्ड',
'Forgot your password?': 'पासवर्ड भूल गए?',
Enter: 'दर्ज करें',
'CREATE ACCOUNT': 'खाता बनाएं',
'NEW LOGIN': 'नया लॉगिन',
WELCOME: 'स्वागत है',
'LOG INTO YOUR ACCOUNT': 'अपने खाते में लॉग इन करें',
SEND: 'भेजें',
'Please provide your registered email to send the password reset instructions.': 'कृपया पासवर्ड रीसेट निर्देशों भेजने के लिए अपना पंजीकृत ईमेल प्रदान करें।',
'Reset your password': 'अपना पासवर्ड रीसेट करें',
Registration: 'पंजीकरण',
QUALIFICATION: 'योग्यता',
'DONATIONS RECEIVED': 'प्राप्त दान',
'DONATIONS MADE': 'किए गए दान',
'COMMUNITY GAINS': 'समुदाय लाभ',
'TOTAL WINS': 'कुल जीत',
'BALANCE AVAILABLE': 'उपलब्ध शेष',
'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'नीचे दिए गए किसी भी वॉलेट से कनेक्ट करके शुरू करें। अपनी निजी कुंजी या बीज वाक्यों को सुरक्षित रूप से संग्रहित करने का सुनिश्चित करें। इन्हें किसी के साथ साझा न करें।',
'connect wallet': 'वॉलेट कनेक्ट करें',
'Learn how to connect': 'कैसे कनेक्ट करें यह जानें',
"I still don't have a wallet": "मेरे पास अभी भी वॉलेट नहीं है",
'Financial password': 'वित्तीय पासवर्ड',
Password: 'पासवर्ड',
'FINANCIAL DATA': 'वित्तीय आंकड़े',
Send: 'भेजें',
'REGISTRATION DATA': 'पंजीकरण डेटा',
SECURITY: 'सुरक्षा',
'Start by connecting to one of the wallets below.': 'नीचे दिए गए किसी भी वॉलेट से कनेक्ट करके शुरू करें।',
TIKTOK: 'टिकटॉक',
PHONE: 'फोन',
'validate Email': 'ईमेल को मान्यता दें',
FACEBOOK: 'फेसबुक',
DISCORD: 'डिस्कॉर्ड',
INSTAGRAM: 'इंस्टाग्राम',
'LAST NAME': 'उपनाम',
LOGIN: 'लॉग इन करें',
'Enter the 2FA code': '2FA कोड दर्ज करें',
'Manually copy the code': 'कोड को मैन्युअल रूप से कॉपी करें',
'Download and install the Google Authenticator app.': 'Google Authenticator ऐप डाउनलोड और इंस्टॉल करें।',
'Scan the QR CODE': 'QR कोड स्कैन करें',
'Two-factor authentication (2FA)': 'दो-कारक प्रमाणीकरण (2FA)',
'CONFIRM PASSWORD': 'पासवर्ड की पुष्टि करें',
'NEW PASSWORD': 'नया पासवर्ड',
'CURRENT PASSWORD': 'वर्तमान पासवर्ड',
"My Wallet": "मेरा बटुआ",
"FINANCIAL": "वित्तीय",
"My Profile": "मेरा प्रोफ़ाइल",
"Change Password": "पासवर्ड बदलें",
"Log Out": "लोग आउट करें"
        },
      },
      // ru:{
      //   translation : {
      //     Dashboard: "Панель управления",
      //     'Donate manually': "Пожертвовать вручную",
      //     'Donate automatically': "Пожертвовать автоматически",
      //     STATEMENT: "ЗАЯВЛЕНИЕ",
      //     'MAKE YOUR DONATION': "СДЕЛАЙТЕ ПОЖЕРТВОВАНИЕ",
      //     'Choose your donation': "Выберите ваше пожертвование",
      //     Donation: "Пожертвование",
      //     Donate: "Пожертвовать",
      //     'Your donation': "Ваше пожертвование",
      //     'Donation Return': "Возврат пожертвования",
      //     'Active Donations': "Активные пожертвования",
      //     Progress: "Прогресс",
      //     'Donations Received': "Полученные пожертвования",
      //     'Donations Made': "Сделанные пожертвования",
      //     Community: "Сообщество",
      //     Donations: "Пожертвования",
      //     'Referral Link': "Реферальная ссылка",
      //     Statement: "Заявление",
      //     'Showing data': "Отображение данных",
      //     entries: "записи",
      //     'Network Commissions': "Сетевые комиссии",
      //     'Date/Time': "Дата/Время",
      //     User: "Пользователь",
      //     Access: "Доступ",
      //     'Hash Transaction': "Хэш-транзакция",
      //     Valor: "Значение",
      //     Directs: "Прямые",
      //     Indirect: "Косвенный",
      //     Assets: "Активы",
      //     Inactive: "Неактивный",
      //     Total: "Всего",
      //     'My Community': "Мое сообщество",
      //     Name: "Имя",
      //     Country: "Страна",
      //     WhatsApp: "WhatsApp",
      //     Telegram: "Телеграм",
      //     Discord: "Дискорд",
      //     Help: "Помощь",
      //     Reviews: "Отзывы",
      //     All: "Все",
      //     Positive: "Положительный",
      //     Negative: "Отрицательный",
      //     'Safe and reliable,Polite and friendly,Quick transaction, Patient': "Безопасно и надежно, Вежливо и дружелюбно, Быстрая сделка, Терпеливо",
      //     COIN: "МОНЕТА",
      //     PRICE: "ЦЕНА",
      //     Payment: "Оплата",
      //     INFO: "Информация",
      //     Information: "Информация",
      //     'Available / Limit': "Доступно / Лимит",
      //     'Online Sale Advertisement': "Онлайн-реклама продажи",
      //     'Online Purchase Advertisement': "Онлайн-реклама покупки",
      //     ONLINE: "ОНЛАЙН",
      //     'ENTERED IN': "ВОШЕЛ В",
      //     Minutes: "Минуты",
      //     'Average Release Time': "Среднее время выпуска",
      //     'Completion Fee': "Комиссия за завершение",
      //     Trades: "Сделки",
      //     Sell: "Продать",
      //     Buy: "Купить",
      //     'All negotiations': "Все переговоры",
      //     negatives: "отрицательные",
      //     Positives: "Положительные",
      //     'Positive Ratings': "Положительные оценки",
      //     back: "назад",
      //     Telegram: "Телеграм",
      //     Whatsapp: "WhatsApp",
      //     Email: "Эл. почта",
      //     Home: "Главная",
      //     About: "О нас",
      //     Blog: "Блог",
      //     STATUS: "СТАТУС",
      //     'MANUAL DONATION': "РУЧНОЕ ПОЖЕРТВОВАНИЕ",
      //     'AMOUNT ENUN': "СУММА ENUN",
      //     'AMOUNT USDT': "СУММА USDT",
      //     LOGIN: "ВХОД",
      //     'WALLET ENUN': "КОШЕЛЕК ENUN",
      //     'After completing your donation, please provide the transaction address below.': "После завершения вашего пожертвования, пожалуйста, укажите адрес транзакции ниже.",
      //     'txn hash': "хэш транзакции",
      //     Finish: "Закончить",
      //     'Referral Link': "Реферальная ссылка",
      //     'Average Payment Time': "Среднее время оплаты",
      //     'Social Media': "Социальные медиа",
      //     'Online Advertisement': "Онлайн-реклама",
      //     Comments: "Комментарии",
      //     'Fiat Currency Name': "Название валюты Fiat",
      //     'Fiat Currency Symbol': "Символ валюты Fiat",
      //     Register: "Регистрация",
      //     'Country Name': "Название страны",
      //     REGISTER: "РЕГИСТРАЦИЯ",
      //     'Available/Limit': "Доступно/Лимит",
      //     'publish new ad': "опубликовать новое объявление",
      //     'LOCAL CRYPTO': "ЛОКАЛЬНАЯ КРИПТОВАЛЮТА",
      //     'LOCAL CRYPTO P2P': "CRYPTO LOCAL P2P",
      //     'I have met the requirements': "Я выполнил требования",
      //     'Social network 2': "Социальная сеть 2",
      //     orders: "заказы",
      //     completed: "завершено",
      //     'Trading requirements': "Требования к торговле",
      //     'Minimum Donation': "Минимальное пожертвование",
      //     'Social network 1': "Социальная сеть 1",
      //     enabled: "включено",
      //     'desired amount': "желаемая сумма",
      //     ENUM: "ENUM",
      //     BUY: "КУПИТЬ",
      //     SELL: "ПРОДАТЬ",
      //     Send: "Отправить",
      //     'Financial password': "Финансовый пароль",
      //     Password: "Пароль",
      //     'My Wallet': "Мой кошелек",
      //     'Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.': "Начните, подключившись к одному из кошельков ниже. Обязательно сохраните свои частные ключи или фразы-семена в надежном месте. Никогда не передавайте их кому-либо.",
      //     'Learn how to connect': "Узнайте, как подключиться",
      //     'I still don\'t have a wallet': "У меня все еще нет кошелька",
      //     'connect wallet': "подключить кошелек",
      //     'FINANCIAL DATA': "ФИНАНСОВЫЕ ДАННЫЕ",
      //     Observations: "Наблюдения",
      //     'Define the Total Value and Payment Method': "Определите общую стоимость и метод оплаты",
      //     'Define type and price': "Определите тип и цену",
      //     'Publish Regular Advertisement': "Опубликовать обычное объявление",
      //     previous: "предыдущий",
      //     Next: "Следующий",
      //     'Select the payment method': "Выберите метод оплаты",
      //     PicPay: "PicPay",
      //     'Transfer with specific bank': "Перевод с конкретного банка",
      //     'Bank Transfer': "Банковский перевод",
      //     Recommended: "Рекомендуется",
      //     Trubit: "Trubit",
      //     'Itaú Brazil': "Итау Бразилия",
      //     Confirm: "Подтвердить",
      //     'Payment institution': "Платежное учреждение",
      //     'Token NameToken contract': "Имя токена / Контракт токена",
      //     REGISTER: "РЕГИСТРАЦИЯ",
      //     COMMUNITY: "Сообщество",
      //     HELP: "Помощь",
      //     DISCORD: "Дискорд",
      //     TELEGRAM: "Телеграм",
      //     COUNTRY: "Страна",
      //     WHATSAPP: "WhatsApp",
      //     NAME: "Имя",
      //     USER: "Пользователь",
      //     'Showing data': "Отображение данных",
      //     entries: "записи",
      //     'MY COMMUNITY': "Мое сообщество",
      //     COMMUNITY: "Сообщество",
      //     HELP: "Помощь",
      //     DISCORD: "Дискорд",
      //     TELEGRAM: "Телеграм",
      //     COUNTRY: "Страна",
      //     WHATSAPP: "WhatsApp",
      //     NAME: "Имя",
      //     USER: "Пользователь",
      //     'Showing data': "Отображение данных",
      //     entries: "записи",
      //     'MY COMMUNITY': "Мое сообщество",
      //     Telegram: "Телеграм",
      //     'Local Crypto': "Локальная криптовалюта",
      //     'Financial data': "Финансовые данные",
      //     'My community': "Мое сообщество",
      //     Statement: "Заявление",
      //     Dashboard: "Панель",
      //     Donation: "Пожертвование",
      //     'Confirm donation': "Подтвердить пожертвование",
      //     'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': "Внимание: Подтверждая ниже, вы осведомлены о том, что делаете пожертвование через систему Enjoy Union, и соглашаетесь с условиями.",
      //     'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': "Чтобы сделать автоматическое пожертвование, вам нужно активировать ваш Connect Wallet. Таким образом, всего одним кликом ваше пожертвование будет сделано автоматически, и вы сможете проверить его в панели заявлений.",
      //     'Chosen donation in the amount of': "Выбранное пожертвование в размере",
      //     'Fiat Currency Name': "Название валюты Fiat",
      //     'Donate automatically': "Пожертвовать автоматически",
      //     'Choose your donation': "Выберите ваше пожертвование",
      //     DONATE: "ПОЖЕРТВОВАТЬ",
      //     COMPLETED: "ЗАВЕРШЕНО",
      //     'MAKE DONATION': "СДЕЛАТЬ ПОЖЕРТВОВАНИЕ",
      //     LOGIN: "ВХОД",
      //     'AMOUNT USDT': "СУММА USDT",
      //     'AMOUNT ENUN': "СУММА ENUN",
      //     'MANUAL DONATION': "РУЧНОЕ ПОЖЕРТВОВАНИЕ",
      //     STATUS: "СТАТУС",
      //     'MAKE YOUR DONATION': "СДЕЛАЙТЕ ПОЖЕРТВОВАНИЕ",
      //     Directs: "Прямые",
      //     INDIRECT: "Косвенный",
      //     ASSETS: "Активы",
      //     INACTIVE: "Неактивный",
      //     TOTAL: "Всего",
      //     COMMUNITY: "Сообщество",
      //     HELP: "Помощь",
      //     DISCORD: "Дискорд",
      //     'Forgot your password?': "Забыли пароль?",
      //     Enter: "Вход",
      //     'CREATE ACCOUNT': "Создать аккаунт",
      //     'NEW LOGIN': "Новый логин",
      //     WELCOME: "Добро пожаловать",
      //     'LOG INTO YOUR ACCOUNT': "Войдите в свой аккаунт",
      //     SEND: "Отправить",
      //     'Please provide your registered email to send the password reset instructions.': "Пожалуйста, укажите свой зарегистрированный адрес электронной почты, чтобы отправить инструкции по сбросу пароля.",
      //     'Reset your password': "Сбросить ваш пароль",
      //     Registration: "Регистрация",
      //     QUALIFICATION: "Квалификация",
      //     'DONATIONS RECEIVED': "Полученные пожертвования",
      //     'DONATIONS MADE': "Сделанные пожертвования",
      //     'COMMUNITY GAINS': "Выигрыши сообщества",
      //     'TOTAL WINS': "Общий выигрыш",
      //     'BALANCE AVAILABLE': "Доступный баланс",
      //     'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': "Начните, подключившись к одному из кошельков ниже. Обязательно сохраните свои частные ключи или фразы-семена в надежном месте. Никогда не передавайте их кому-либо.",
      //     'connect wallet': "Подключить кошелек",
      //     'Learn how to connect': "Узнайте, как подключиться",
      //     "I still don't have a wallet": "У меня все еще нет кошелька",
      //     'Financial password': "Финансовый пароль",
      //     Password: "Пароль",
      //     'FINANCIAL DATA': "ФИНАНСОВЫЕ ДАННЫЕ",
      //     Send: "Отправить",
      //     'REGISTRATION DATA': "ДАННЫЕ РЕГИСТРАЦИИ",
      //     SECURITY: "Безопасность",
      //     'Start by connecting to one of the wallets below.': "Начните, подключившись к одному из кошельков ниже.",
      //     TIKTOK: "TIKTOK",
      //     PHONE: "Телефон",
      //     'validate Email': "проверить электронную почту",
      //     FACEBOOK: "FACEBOOK",
      //     DISCORD: "Дискорд",
      //     INSTAGRAM: "INSTAGRAM",
      //     'LAST NAME': "Фамилия",
      //     LOGIN: "Логин",
      //     'Enter the 2FA code': "Введите код 2FA",
      //     'Manually copy the code': "Скопируйте код вручную",
      //     'Download and install the Google Authenticator app.': "Скачайте и установите приложение Google Authenticator.",
      //     'Scan the QR CODE': "Отсканируйте QR-код",
      //     'Two-factor authentication (2FA)': "Двухфакторная аутентификация (2FA)",
      //     'CONFIRM PASSWORD': "Подтвердить пароль",
      //     'NEW PASSWORD': "Новый пароль",
      //     'CURRENT PASSWORD': "Текущий пароль",
      //     "My Wallet": "мой кошелек",
      //     'FINANCIAL': "ФИНАНСЫ",
      //     "My Profile": "Мой профиль",
      //     "Change Password": "Изменить пароль",
      //     "Log Out": "Выйти"
      // }
      // },
    //   fr:{
    //     translation:{
    //       Dashboard: "Tableau de bord",
    //       'Donate manually': "Faire un don manuellement",
    //       'Donate automatically': "Faire un don automatiquement",
    //       STATEMENT: "DÉCLARATION",
    //       'MAKE YOUR DONATION': "FAITES VOTRE DON",
    //       'Choose your donation': "Choisissez votre don",
    //       Donation: "Don",
    //       Donate: "Faire un don",
    //       'Your donation': "Votre don",
    //       'Donation Return': "Retour de don",
    //       'Active Donations': "Dons actifs",
    //       Progress: "Progrès",
    //       'Donations Received': "Dons reçus",
    //       'Donations Made': "Dons effectués",
    //       Community: "Communauté",
    //       Donations: "Dons",
    //       'Referral Link': "Lien de parrainage",
    //       Statement: "Déclaration",
    //       'Showing data': "Affichage des données",
    //       entries: "entrées",
    //       'Network Commissions': "Commissions réseau",
    //       'Date/Time': "Date/Heure",
    //       User: "Utilisateur",
    //       Access: "Accès",
    //       'Hash Transaction': "Transaction de hachage",
    //       Valor: "Valeur",
    //       Directs: "Directs",
    //       Indirect: "Indirects",
    //       Assets: "Actifs",
    //       Inactive: "Inactif",
    //       Total: "Total",
    //       'My Community': "Ma communauté",
    //       Name: "Nom",
    //       Country: "Pays",
    //       WhatsApp: "WhatsApp",
    //       Telegram: "Telegram",
    //       Discord: "Discord",
    //       Help: "Aide",
    //       Reviews: "Avis",
    //       All: "Tout",
    //       Positive: "Positif",
    //       Negative: "Négatif",
    //       'Safe and reliable,Polite and friendly,Quick transaction, Patient': "Sûr et fiable, Poli et amical, Transaction rapide, Patient",
    //       COIN: "MONNAIE",
    //       PRICE: "PRIX",
    //       Payment: "Paiement",
    //       INFO: "INFO",
    //       Information: "Information",
    //       'Available / Limit': "Disponible / Limite",
    //       'Online Sale Advertisement': "Publicité de vente en ligne",
    //       'Online Purchase Advertisement': "Publicité d'achat en ligne",
    //       ONLINE: "EN LIGNE",
    //       'ENTERED IN': "ENTRÉ DANS",
    //       Minutes: "Minutes",
    //       'Average Release Time': "Temps de libération moyen",
    //       'Completion Fee': "Frais de traitement",
    //       Trades: "Transactions",
    //       Sell: "Vendre",
    //       Buy: "Acheter",
    //       'All negotiations': "Toutes les négociations",
    //       negatives: "négatives",
    //       Positives: "Positives",
    //       'Positive Ratings': "Évaluations positives",
    //       back: "retour",
    //       Telegram: "Telegram",
    //       Whatsapp: "WhatsApp",
    //       Email: "E-mail",
    //       Home: "Accueil",
    //       About: "À propos",
    //       Blog: "Blog",
    //       STATUS: "STATUT",
    //       'MANUAL DONATION': "DON MANUEL",
    //       'AMOUNT ENUN': "MONTANT ENUN",
    //       'AMOUNT USDT': "MONTANT USDT",
    //       LOGIN: "CONNEXION",
    //       'WALLET ENUN': "PORTEFEUILLE ENUN",
    //       'After completing your donation, please provide the transaction address below.': "Après avoir effectué votre don, veuillez indiquer l'adresse de transaction ci-dessous.",
    //       'txn hash': "hachage de la transaction",
    //       Finish: "Terminer",
    //       'Referral Link': "Lien de parrainage",
    //       'Average Payment Time': "Temps moyen de paiement",
    //       'Social Media': "Médias sociaux",
    //       'Online Advertisement': "Publicité en ligne",
    //       Comments: "Commentaires",
    //       'Fiat Currency Name': "Nom de la devise fiduciaire",
    //       'Fiat Currency Symbol': "Symbole de la devise fiduciaire",
    //       Register: "S'inscrire",
    //       'Country Name': "Nom du pays",
    //       REGISTER: "INSCRIPTION",
    //       'Available/Limit': "Disponible/Limite",
    //       'publish new ad': "publier une nouvelle annonce",
    //       'LOCAL CRYPTO': "CRYPTO LOCAL",
    //       'LOCAL CRYPTO P2P': "CRYPTO LOCAL P2P",
    //       'I have met the requirements': "J'ai rempli les conditions",
    //       'Social network 2': "Réseau social 2",
    //       orders: "commandes",
    //       completed: "terminé",
    //       'Trading requirements': "Exigences de trading",
    //       'Minimum Donation': "Don minimum",
    //       'Social network 1': "Réseau social 1",
    //       enabled: "activé",
    //       'desired amount': "montant souhaité",
    //       ENUM: "ÉNUM",
    //       BUY: "ACHETER",
    //       SELL: "VENDRE",
    //       Send: "Envoyer",
    //       'Financial password': "Mot de passe financier",
    //       Password: "Mot de passe",
    //       'My Wallet': "Mon portefeuille",
    //       'Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.': "Commencez par vous connecter à l'un des portefeuilles ci-dessous. Assurez-vous de stocker vos clés privées ou phrases de récupération en toute sécurité. Ne les partagez jamais avec qui que ce soit.",
    //       'Learn how to connect': "Apprenez comment vous connecter",
    //       "I still don't have a wallet": "Je n'ai toujours pas de portefeuille",
    //       'connect wallet': "connecter un portefeuille",
    //       'FINANCIAL DATA': "DONNÉES FINANCIÈRES",
    //       Observations: "Observations",
    //       'Define the Total Value and Payment Method': "Définir la valeur totale et le mode de paiement",
    //       'Define type and price': "Définir le type et le prix",
    //       'Publish Regular Advertisement': "Publier une annonce régulière",
    //       previous: "précédent",
    //       Next: "Suivant",
    //       'Select the payment method': "Sélectionner le mode de paiement",
    //       PicPay: "PicPay",
    //       'Transfer with specific bank': "Transfert avec une banque spécifique",
    //       'Bank Transfer': "Virement bancaire",
    //       Recommended: "Recommandé",
    //       Trubit: "Trubit",
    //       'Itaú Brazil': "Itaú Brésil",
    //       Confirm: "Confirmer",
    //       'Payment institution': "Établissement de paiement",
    //       'Token NameToken contract': "Nom du token / Contrat de token",
    //       REGISTER: "S'INSCRIRE",
    //       COMMUNITY: "Communauté",
    //       HELP: "Aide",
    //       DISCORD: "Discord",
    //       TELEGRAM: "Télégramme",
    //       COUNTRY: "Pays",
    //       WHATSAPP: "WhatsApp",
    //       NAME: "Nom",
    //       USER: "Utilisateur",
    //       'Showing data': "Affichage des données",
    //       entries: "entrées",
    //       'MY COMMUNITY': "MA COMMUNAUTÉ",
    //       COMMUNITY: "Communauté",
    //       HELP: "Aide",
    //       DISCORD: "Discord",
    //       TELEGRAM: "Télégramme",
    //       COUNTRY: "Pays",
    //       WHATSAPP: "WhatsApp",
    //       NAME: "Nom",
    //       USER: "Utilisateur",
    //       'Showing data': "Affichage des données",
    //       entries: "entrées",
    //       'MY COMMUNITY': "MA COMMUNAUTÉ",
    //       Telegram: "Télégramme",
    //       'Local Crypto': "Local Crypto",
    //       'Financial data': "Données financières",
    //       'My community': "Ma communauté",
    //       Statement: "Déclaration",
    //       Dashboard: "Tableau de bord",
    //       Donation: "Donation",
    //       'Confirm donation': "Confirmer le don",
    //       'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': "Attention : En confirmant ci-dessous, vous êtes conscient que vous effectuez la donation via le système Enjoy Union, et vous acceptez les termes et conditions.",
    //       'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': "Pour effectuer une donation automatique, vous devez avoir votre portefeuille Connect activé. De cette façon, d'un simple clic, votre donation sera effectuée automatiquement, et vous pourrez la vérifier dans le panneau de déclaration.",
    //       'Chosen donation in the amount of': "Don choisi d'un montant de",
    //       'Fiat Currency Name': "Nom de la devise fiduciaire",
    //       'Donate automatically': "Donner automatiquement",
    //       'Choose your donation': "Choisissez votre donation",
    //       DONATE: "DONNER",
    //       COMPLETED: "TERMINÉ",
    //       'MAKE DONATION': "FAIRE UN DON",
    //       LOGIN: "CONNEXION",
    //       'AMOUNT USDT': "MONTANT USDT",
    //       'AMOUNT ENUN': "MONTANT ENUN",
    //       'MANUAL DONATION': "DON MANUEL",
    //       STATUS: "STATUT",
    //       'MAKE YOUR DONATION': "FAITES VOTRE DON",
    //       Directs: "Directs",
    //       INDIRECT: "Indirects",
    //       ASSETS: "Actifs",
    //       INACTIVE: "Inactif",
    //       TOTAL: "Total",
    //       COMMUNITY: "Communauté",
    //       HELP: "Aide",
    //       DISCORD: "Discord",
    //       'Forgot your password?': "Mot de passe oublié ?",
    //       Enter: "Entrer",
    //       'CREATE ACCOUNT': "CRÉER UN COMPTE",
    //       'NEW LOGIN': "NOUVELLE CONNEXION",
    //       WELCOME: "Bienvenue",
    //       'LOG INTO YOUR ACCOUNT': "CONNEXION À VOTRE COMPTE",
    //       SEND: "Envoyer",
    //       'Please provide your registered email to send the password reset instructions.': "Veuillez fournir votre e-mail enregistré pour envoyer les instructions de réinitialisation du mot de passe.",
    //       'Reset your password': "Réinitialiser votre mot de passe",
    //       Registration: "Inscription",
    //       QUALIFICATION: "Qualification",
    //       'DONATIONS RECEIVED': "Dons reçus",
    //       'DONATIONS MADE': "Dons effectués",
    //       'COMMUNITY GAINS': "Bénéfices de la communauté",
    //       'TOTAL WINS': "Total des gains",
    //       'BALANCE AVAILABLE': "Solde disponible",
    //       'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': "Commencez par vous connecter à l'un des portefeuilles ci-dessous. Assurez-vous de stocker vos clés privées ou phrases de récupération en toute sécurité. Ne les partagez jamais avec qui que ce soit.",
    //       'connect wallet': "connecter un portefeuille",
    //       'Learn how to connect': "Apprenez comment vous connecter",
    //       "I still don't have a wallet": "Je n'ai toujours pas de portefeuille",
    //       'Financial password': "Mot de passe financier",
    //       Password: "Mot de passe",
    //       'FINANCIAL DATA': "DONNÉES FINANCIÈRES",
    //       Send: "Envoyer",
    //       'REGISTRATION DATA': "DONNÉES D'INSCRIPTION",
    //       SECURITY: "Sécurité",
    //       'Start by connecting to one of the wallets below.': "Commencez par vous connecter à l'un des portefeuilles ci-dessous.",
    //       TIKTOK: "TIKTOK",
    //       PHONE: "Téléphone",
    //       'validate Email': "valider l'e-mail",
    //       FACEBOOK: "FACEBOOK",
    //       DISCORD: "Discord",
    //       INSTAGRAM: "INSTAGRAM",
    //       'LAST NAME': "Nom de famille",
    //       LOGIN: "Connexion",
    //       'Enter the 2FA code': "Entrez le code 2FA",
    //       'Manually copy the code': "Copiez le code manuellement",
    //       'Download and install the Google Authenticator app.': "Téléchargez et installez l'application Google Authenticator.",
    //       'Scan the QR CODE': "Scannez le QR CODE",
    //       'Two-factor authentication (2FA)': "Authentification à deux facteurs (2FA)",
    //       'CONFIRM PASSWORD': "Confirmez le mot de passe",
    //       'NEW PASSWORD': "Nouveau mot de passe",
    //       'CURRENT PASSWORD': "Mot de passe actuel",
    //       "My Wallet": "Mon portefeuille",
    //       'FINANCIAL': "FINANCIER",
    //       "My Profile": "Mon profil",
    //       "Change Password": "Changer le mot de passe",
    //       "Log Out": "Déconnexion"
    //   },
    // },
    
    fr:{
      translation:{
        "language": "langue",
        "ENJOYUNION CLUB REGISTRATION" : "Inscription au club ENJOYUNION",
"SPONSOR: JOÃO VICTOR" : "PARRAIN: JOÃO VICTOR",
"LAST NAME" : "NOM",
"FIRST NAME" : "PRÉNOM",
"USERNAME" : "IDENTIFIANT",
"COUNTRY" : "PAYS",
"EMAIL" : "EMAIL",
"WHATSAPP" : "WHATSAPP",
"PASSWORD" : "MOT DE PASSE",
"CONFIRM PASSWORD" : "CONFIRMER LE MOT DE PASSE",
"[REGISTER]" : "[INSCRIRE]",
"Page 2" : "Page 2",
"ENJOYUNION CLUB WELCOME" : "BIENVENUE AU CLUB ENJOYUNION",
"NEW USERNAME" : "NOUVEL IDENTIFIANT",
"[Create an account]" : "[Créer un compte]",
"LOGIN TO YOUR ACCOUNT" : "SE CONNECTER À VOTRE COMPTE",
"Forgot Password?" : "Mot de passe oublié ?",
"[Continue with Google]" : "[Continuer avec Google]",
"[Continue with Facebook]" : "[Continuer avec Facebook]",
"[Continue with Apple]" : "[Continuer avec Apple]",
"Page 3" : "Page 3",
"RESET YOUR PASSWORD" : "RÉINITIALISER VOTRE MOT DE PASSE",
"Enter your registered email so we can send you password reset instructions" : "Saisissez votre email enregistré afin que nous puissions vous envoyer des instructions de réinitialisation de mot de passe",
"[Send]" : "[Envoyer]",
"Page 4" : "Page 4",
"QUALIFICATION" : "QUALIFICATION",
"RECEIVED DONATIONS" : "DONATIONS REÇUES",
"MADE DONATIONS" : "DONATIONS EFFECTUÉES",
"COMMUNITY EARNINGS" : "GAINS DE LA COMMUNAUTÉ",
"TOTAL EARNINGS" : "GAINS TOTAUX",
"AVAILABLE BALANCE" : "SOLDE DISPONIBLE",
"Donations" : "Donations",
"Progress Limit" : "Limite de progression",
"Active Donations" : "Donations actives",
"Donation Return" : "Retour sur donation",
"Remaining" : "Restant",
"DAY" : "JOUR",
"For Your Donation" : "Pour votre donation",
"Your Donation" : "Votre Donation",
"$0.00" : "0,00 $",
"GIVE" : "DONNER",
"Page 5" : "Page 5",
"EXCERPT" : "EXTRAIT",
"NETWORK COMMISSIONS" : "COMMISSIONS RÉSEAU",
"WITHDRAWALS" : "RETRAITS",
"DATE/TIME" : "DATE/HEURE",
"USER" : "UTILISATEUR",
"TRANSACTION HASH" : "HACHE TRANSACTION",
"AMOUNT" : "MONTANT",
"Displaying data 1 to 8 out of 256K entries (Displaying data 1 to 8 out of 256 thousand entries)" : "Affichage des données de 1 à 8 sur 256K entrées (Affichage des données de 1 à 8 sur 256 mille entrées)",
"ACCESS" : "ACCÉDER",
"Page 6" : "Page 6",
"NETWORK FEES" : "FRAIS DE RÉSEAU",
"DATE/TIME" : "DATE/HEURE",
"TRANSACTION HASH" : "HACHE DE TRANSACTION",
"VALUE" : "VALEUR",
"Page 7" : "Page 7",
"EXCERPT" : "EXTRAIT",
"NETWORK FEES" : "FRAIS DE RÉSEAU",
"DATE/TIME" : "DATE/HEURE",
"TRANSACTION HASH" : "HACHE DE TRANSACTION",
"VALUE" : "VALEUR",
"Page 8" : "Page 8",
"MY COMMUNITY" : "MA COMMUNAUTÉ",
"Directs" : "Directs",
"INDirects" : "INDirects",
"ACTIVE" : "ACTIFS",
"INACTIVE" : "INACTIFS",
"TOTAL" : "TOTAL",
"USER" : "UTILISATEUR",
"NAME" : "NOM",
"COUNTRY" : "PAYS",
"WHATSAPP" : "WHATSAPP",
"TELEGRAM" : "TELEGRAM",
"DISCORD" : "DISCORD",
"SUPPORT" : "AIDE",
"COMMUNITY" : "COMMUNAUTÉ",
"Page 9" : "Page 9",
"MY COMMUNITY" : "MA COMMUNAUTÉ",
"USER" : "UTILISATEUR",
"NAME" : "NOM",
"COUNTRY" : "PAYS",
"WHATSAPP" : "WHATSAPP",
"TELEGRAM" : "TELEGRAM",
"DISCORD" : "DISCORD",
"SUPPORT" : "AIDE",
"COMMUNITY" : "COMMUNAUTÉ",
"Page 10" : "Page 10",
"FINANCIAL DATA" : "DONNÉES FINANCIÈRES",
"my wallet | enun | bep - 20" : "mon portefeuille | enun | bep - 20",
"password" : "mot de passe",
"financial password" : "mot de passe financier",
"2fa" : "2fa",
"123890" : "123890",
"save" : "sauvegarder",
"connect wallet" : "connecter le portefeuille",
"Start by connecting to one of the wallets below. Make sure to store your private keys or recovery phrases securely, never share them with anyone." : "Commencez par vous connecter à l'un des portefeuilles ci-dessous. Assurez-vous de stocker vos clés privées ou phrases de récupération en toute sécurité, ne les partagez jamais avec personne.",
"I still don't have a wallet" : "Je n'ai toujours pas de portefeuille",
"learn how to connect" : "apprenez comment vous connecter",
"Page 11" : "Page 11",
"REGISTRATION DATA" : "DONNÉES D'INSCRIPTION",
"REGISTRATION DETAILS" : "DÉTAILS D'INSCRIPTION",
"SECURITY" : "SÉCURITÉ",
"FINANCIAL" : "FINANCIER",
"USERNAME" : "IDENTIFIANT",
"LAST NAME" : "NOM DE FAMILLE",
"EMAIL" : "EMAIL",
"[validate email]" : "[valider l'email]",
"INSTAGRAM" : "INSTAGRAM",
"DE CASTILHO" : "DE CASTILHO",
"PHONE" : "TÉLÉPHONE",
"REGISTRATION DATA" : "DONNÉES D'INSCRIPTION",
"REGISTER" : "INSCRIPTION",
"SECURITY" : "SÉCURITÉ",
"FINANCIAL" : "FINANCIER",
"USERNAME" : "IDENTIFIANT",
"João Victor" : "João Victor",
"LAST NAME" : "NOM DE FAMILLE",
"INSTAGRAM" : "INSTAGRAM",
"DE CASTILHO" : "DE CASTILHO",
"DISCORD" : "DISCORD",
"(empty field)" : "(champ vide)",
"FACEBOOK" : "FACEBOOK",
"(empty field)" : "(champ vide)",
"VK" : "VK",
"(empty field)" : "(champ vide)",
"TIKTOK" : "TIKTOK",
"(empty field)" : "(champ vide)",
"EMAIL" : "EMAIL",
"JOAO@TESTE123.COM" : "JOAO@TESTE123.COM",
"[validate email]" : "[valider l'email]",
"Check the code sent to your email address and enter it in the field below:" : "vérifiez le code envoyé à votre adresse e-mail et entrez-le dans le champ ci-dessous :",
"[send]" : "[envoyer]",
"PHONE" : "TÉLÉPHONE",
"+55 12 992007383" : "+55 12 992007383",
"Page13" : "Page13",
"SECURITY DATA" : "DONNÉES DE SÉCURITÉ",
"Registration data" : "données d'inscription",
"Financial security" : "Sécurité financier",
"Current password" : "mot de passe actuel",
"New password" : "Nouveau mot de passe",
"Confirm password" : "confirmer le mot de passe",
"Two-factor authentication (2FA)" : "authentification à deux facteurs (2FA)",
"Scan the QR CODE" : "Scannez le CODE QR",
"Download and install the Google Authenticator app" : "Téléchargez et installez l'application Google Authenticator",
"[Download from] App Store [Get it on] Google Play" : "[Téléchargez sur] l'App Store [Obtenez-le sur] Google Play",
"Copy the MANUAL CODE" : "copiez le CODE MANUEL",
"SAAJAL02014U3" : "SAAJAL02014U3",
"Enter the 2FA code" : "Entrez le code 2FA",
"Save" : "sauvegarder",
"Page14" : "Page14",
"MAKE YOUR DONATION" : "FAITES VOTRE DON",
"Choose your donation" : "Choisissez votre don",
"5 $" : "5 $",
"Make a donation manually Make a donation automatically" : "Faire un don manuellement Faire un don automatiquement",
"Page15" : "Page15",
"MAKE YOUR DONATION" : "FAITES VOTRE DON",
"Choose your donation" : "Choisissez votre don",
"5 $" : "5 $",
"Make a donation manually Make a donation automatically" : "Faire un don manuellement Faire un don automatiquement",
"Donation chosen in the amount of" : "Don choisi d'un montant de",
"5.00 $" : "5,00 $",
"8.62068966 ENUM" : "8,62068966 ENUM",
"Attention: By confirming below, you are aware that you are making a donation through the Enjoy Union system, and you accept the terms and conditions." : "Attention : En confirmant ci-dessous, vous êtes conscient que vous effectuez un don à travers le système Enjoy Union, et vous acceptez les termes et conditions.",
"Confirm donation" : "confirmer le don",
"Page16" : "Page16",
"PROCEED WITH DONATION" : "PROCÉDER AU DON",
"IDENTIFIER VALUE USDT ENUM MANUAL DONATION VALUE STATUS" : "IDENTIFIANT VALEUR USDT ENUM VALEUR DONATION MANUELLE STATUT",
"Victor 2 000 $ 3 448,2758 faire un don Complet" : "Victor 2 000 $ 3 448,2758 faire un don Complet",
"Floyd Miles 2 000 $ 3 448,2758 faire un don Partiel" : "Floyd Miles 2 000 $ 3 448,2758 faire un don Partiel",
"Ronald Richards 2 000 $ 3 448,2758 faire un don En attente" : "Ronald Richards 2 000 $ 3 448,2758 faire un don En attente",
"CANCEL DONATION" : "ANNULER LE DON",
"You must finalize your donation within a maximum of 25 minutes." : "Il est nécessaire de finaliser votre don dans un délai maximum de 25 minutes.",
"Page17" : "Page17",
"PROCEED WITH DONATION" : "PROCÉDER AU DON",
"IDENTIFIER: VICTOR VALUE USDT: 2 000 $ VALUE ENUM: 3 448,2758 ENUM WALLET ENUM: 0x1B92334dB8d3B07E0031F5..." : "IDENTIFIANT : VICTOR VALEUR USDT : 2 000 $ VALEUR ENUM : 3 448,2758 ENUM PORTEFEUILLE ENUM : 0x1B92334dB8d3B07E0031F5...",
"After finalizing your donation, please provide the transaction address below." : "Après avoir finalisé votre don, veuillez indiquer l'adresse de transaction ci-dessous.",
"Txn Hash:" : "hachage txn :",
"[finalize]" : "[finaliser]",
"Page18" : "Page18",
"MAKE A DONATION" : "FAIRE UN DON",
"IDENTIFIER: VICTOR VALUE USDT: 2 000 $ VALUE ENUM: 3 448,2758 ENUM WALLET ENUM: 0x1B92334dB8d3B07E0031F5..." : "IDENTIFIANT : VICTOR VALEUR USDT : 2 000 $ VALEUR ENUM : 3 448,2758 ENUM PORTEFEUILLE ENUM : 0x1B92334dB8d3B07E0031F5...",
"After finalizing your donation, please provide the transaction address below." : "Après avoir finalisé votre don, veuillez indiquer l'adresse de transaction ci-dessous.",
"Txn Hash:" : "hachage txn :",
"[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]" : "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]",
"[finalize]" : "[finaliser]",
"Page19" : "Page19",
"MAKE A DONATION" : "FAIRE UN DON",
"IDENTIFIER: VICTOR VALUE USDT: 2 000 $ VALUE ENUM: 3 448,2758 ENUM WALLET ENUM: 0x1B92334dB8d3B07E0031F5..." : "IDENTIFIANT : VICTOR VALEUR USDT : 2 000 $ VALEUR ENUM : 3 448,2758 ENUM PORTEFEUILLE ENUM : 0x1B92334dB8d3B07E0031F5...",
"After finalizing your donation, please provide the transaction address below." : "Après avoir finalisé votre don, veuillez indiquer l'adresse de transaction ci-dessous.",
"Txn Hash:" : "hachage txn :",
"[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]" : "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]",
"[finalize]" : "[finaliser]",
"This is a user interface for a donation platform where the user 'VICTOR' is about to donate $2,000 in USDT, equivalent to 3,448.2758 ENUM. They have space to enter the transaction hash after the donation is made." : "Il s'agit d'une interface utilisateur pour une plateforme de dons où l'utilisateur 'VICTOR' est sur le point de faire un don de 2 000 $ en USDT, ce qui équivaut à 3 448,2758 ENUM. Ils disposent d'un espace pour entrer le hachage de transaction après que le don soit fait.",
"Page20" : "Page20",
"MAKE YOUR DONATION" : "FAITES VOTRE DON",
"Choose your donation" : "Choisissez votre don",
"5 $" : "5 $",
"Make a donation manually Make a donation automatically" : "Faire un don manuellement Faire un don automatiquement",
"Donation chosen in the amount of" : "Don choisi d'un montant de",
"5.00 $" : "5,00 $",
"(8.62068966 ENUM)" : "(8.62068966 ENUM)",
"To make the automatic donation, it is necessary to have your connected wallet active. This way, with just one click, your donation will be made automatically and you can check it on the summary panel." : "Pour effectuer le don automatique, il est nécessaire d'avoir votre portefeuille connecté actif. De cette façon, en un seul clic, votre don sera fait automatiquement et vous pourrez le vérifier sur le panneau d'extrait.",
"Attention: By confirming below, you acknowledge that you are making a donation through the Enjoy Union system, accepting the terms and conditions." : "Attention : En confirmant ci-dessous, vous reconnaissez que vous effectuez un don à travers le système Enjoy Union, en acceptant les termes et conditions.",
"[confirm donation]" : "[confirmer le don]",
"Page21" : "Page21",
"COMPLETED DONATION" : "DONATION COMPLÉTÉE",
"IDENTIFIER VALUE USDT VALUE ENUM TRANSACTION HASH STATUS" : "IDENTIFIANT VALEUR USDT VALEUR ENUM HACHAGE DE TRANSACTION STATUT",
"Victor 2.000 $ 3.448,2758 [HASH] Completed" : "Victor 2.000 $ 3.448,2758 [HACHAGE] Complet",
"Floyd Miles 2.000 $ 3.448,2758 [HASH] Completed" : "Floyd Miles 2.000 $ 3.448,2758 [HACHAGE] Complet",
"Ronald Richards 2.000 $ 3.448,2758 [HASH] Completed" : "Ronald Richards 2.000 $ 3.448,2758 [HACHAGE] Complet",
"Page22" : "Page22",
"CRYPTO LOCAL P2P" : "CRYPTO LOCAL P2P",
"Buy | Sell" : "acheter | vendre",
"[ENUM] [USDT] [BTC] [ETH] [BNB] [ADA] [BRL] � [Publish a new ad]" : "[ENUM] [USDT] [BTC] [ETH] [BNB] [ADA] [BRL] � [Publier une nouvelle annonce]",
"Available/Limit" : "Disponible/Limite",
"Advertiser Price Available/Limit Payment INFO" : "Annonceur Prix Disponible/Limite Paiement INFO",
"João Victor 4,975 BRL 34,935.10 USDT PIX Information" : "João Victor 4,975 BRL 34,935.10 USDT PIX Information",
"368 orders 99.50% completed 10,000.00 R$ - 150,000.00 R$" : "368 commandes 99,50% terminées 10 000,00 R$ - 150 000,00 R$",
"99.73%" : "99,73%",
"Image displays a peer-to-peer cryptocurrency trading platform interface." : "L'image affiche une interface de plateforme de trading peer-to-peer de cryptomonnaie.",
"The fields show the advertiser's name, the price in Brazilian Reais (BRL), the available amount or limit in USDT (Tether), and the payment method, which is PIX (a Brazilian instant payment system). The information button probably leads to more details about the advertiser's offer." : "Les champs montrent le nom de l'annonceur, le prix en réais brésiliens (BRL), le montant disponible ou la limite en USDT (Tether), et le mode de paiement, qui est PIX (un système de paiement instantané brésilien). Le bouton d'information mène probablement à plus de détails sur l'offre de l'annonceur.",
"Page23" : "Page23",
"CRYPTO LOCAL P2P" : "CRYPTO LOCAL P2P",
"Trading Requirements" : "Exigences de trading",
"Minimum donation 5 $" : "Don minimum 5 $",
"2FA" : "2FA",
"Email" : "Email",
"Social Network 1" : "Réseau social 1",
"Social Network 2" : "Réseau social 2",
"Enabled" : "Activé",
"I have fulfilled the conditions" : "J'ai rempli les conditions",
"Payment" : "Paiement",
"PIX" : "PIX",
"Trade" : "Commerce",
"[Information]" : "[Information]",
"The button at the bottom of the page says 'I have fulfilled the conditions.' The context suggests it is a screen of a peer-to-peer cryptocurrency trading platform (P2P), where the user needs to fulfill certain conditions to participate in trading. The 'Enabled' status next to each requirement indicates the user has fulfilled these conditions. 'PIX' refers to a payment method, likely specific to the region where the platform operates." : "Le bouton en bas de la page indique 'J'ai rempli les conditions'. Le contexte suggère qu'il s'agit d'un écran d'une plateforme de trading de cryptomonnaie peer-to-peer (P2P), où l'utilisateur doit remplir certaines conditions pour participer au trading. Le statut 'Activé' à côté de chaque exigence indique que l'utilisateur a rempli ces conditions. 'PIX' fait référence à une méthode de paiement, probablement spécifique à la région où la plateforme opère.",
"Page 24" : "Page 24",
"Publish a regular announcement" : "Publier une annonce normale",
"Set type and price" : "Définir le type et le prix",
"Copy code" : "Copy code",
"I want to buy" : "Je veux acheter",
"Assets" : "Actifs",
"USDT" : "USDT",
"With fiat currency" : "Avec monnaie fiduciaire",
"→ BRL" : "→ BRL",
"Price type" : "Type de prix",
"Fixed" : "Fixé",
"The fixed price must be between 4.464 - 5.456" : "Le prix fixé doit être compris entre 4.464 - 5.456",
"Your price" : "Votre prix",
"More expensive order" : "Ordre plus cher",
"Mathematica" : "mathematica",
"Set total amount and payment method" : "Définir le montant total et le mode de paiement",
"Observations" : "Observations",
"I want to sell" : "Je veux vendre",
"Next" : "Suivant",
"The screen appears to be for a cryptocurrency trading platform where users can post ads to buy or sell digital assets like USDT (Tether) against fiat currency, in this case, the Brazilian Real (BRL). Users can set a fixed price for their transactions." : "L'écran semble être pour une plateforme de trading de cryptomonnaie où les utilisateurs peuvent publier des annonces pour acheter ou vendre des actifs numériques comme USDT (Tether) contre une monnaie fiduciaire, dans ce cas, le réal brésilien (BRL). Les utilisateurs peuvent définir un prix fixe pour leurs transactions.",
"Page25" : "Page25",
"Publish a regular announcement" : "Publier une annonce normale",
"Bash" : "Bash",
"Set type and price" : "Définir le type et le prix",
"Mathematica" : "mathematica",
"Set total amount and payment method" : "Définir le montant total et le mode de paiement",
"Observations" : "Observations",
"I want to buy" : "Je veux acheter",
"Assets" : "Actifs",
"USDT" : "USDT",
"With fiat currency" : "Avec monnaie fiduciaire",
"Price type" : "Type de prix",
"Fixed [Selected] Floating" : "Fixé [Sélectionné] Flottant",
"Decrease button" : "[Bouton de diminution]",
"4.960" : "4.960",
"Increase button" : "[Bouton d'augmentation]",
"The fixed price must be between 4.464 - 5.456" : "Le prix fixé doit être compris entre 4.464 - 5.456",
"Your price" : "Votre prix",
"More expensive order" : "Ordre plus cher",
"Next" : "Suivant",
"I want to sell" : "Je veux vendre",
"It appears to be a user interface for posting an advertisement to buy or sell cryptocurrencies, with options to select the currency type, price, and payment method." : "Il semble s'agir d'une interface utilisateur pour publier une annonce d'achat ou de vente de cryptomonnaies, avec des options pour sélectionner le type de monnaie, le prix et le mode de paiement.",
"Page26" : "Page26",
"Publish a regular announcement" : "Publier une annonce normale",
"Bash" : "Bash",
"Set type and price" : "Définir le type et le prix",
"Mathematica" : "mathematica",
"Set total amount and payment method" : "Définir le montant total et le mode de paiement",
"Observations" : "Observations",
"I want to buy" : "Je veux acheter",
"Assets" : "Actifs",
"USDT" : "USDT",
"With fiat currency" : "Avec monnaie fiduciaire",
"Save" : "Enregistrer",
"Enter token name input field" : "[Champ de saisie pour le nom du token]",
"Enter token contract input field" : "[Champ de saisie pour le contrat de token]",
"Save button" : "[Bouton d'enregistrement]",
"Your price" : "Votre prix",
"More expensive order" : "Ordre plus cher",
"Next" : "Suivant",
"I want to sell" : "Je veux vendre",
"It appears to be a user interface for posting an advertisement to buy or sell cryptocurrencies, with options to select the currency type, price, and payment method, as well as a section to save a new token." : "Il semble s'agir d'une interface utilisateur pour publier une annonce d'achat ou de vente de cryptomonnaies, avec des options pour sélectionner le type de monnaie, le prix et le mode de paiement, ainsi qu'une section pour enregistrer un nouveau token.",
"Page27" : "Page27",
"Publish a regular announcement" : "Publier une annonce normale",
"Set total amount and payment method" : "Définir le montant total et le mode de paiement",
"Comments" : "Commentaires",
"Set type and price" : "Définir le type et le prix",
"I want to buy" : "Je veux acheter",
"I want to sell" : "Je veux vendre",
"Asset" : "Actif",
"With fiat currency" : "Avec monnaie fiduciaire",
"USDT" : "USDT",
"Price type" : "Type de prix",
"It made" : "il a fait",
"Floating" : "Flottant",
"It made" : "il a fait",
"The fixed price must be between 4,4645,456" : "Le prix fixé doit être entre 4,4645,456",
"Your price" : "Votre prix",
"Next" : "Suivant",
"More expensive order" : "Ordre plus cher",
"Page28" : "Page28",
"Publish a regular announcement" : "Publier une annonce normale",
"Set type and price" : "Définir le type et le prix",
"Set total amount and payment method" : "Définir le montant total et le mode de paiement",
"Comments" : "Commentaires",
"B" : "B",
"I want to buy" : "Je veux acheter",
"I want to sell" : "Je veux vendre",
"Asset" : "Actif",
"With fiat currency" : "Avec monnaie fiduciaire",
"USDT" : "USDT",
"Register" : "Registre",
"Fixed" : "Fixé",
"Floating" : "Flottant",
"Register" : "Registre",
"The fixed price must be between 4,4645,456" : "Le prix fixé doit être entre 4,4645,456",
"Your price" : "Votre prix",
"Next" : "Suivant",
"More expensive order" : "Ordre plus cher",
"Page29" : "Page29",
"Publish a regular announcement" : "Publier une annonce normale",
"Set total amount and payment method" : "Définir le montant total et le mode de paiement",
"Comments" : "Commentaires",
"Set type and price" : "Définir le type et le prix",
"I want to buy" : "Je veux acheter",
"I want to sell" : "Je veux vendre",
"Asset" : "Actif",
"With fiat currency" : "Avec monnaie fiduciaire",
"USDT" : "USDT",
"Price type" : "Type de prix",
"It made" : "il a fait",
"Floating" : "Flottant",
"It made" : "il a fait",
"The fixed price must be between 4,4645,456" : "Le prix fixé doit être entre 4,4645,456",
"Your price" : "Votre prix",
"Next" : "Suivant",
"More expensive order" : "Ordre plus cher",
"Page30" : "Page30",
"Publish a regular announcement" : "Publier une annonce normale",
"Set type and price" : "Définir le type et le prix",
"Set total amount and payment method" : "Définir le montant total et le mode de paiement",
"Comments" : "Commentaires",
"I want to buy" : "Je veux acheter",
"I want to sell" : "Je veux vendre",
"Asset" : "Actif",
"With fiat currency" : "Avec monnaie fiduciaire",
"USDT" : "USDT",
"Price type" : "Type de prix",
"Fixed" : "Fixé",
"Floating" : "Flottant",
"The fixed price must be between 4,4645,456" : "Le prix fixé doit être entre 4,4645,456",
"Your price" : "Votre prix",
"Next" : "Suivant",
"More expensive order" : "Ordre plus cher",
"The series of screenshots seems to represent a user interface for posting an advertisement to buy or sell cryptocurrencies. The user has the option to set the transaction type, amount, price, and payment method." : "La série de captures d'écran semble représenter une interface utilisateur pour publier une annonce d'achat ou de vente de cryptomonnaies. L'utilisateur a la possibilité de définir le type de transaction, le montant, le prix et le mode de paiement.",
      }
    },
    ru:{
      translation:{
        "ENJOYUNION CLUB" : "КЛУБ ENJOYUNION",
"REGISTRATION" : "РЕГИСТРАЦИЯ",
"SPONSOR: JOAO VICTOR" : "СПОНСОР: ЖОАО ВИКТОР",
"* NAME" : "* ИМЯ",
"* LAST NAME" : "* ФАМИЛИЯ",
"* LOGIN" : "* ЛОГИН",
"* COUNTRY" : "* СТРАНА",
"* EMAIL" : "* ЭЛЕКТРОННАЯ ПОЧТА",
"* WHATSAPP" : "* WHATSAPP",
"* PASSWORD" : "* ПАРОЛЬ",
"* CONFIRM PASSWORD" : "* ПОДТВЕРДИТЬ ПАРОЛЬ",
"[REGISTER]" : "[ЗАРЕГИСТРИРОВАТЬ]",
"Page2" : "Страница 2",
"ENJOYUNION CLUB" : "КЛУБ ENJOYUNION",
"WELCOME" : "ДОБРО ПОЖАЛОВАТЬ",
"NEW LOGIN" : "НОВЫЙ ЛОГИН",
"[Create an account]" : "[Создать учетную запись]",
"LOG IN TO YOUR ACCOUNT" : "ВОЙТИ В СВОЮ УЧЕТНУЮ ЗАПИСЬ",
"* USERNAME" : "* ИМЯ ПОЛЬЗОВАТЕЛЯ",
"* PASSWORD" : "* ПАРОЛЬ",
"[Log in]" : "[Войти]",
"Forgot your password?" : "Забыли пароль?",
"[Continue with Google]" : "[Продолжить с Google]",
"[Continue with Facebook]" : "[Продолжить с Facebook]",
"[Continue with Apple]" : "[Продолжить с Apple]",
"Page3" : "Страница 3",
"RESET PASSWORD" : "СБРОСИТЬ ПАРОЛЬ",
"Enter your registered email so we can send you instructions to reset your password" : "Введите вашу зарегистрированную электронную почту, чтобы мы могли отправить вам инструкции по сбросу пароля",
"* EMAIL" : "* ЭЛЕКТРОННАЯ ПОЧТА",
"[Send]" : "[Отправить]",
"Page4" : "Страница 4",
"* QUALIFICATION" : "* КВАЛИФИКАЦИЯ",
"* DONATIONS RECEIVED" : "* ПОЛУЧЕННЫЕ ПОЖЕРТВОВАНИЯ",
"* DONATIONS MADE" : "* СДЕЛАННЫЕ ПОЖЕРТВОВАНИЯ",
"* COMMUNITY EARNINGS" : "* ДОХОДЫ СООБЩЕСТВА",
"* TOTAL EARNINGS" : "* ОБЩИЕ ДОХОДЫ",
"* AVAILABLE BALANCE" : "* ДОСТУПНЫЙ БАЛАНС",
"* Donations" : "* Пожертвования",
"* 68%" : "* 68%",
"* $4,000.00" : "* $4,000.00",
"* Progress Limit" : "* Предел Прогресса",
"* Community" : "* Сообщество",
"* 90%" : "* 90%",
"* $1,000.00" : "* $1,000.00",
"* Progress Limit" : "* Предел Прогресса",
"* Donations Made" : "* Сделанные Пожертвования",
"* 8" : "* 8",
"* Donations Received" : "* Полученные Пожертвования",
"* 6" : "* 6",
"* Referral Link" : "* Реферальная Ссылка",
"* Active Donations" : "* Активные Пожертвования",
"* $1,000.00" : "* $1,000.00",
"* Donation Return" : "* Возврат Пожертвования",
"* $1,500.00" : "* $1,500.00",
"* Remaining" : "* Осталось",
"* 01" : "* 01",
"* DAY" : "* ДЕНЬ",
"* For your donation" : "* Для вашего пожертвования",
"* Your Donation" : "* Ваше Пожертвование",
"* $0.00" : "* $0.00",
"[DONATE]" : "[ПОЖЕРТВОВАТЬ]",
"Page5" : "Страница 5",
"* Statement" : "* Выписка",
"* Donations Received" : "* Полченные Пожертвования",
"* Donations Made" : "* Сделанные Пожертвования",
"* Network Commissions" : "* Комиссии Сети",
"* Withdrawals" : "* Выводы",
"* Date/Time" : "* Дата/Время",
"* User" : "* Пользователь",
"* Transaction Hash" : "* Хеш Транзакции",
"* Amount" : "* Сумма",
"* 01/27/2024 at 00:00 - Microsoft - $1,000.00" : "* 01/27/2024 в 00:00 - Microsoft - $1,000.00",
"* Floyd Miles - Yahoo - $5.00" : "* Floyd Miles - Yahoo - $5.00",
"* Ronald Richards - Adobe - $400.00" : "* Ronald Richards - Adobe - $400.00",
"* Marvin McKinney - Tesla - $150.00" : "* Marvin McKinney - Tesla - $150.00",
"* Jerome Bell - Google - $30.00" : "* Jerome Bell - Google - $30.00",
"* Kathryn Murphy - Microsoft - $50.00" : "* Kathryn Murphy - Microsoft - $50.00",
"* Jacob Jones - Yahoo - $80.00" : "* Jacob Jones - Yahoo - $80.00",
"* Jacob Jones - Yahoo - $80.00" : "* Jacob Jones - Yahoo - $80.00",
"* Kathryn Murphy - Microsoft - $50.00" : "* Kathryn Murphy - Microsoft - $50.00",
"* Jacob Jones - Yahoo - $80.00" : "* Jacob Jones - Yahoo - $80.00",
"* Shown data from 1 to 8 out of 256K records" : "* Показаны данные с 1 по 8 из 256K записей",
"* ACCESS" : "* ВХОД",
"Page6" : "Страница 6",
"* Donations Received" : "* Полченные Пожертвования",
"* Donations Made" : "* Сделанные Пожертвования",
"* Network Commissions" : "* Комиссии Сети",
"* Withdrawals" : "* Выводы",
"* Date/Time" : "* Дата/Время",
"* User" : "* Пользователь",
"* Level" : "* Уровень",
"* Commission" : "* Комиссия",
"* Total" : "* Всего",
"* 01/27/2024 at 00:00 - Microsoft - 10 - $1,000.00 - $1,580.00" : "* 01/27/2024 в 00:00 - Microsoft - 10 - $1,000.00 - $1,580.00",
"* 01/27/2024 at 00:00 - Yahoo - 2 - $5.00 - $580.00" : "* 01/27/2024 в 00:00 - Yahoo - 2 - $5.00 - $580.00",
"* 01/27/2024 at 00:00 - Yahoo - 3 - $5.00 - $575.00" : "* 01/27/2024 в 00:00 - Yahoo - 3 - $5.00 - $575.00",
"* 01/27/2024 at 00:00 - Microsoft - 4 - $80.00 - $570.00" : "* 01/27/2024 в 00:00 - Microsoft - 4 - $80.00 - $570.00",
"* 01/27/2024 at 00:00 - Microsoft - 4 - $50.00 - $490.00" : "* 01/27/2024 в 00:00 - Microsoft - 4 - $50.00 - $490.00",
"* 01/27/2024 at 00:00 - Microsoft - 6 - $50.00 - $440.00" : "* 01/27/2024 в 00:00 - Microsoft - 6 - $50.00 - $440.00",
"* 01/27/2024 at 00:00 - Yahoo - 7 - $80.00 - $390.00" : "* 01/27/2024 в 00:00 - Yahoo - 7 - $80.00 - $390.00",
"* 01/27/2024 at 00:00 - Yahoo - 3 - $80.00 - $310.00" : "* 01/27/2024 в 00:00 - Yahoo - 3 - $80.00 - $310.00",
"* 01/27/2024 at 00:00 - Microsoft - 1 - $50.00 - $230.00" : "* 01/27/2024 в 00:00 - Microsoft - 1 - $50.00 - $230.00",
"* 01/27/2024 at 00:00 - Yahoo - 1 - $80.00 - $180.00" : "* 01/27/2024 в 00:00 - Yahoo - 1 - $80.00 - $180.00",
"* Shown data from 1 to 8 out of 256K records" : "* Показаны данные с 1 по 8 из 256K записей",
"Page7" : "Страница 7",
"* Statement" : "* Выписка",
"* Donations Received" : "* Полченные Пожертвования",
"* Donations Made" : "* Сделанные Пожертвования",
"* Network Commissions" : "* Комиссии Сети",
"* Withdrawals" : "* Выводы",
"* Date/Time" : "* Дата/Время",
"* User" : "* Пользователь",
"* Transaction Hash" : "* Хеш Транзакции",
"* Amount" : "* Сумма",
"* 01/27/2024 at 00:00 - Microsoft - ACCESS - $1,000.00" : "* 01/27/2024 в 00:00 - Microsoft - ACCESS - $1,000.00",
"* Floyd Miles - Yahoo - ACCESS - $5.00" : "* Floyd Miles - Yahoo - ACCESS - $5.00",
"* Ronald Richards - Adobe - ACCESS - $400.00" : "* Ronald Richards - Adobe - ACCESS - $400.00",
"* Marvin McKinney - Tesla - ACCESS - $150.00" : "* Marvin McKinney - Tesla - ACCESS - $150.00",
"* Jerome Bell - Google - ACCESS - $30.00" : "* Jerome Bell - Google - ACCESS - $30.00",
"* Kathryn Murphy - Microsoft - ACCESS - $50.00" : "* Kathryn Murphy - Microsoft - ACCESS - $50.00",
"* Jacob Jones - Yahoo - ACCESS - $80.00" : "* Jacob Jones - Yahoo - ACCESS - $80.00",
"* Jacob Jones - Yahoo - ACCESS - $80.00" : "* Jacob Jones - Yahoo - ACCESS - $80.00",
"* Kathryn Murphy - Microsoft - ACCESS - $50.00" : "* Kathryn Murphy - Microsoft - ACCESS - $50.00",
"* Jacob Jones - Yahoo - ACCESS - $80.00" : "* Jacob Jones - Yahoo - ACCESS - $80.00",
"* Shown data from 1 to 8 out of 256K records" : "* Показаны данные с 1 по 8 из 256K записей",
"Page8" : "Страница 8",
"* MY COMMUNITY" : "* МОЕ СООБЩЕСТВО",
"* DIRECT" : "* ПРЯМЫЕ",
"* INDIRECT" : "* НЕПРЯМЫЕ",
"* ACTIVE" : "* АКТИВНЫЙ",
"* 7" : "* 7",
"* 1,780" : "* 1,780",
"* 987" : "* 987",
"* 793" : "* 793",
"* 1,787" : "* 1,787",
"* USER" : "* ПОЛЬЗОВАТЕЛЬ",
"* NAME" : "* ИМЯ",
"* COUNTRY" : "* СТРАНА",
"* WHATSAPP" : "* WHATSAPP",
"* TELEGRAM" : "* TELEGRAM",
"* DISCORD" : "* DISCORD",
"* HELP" : "* ПОМОЩЬ",
"* COMMUNITY" : "* СООБЩЕСТВО",
"* Victor - Microsoft - [United States Flag] - +55 (12) 99812-3332 - @test - @test - $100 - 4" : "* Виктор - Microsoft - [Флаг Соединенных Штатов] - +55 (12) 99812-3332 - @teste - @teste - $100 - 4",
"* Floyd Miles - Yahoo - [India Flag] - +55 (12) 99812-3332 - @test - @test - $300 - 5" : "* Флойд Майлз - Yahoo - [Флаг Индии] - +55 (12) 99812-3332 - @teste - @teste - $300 - 5",
"* Ronald Richards - Adobe - [United States Flag] - +55 (12) 99812-3332 - @test - @test - $400 - 6" : "* Рональд Ричардс - Adobe - [Флаг Соединенных Штатов] - +55 (12) 99812-3332 - @teste - @teste - $400 - 6",
"* Marvin McKinney - Tesla - [Spain Flag] - +55 (12) 99812-3332 - @test - @test - $800 - 7" : "* Марвин Маккинни - Tesla - [Флаг Испании] - +55 (12) 99812-3332 - @teste - @teste - $800 - 7",
"* Jerome Bell - Google - [United Kingdom Flag] - +55 (12) 99812-3332 - @test - @test - $300 - 2" : "* Джером Белл - Google - [Флаг Соединенного Королевства] - +55 (12) 99812-3332 - @teste - @teste - $300 - 2",
"* Kathryn Murphy - Microsoft - [Spain Flag] - +55 (12) 99812-3332 - @test - @test - $200 - 9" : "* Кэтрин Мерфи - Microsoft - [Флаг Испании] - +55 (12) 99812-3332 - @teste - @teste - $200 - 9",
"* Jacob Jones - Yahoo - [United Kingdom Flag] - +55 (12) 99812-3332 - @test - @test - $100 - 7" : "* Джейкоб Джонс - Yahoo - [Флаг Соединенного Королевства] - +55 (12) 99812-3332 - @teste - @teste - $100 - 7",
"* Jacob Jones - Yahoo - [India Flag] - +55 (12) 99812-3332 - @test - @test - $100 - 7" : "* Джейкоб Джонс - Yahoo - [Флаг Индии] - +55 (12) 99812-3332 - @teste - @teste - $100 - 7",
"* Kathryn Murphy - Microsoft - [Spain Flag] - +55 (12) 99812-3332 - @test - @test - $800 - 3" : "* Кэтрин Мерфи - Microsoft - [Флаг Испании] - +55 (12) 99812-3332 - @teste - @teste - $800 - 3",
"* Jacob Jones - Yahoo - [Spain Flag] - +55 (12) 99812-3332 - @test - @test" : "* Джейкоб Джонс - Yahoo - [Флаг Испании] - +55 (12) 99812-3332 - @teste - @teste",
"* Shown data from 1 to 8 out of 256K records" : "* Показаны данные с 1 по 8 из 256K записей",
"Page9" : "Страница 9",
"* MY COMMUNITY" : "* МОЕ СООБЩЕСТВО",
"* USER" : "* ПОЛЬЗОВАТЕЛЬ",
"* NAME" : "* ИМЯ",
"*COUNTRY" : "* СТРАНА",
"* WHATSAPP" : "* WHATSAPP",
"* TELEGRAM" : "* TELEGRAM",
"* DISCORD" : "* DISCORD",
"* HELP" : "* ПОМОЩЬ",
"* COMMUNITY" : "* СООБЩЕСТВО",
"* Shown data from 1 to 8 out of 256K records" : "* Показаны данные с 1 по 8 из 256K записей",
"Page10" : "Страница 10",
"* FINANCIAL DATA" : "* ФИНАНСОВЫЕ ДАННЫЕ",
"* Scammer | enun | bep - 20" : "* Мошенник | enun | bep - 20",
"* 0x3801410DcEa87EFA2414ECC866EcA" : "* 0x3801410DcEa87EFA2414ECC866EcA",
"password" : "пароль",
"financial password" : "финансовый пароль",
"save" : "сохранить",
"connect wallet" : "подключить кошелек",
"Start by connecting to one of the wallets below. Make sure you securely store your private keys or recovery phrases, never share them with anyone." : "Начните с подключения к одному из кошельков ниже. Убедитесь, что вы надежно храните свои приватные ключи или фразы восстановления, никогда не делитесь ими ни с кем.",
"I still don't have a wallet" : "У меня все еще нет кошелька",
"learn how to connect" : "узнать, как подключить",
"REGISTRATION DETAILS" : "ДАННЫЕ О РЕГИСТРАЦИИ",
"REGISTRATION DETAILS" : "ДЕТАЛИ РЕГИСТРАЦИИ",
"SECURITY" : "БЕЗОПАСНОСТЬ",
"FINANCIAL" : "ФИНАНСОВЫЙ",
"Login" : "ЛОГИН",
"Joao Victor" : "Жоао Виктор",
"Surname" : "ФАМИЛИЯ",
"Joao Victor" : "Жоао Виктор",
"Email" : "ЭЛЕКТРОННАЯ ПОЧТА",
"JOAO@TESTE123.COM" : "JOAO@TESTE123.COM",
"[check email]" : "[проверить электронную почту]",
"Instagram" : "ИНСТАГРАМ",
"De Castilho" : "ДЕ КАСТИЛЬО",
"Phone" : "ТЕЛЕФОН",
"+55 12 992007383" : "+55 12 992007383",
"Discord" : "DISCORD",
"Facebook" : "FACEBOOK",
"VK" : "VK",
"TikTok" : "TIKTOK",
"REGISTRATION DETAILS" : "ДАННЫЕ О РЕГИСТРАЦИИ",
"REGISTRATION" : "РЕГИСТРАЦИЯ",
"SECURITY" : "БЕЗОПАСНОСТЬ",
"FINANCIAL" : "ФИНАНСОВЫЙ",
"Login" : "ЛОГИН",
"Joao Victor" : "Жоао Виктор",
"Surname" : "ФАМИЛИЯ",
"Joao Victor" : "Жоао Виктор",
"Instagram" : "ИНСТАГРАМ",
"De Castilho" : "ДЕ КАСТИЛЬО",
"Discord" : "DISCORD",
"(empty field)" : "(пустое поле)",
"Facebook" : "FACEBOOK",
"(empty field)" : "(пустое поле)",
"VK" : "VK",
"(empty field)" : "(пустое поле)",
"TikTok" : "TIKTOK",
"(empty field)" : "(пустое поле)",
"Email" : "ЭЛЕКТРОННАЯ ПОЧТА",
"JOAO@TESTE123.COM" : "JOAO@TESTE123.COM",
"[check email]" : "[проверить электронную почту]",
"check the code sent to your email and enter it in the field below:" : "проверьте код, отправленный на вашу электронную почту, и введите его в поле ниже:",
"[send]" : "[отправить]",
"Phone" : "ТЕЛЕФОН",
"+55 12 992007383" : "+55 12 992007383",
"SECURITY DETAILS" : "ДАННЫЕ О БЕЗОПАСНОСТИ",
"registration details" : "данные о регистрации",
"Security" : "Безопасность",
"financial" : "финансовый",
"current password" : "текущий пароль",
"New password" : "Новый пароль",
"confirm password" : "подтвердите пароль",
"two-factor authentication (2FA)" : "двухфакторная аутентификация (2FA)",
"Scan the QR code" : "Отсканируйте QR-код",
"Download and install the Google Authenticator app" : "Загрузите и установите приложение Google Authenticator",
"[Download on] App Store [Get it on] Google Play" : "[Скачать на] App Store [Получите его на] Google Play",
"copy the USER MANUAL" : "скопируйте РУКОВОДСТВО ПОЛЬЗОВАТЕЛЯ",
"SAAJAL02014U3" : "SAAJAL02014U3",
"Enter 2FA code" : "Введите код 2FA",
"save" : "сохранить",
"MAKE YOUR DONATION" : "СДЕЛАЙТЕ СВОЮ ДОНАЦИЮ",
"Choose your donation" : "Выберите свою донату",
"$5" : "$5",
"Donate manually Donate automatically" : "Пожертвовать вручную Пожертвовать автоматически",
"MAKE YOUR DONATION" : "СДЕЛАЙТЕ СВОЮ ДОНАЦИЮ",
"Choose your donation" : "Выберите свою донату",
"$5" : "$5",
"Donate manually Donate automatically" : "Пожертвовать вручную Пожертвовать автоматически",
"Selected donation amount" : "Выбранное пожертвование на сумму",
"$5.00" : "$5.00",
"(8.62068966 ENUM)" : "(8.62068966 ENUM)",
"Attention: By confirming below, you acknowledge that you are making a donation through the Enjoy Union system and agree to the terms and conditions." : "Внимание: Подтверждая ниже, вы осознаете, что делаете пожертвование через систему Enjoy Union и соглашаетесь с условиями и положениями.",
"confirm donation" : "подтвердить пожертвование",

"CONTINUE WITH DONATION" : "ПРОДОЛЖИТЬ С ДОНАЦИЕЙ",
"LOGIN" : "ЛОГИН",
"USDT" : "USDT",
"ENUM VALUE" : "ЗНАЧЕНИЕ ENUM",
"MANUAL DONATION" : "РУЧНАЯ ДОНАЦИЯ",
"STATUS" : "СТАТУС",
"Victor" : "Виктор",
"$2,000" : "$2,000",
"3,448.2758 ENUM" : "3,448.2758 ENUM",
"donate" : "пожертвовать",
"Completed" : "Завершено",
"Miles Floyd" : "Флойд Майлз",
"Partially" : "Частично",
"Richard Ronalds" : "Рональд Ричардс",
"Pending" : "В ожидании",
"CANCEL DONATION" : "ОТМЕНИТЬ ПОЖЕРТВОВАНИЕ",
"You must complete your donation within a maximum period of 25 minutes." : "Необходимо завершить вашу пожертвование в течение максимального периода 25 минут.",
"CONTINUE WITH DONATION" : "ПРОДОЛЖИТЬ С ДОНАЦИЕЙ",
"LOGIN" : "ЛОГИН",
"USDT" : "USDT",
"ENUM VALUE" : "ЗНАЧЕНИЕ ENUM",
"ENUM WALLET" : "ENUM КОШЕЛЕК",
"After completing the donation, provide the transaction address below." : "После завершения пожертвования сообщите адрес транзакции ниже.",
"txn hash:" : "txn хеш:",
"[finish]" : "[завершить]",

"MAKE A DONATION" : "СДЕЛАЙТЕ ПОЖЕРТВОВАНИЕ",
"LOGIN" : "ЛОГИН",
"USDT" : "USDT",
"ENUM VALUE" : "ЗНАЧЕНИЕ ENUM",
"ENUM WALLET" : "ENUM КОШЕЛЕК",
"After completing the donation, provide the transaction address below." : "После завершения пожертвования сообщите адрес транзакции ниже.",
"txn hash:" : "txn хеш:",
"[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]" : "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]",
"[finish]" : "[завершить]",

"MAKE A DONATION" : "СДЕЛАЙТЕ ПОЖЕРТВОВАНИЕ",
"LOGIN" : "ЛОГИН",
"USDT" : "USDT",
"ENUM VALUE" : "ЗНАЧЕНИЕ ENUM",
"ENUM WALLET" : "ENUM КОШЕЛЕК",
"After completing the donation, provide the transaction address below." : "После завершения пожертвования сообщите адрес транзакции ниже.",
"txn hash:" : "txn хеш:",
"[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]" : "[https://bscscan.com/tx/0xd6f099bc08c5e3d3...]",
"[finish]" : "[завершить]",
"MAKE A DONATION" : "СДЕЛАЙТЕ ПОЖЕРТВОВАНИЕ",
"Choose your donation" : "Выберите свою донату",
"$5" : "$5",
"[Donate manually]" : "[Пожертвовать вручную]",
"[Donate automatically]" : "[Пожертвовать автоматически]",
"Selected donation amount" : "Выбранное пожертвование на сумму",
"$5.00" : "$5.00",
"(8.62068966 ENUM)" : "(8.62068966 ENUM)",
"To perform an automatic donation, you must have an active connect wallet." : "Для выполнения автоматической пожертвования необходимо иметь активный кошелек connect.",
"By pressing one button, your donation will be made automatically, and you can leave the platform without waiting for the donation to be completed. Make sure your ENUM wallet is connected." : "Таким образом, нажатием одной кнопки ваше пожертвование будет сделано автоматически, и вы сможете покинуть платформу, не ожидая момента завершения пожертвования. Убедитесь, что ваш ENUM кошелек подключен.",
"[Confirm donation]" : "[Подтвердить пожертвование]",
"DONATION COMPLETED" : "ДОНАЦИЯ ЗАВЕРШЕНА",
"LOGIN" : "ЛОГИН",
"USDT AMOUNT" : "СУММА USDT",
"ENUM AMOUNT" : "СУММА ENUM",
"TRANSACTION HASH" : "ХЭШ ТРАНЗАКЦИИ",
"STATUS" : "СТАТУС",
"Victor" : "Виктор",
"$2,000" : "$2,000",
"3,448,2758 [HASH]" : "3,448,2758 [ХЭШ]",
"Completed" : "Завершено",
"Miles Floyd" : "Флойд Майлз",
"$2,000" : "$2,000",
"3,448,2758 [HASH]" : "3,448,2758 [ХЭШ]",
"Completed" : "Завершено",
"Richard Ronalds" : "Рональд Ричардс",
"$2,000" : "$2,000",
"3,448,2758 [HASH]" : "3,448,2758 [ХЭШ]",
"Completed" : "Завершено",
"LOCAL CRYPTO P2P" : "ЛОКАЛЬНЫЙ КРИПТО P2P",
"buy | sell" : "купить | продать",
"[ENUM] [USDT] [BTC] [ETH] [BNB] [ADA] [BRL] � [Publish new ad]" : "[ENUM] [USDT] [BTC] [ETH] [BNB] [ADA] [BRL] � [Опубликовать новое объявление]",
"Available/Limit" : "Доступно/Лимит",
"Advertiser Price Available/Limit Payment INFO" : "Рекламодатель Цена Доступно/Лимит Оплата ИНФО",
"Joao Victor 4,975 BRL 34,935.10 USDTPIX Information" : "Жоао Виктор 4,975 BRL 34,935.10 USDTPIX Информация",
"368 orders 99.50% completed $10,000.00 - $150,000.00 99.73%" : "368 заказов 99.50% выполнено $10,000.00 - $150,000.00 99.73%",
"The image shows the interface of the peer-to-peer cryptocurrency trading platform. The fields show the advertiser's name, the price in Brazilian Reals (BRL), the available quantity or limit in USDT (Tether), and the payment method, which is PIX (Brazilian instant payment system). The information button likely leads to more detailed information about the advertiser's offer." : "Изображение показывает интерфейс торговой платформы криптовалюты peer-to-peer. Поля показывают имя рекламодателя, цену в бразильских реалах (BRL), доступное количество или лимит в USDT (Tether) и метод оплаты, который является PIX (бразильская система мгновенных платежей). Кнопка информации вероятно ведет к более подробной информации о предложении рекламодателя.",
"LOCAL CRYPTO P2P" : "ЛОКАЛЬНЫЙ КРИПТО P2P",
"Trading requirements" : "Требования к торговле",
"* Minimum donation $5" : "* Минимальное пожертвование $5",
"* 2FA" : "* 2FA",
"* Email" : "* Электронная почта",
"* Social network 1" : "* Социальная сеть 1",
"* Social network 2" : "* Социальная сеть 2",
"Included" : "Включено"



      }
    },
    ur:{
      translation:{
Dashboard: "ڈیش بورڈ",
"Donate manually": "دستی طریقے سے دانش",
"Donate automatically": "خود بخود دانش",
STATEMENT: "بیانیہ",
"MAKE YOUR DONATION": "اپنی دانش بنائیں",
"Choose your donation": "اپنی دانش کا انتخاب کریں",
Donation: "دانش",
Donate: 'دانش کریں',
"Your donation": "آپ کی دانش",
"Donation Return": "دانش واپس",
"Active Donations": "فعال دانشیں",
Progress: "پیش رفت",
"Donations Received": "دانشیں موصول ہوئیں",
"Donations Made": "دانشیں کی گئیں",
Community: "کمیونٹی",
Donations: "دانشیں",
"Referral Link": "ریفرل لنک",
Statement: "بیانیہ",
"Showing data": "ڈیٹا دکھانا",
entries: "انٹریز",
"Donations Received": "دانشیں موصول ہوئیں",
"Network Commissions": "نیٹ ورک کمیشنز",
"Donations Made": "دانشیں کی گئیں",
"Date/Time": "تاریخ/وقت",
User: "صارف",
Access: "رسائی",
"Hash Transaction": "ہیش ٹرانزیکشن",
Valor: "قدر",
Directs: "سیدھے",
Indirect: "غیر مستقیم",
Assets: "اثاثے",
Inactive: "غیر فعال",
Total: "کل",
"My Community": "میری کمیونٹی",
User: "صارف",
Name: "نام",
Country: "ملک",
WhatsApp: "واٹس ایپ",
Telegram: "ٹیلیگرام",
Discord: "ڈسکورڈ",
Help: "مدد",
Reviews: "تجزیے",
All: "سب",
Positive: "مثبت",
Negative: "منفی",
"Safe and reliable,Polite and friendly,Quick transaction, Patient": "محفوظ اور معتبر، شائستہ اور دوستانہ، فوری معاملہ، صبر",
COIN: "سکہ",
PRICE: "قیمت",
Payment: "ادائیگی",
INFO: "معلومات",
Information: "معلومات",
"Available / Limit": "دستیاب / حد",
"Online Sale Advertisement": "آن لائن فروخت اشتہار",
"Online Purchase Advertisement": "آن لائن خریداری اشتہار",
ONLINE: "آن لائن",
"ENTERED IN": "میں داخل کیا",
Minutes: "منٹ",
"Average Release Time": "اوسط رہائی کا وقت",
"Completion Fee": "مکمل فیس",
Trades: "ٹریڈز",
Sell: "فروخت",
Buy: "خریدیں",
"All negotiations": "تمام مذاکرات",
negatives: "منفیات",
Positives: "مثبت",
"Positive Ratings": "مثبت درجات",
back: "واپس",
Telegram: "ٹیلیگرام",
Whatsapp: "واٹس ایپ",
Email: "ای میل",
Home: "ہوم",
About: "کے بارے میں",
Blog: "بلاگ",
STATUS: "حالت",
"MANUAL DONATION": "دستی دانش",
"AMOUNT ENUN": "رقم اینی",
"AMOUNT USDT": "رقم USDT",
LOGIN: "لاگ ان",
"WALLET ENUN": "والٹ اینی",
"After completing your donation, please provide the transaction address below.": "اپنی دانش مکمل کرنے کے بعد، نیچے دیے گئے ٹرانزیکشن کا پتہ فراہم کریں۔",
"txn hash": "ٹیکس ہیش",
Finish: "ختم کریں",
Donations: "دانشیں",
"Referral Link": "ریفرل لنک",
"Average Payment Time": "اوسط ادائیگی کا وقت",
"Social Media": "سوشل میڈیا",
"Online Advertisement": "آن لائن اشتہار",
Comments: "تبصرے",
"Fiat Currency Name": "فیات کرنسی کا نام",
"Fiat Currency Symbol": "فیات کرنسی علامت",
Register: "رجسٹر",
"Country Name": "ملک کا نام",
REGISTER: "رجسٹر",
"Available/Limit": "دستیاب / حد",
"publish new ad": "نیا اشتہار شائع کریں",
"LOCAL CRYPTO": "مقامی کرپٹو",
"LOCAL CRYPTO P2P": "مقامی کرپٹو P2P",
"I have met the requirements": "میں نے شرائط پورے کیے ہیں",
"Social network 2": "سوشل نیٹ ورک 2",
orders: "آرڈرز",
completed: "مکمل",
Information: "معلومات",
"Trading requirements": "ٹریڈنگ کی ضروریات",
"Minimum Donation": "کم از کم دانش",
"Social network 1": "سوشل نیٹ ورک 1",
enabled: "فعال",
"desired amount": "مطلوبہ مقدار",
ENUM: "اینیم",
BUY: "خریدیں",
SELL: "فروخت",
Send: "بھیجیں",
"Financial password": "مالی پاس ورڈ",
Password: "پاس ورڈ",
"My Wallet": "میرا والیٹ",
"Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "نیچے دی گئی والیٹس میں سے کسی ایک کے ساتھ جڑ کر شروع کریں۔ اپنی نجی کلیدوں یا بیج جملوں کو محفوظ طریقے سے ذخیرہ کرنے کی اطمینان حاصل کریں۔ انہیں کسی کے ساتھ شیئر نہ کریں۔",
"Learn how to connect": "کنیکٹ کرنے کا طریقہ سیکھیں",
"I still don't have a wallet": "میرے پاس ابھی بھی والیٹ نہیں ہے",
"connect wallet": "والیٹ کنیکٹ کریں",
"FINANCIAL DATA": "مالیاتی ڈیٹا",
Observations: 'مشاہدات',
'Define the Total Value and Payment Method': 'کل قیمت اور ادائیگی کا طریقہ معین کریں',
'Define type and price': 'قسم اور قیمت معین کریں',
'Publish Regular Advertisement': 'معمولی اشتہار شائع کریں',
previous: 'پچھلا',
Next: 'اگلا',
'Select the payment method': 'ادائیگی کا طریقہ منتخب کریں',
PicPay: 'پک پے',
'Transfer with specific bank': 'خاص بینک کے ساتھ منتقلی',
'Bank Transfer': 'بینک کی منتقلی',
Recommended: 'تجویز شدہ',
Trubit: 'ٹروبٹ',
'Itaú Brazil': 'آئٹو برازیل',
Confirm: 'تصدیق',
'Payment institution': 'ادائیگی کی ادارہ',
'Token NameToken contract': 'ٹوکن نام ٹوکن کنٹریکٹ',
REGISTER: 'رجسٹر',
COMMUNITY: 'کمیونٹی',
HELP: 'مدد',
DISCORD: 'ڈسکورڈ',
TELEGRAM: 'ٹیلیگرام',
COUNTRY: 'ملک',
WHATSAPP: 'واٹس ایپ',
NAME: 'نام',
USER: 'صارف',
'Showing data': 'ڈیٹا دکھانا',
entries: 'انٹریز',
'MY COMMUNITY': 'میری کمیونٹی',
Telegram: 'ٹیلیگرام',
'Local Crypto': 'مقامی کرپٹو',
'Financial data': 'مالیاتی ڈیٹا',
'My community': 'میری کمیونٹی',
Statement: 'بیانیہ',
Dashboard: 'ڈیش بورڈ',
Donation: 'دانش',
'Confirm donation': 'تصدیق دانش',
'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': 'توجہ: نیچے تصدیق کرتے ہوئے، آپ کو علم ہے کہ آپ Enjoy Union نظام کے ذریعہ دانش کر رہے ہیں، اور آپ شرائط و ضوابط کو مانتے ہیں۔',
'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': 'خود بخود دانش کرنے کے لئے، آپ کو اپنے کنیکٹ والیٹ کو فعال کرنا ہوگا۔ اس طریقے سے، صرف ایک کلک کے ساتھ، آپ کی دانش خود بخود ہو جائے گی، اور آپ اسے بیانیہ پینل میں چیک کرسکتے ہیں۔',
'Chosen donation in the amount of': 'منتخب کردہ دانش مقدار میں',
'Fiat Currency Name': 'فیات کرنسی کا نام',
'Donate automatically': 'خود بخود دانش',
'Choose your donation': 'اپنی دانش کا انتخاب کریں',
"DONATE": "دانش کریں",
"COMPLETED": "مکمل",
"MAKE DONATION": "دانش بنائیں",
"LOGIN": "لاگ ان",
"AMOUNT USDT": "رقم USDT",
"AMOUNT ENUN": "رقم اینی",
"MANUAL DONATION": "دستی دانش",
"STATUS": "حالت",
"MAKE YOUR DONATION": "اپنی دانش بنائیں",
Directs: 'سیدھے',
INDIRECT: 'غیر مستقیم',
ASSETS: 'اثاثے',
INACTIVE: 'غیر فعال',
TOTAL: 'کل',
COMMUNITY: 'کمیونٹی',
HELP: 'مدد',
DISCORD: 'ڈسکورڈ',
'Forgot your password?': 'اپنا پاس ورڈ بھول گئے؟',
Enter: 'داخل کریں',
'CREATE ACCOUNT': 'اکاؤنٹ بنائیں',
'NEW LOGIN': 'نیا لاگ ان',
WELCOME: 'خوش آمدید',
'LOG INTO YOUR ACCOUNT': 'اپنے اکاؤنٹ میں لاگ ان کریں',
SEND: 'بھیجیں',
'Please provide your registered email to send the password reset instructions.': 'براہ کرم اپنا رجسٹرڈ ای میل فراہم کریں تاکہ پاس ورڈ ری سیٹ ہدایات بھیجی جا سکیں۔',
'Reset your password': 'اپنا پاس ورڈ دوبارہ ترتیب دیں',
Registration: 'رجسٹریشن',
QUALIFICATION: 'وصولی',
'DONATIONS RECEIVED': 'دانشیں موصول ہوئیں',
'DONATIONS MADE': 'دانشیں کی گئیں',
'COMMUNITY GAINS': 'کمیونٹی کا حصول',
'TOTAL WINS': 'کل جیتیں',
'BALANCE AVAILABLE': 'دستیاب بیلنس',
'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'نیچے دی گئی والیٹس میں سے کسی ایک کے ساتھ جڑ کر شروع کریں۔ اپنی نجی کلیدوں یا بیج جملوں کو محفوظ طریقے سے ذخیرہ کرنے کی اطمینان حاصل کریں۔ انہیں کسی کے ساتھ شیئر نہ کریں۔',
'connect wallet': 'والیٹ کنیکٹ کریں',
'Learn how to connect': 'کنیکٹ کرنے کا طریقہ سیکھیں',
"I still don't have a wallet": "میرے پاس ابھی بھی والیٹ نہیں ہے",
'Financial password': 'مالی پاس ورڈ',
Password: 'پاس ورڈ',
'FINANCIAL DATA': 'مالیاتی ڈیٹا',
Send: 'بھیجیں',
'REGISTRATION DATA': 'رجسٹریشن ڈیٹا',
SECURITY: 'سیکورٹی',
'Start by connecting to one of the wallets below.': 'نیچے دی گئی والیٹس میں سے کسی ایک کے ساتھ جڑ کر شروع کریں۔',
TIKTOK: 'ٹک ٹاک',
PHONE: 'فون',
'validate Email': 'ای میل کی تصدیق',
FACEBOOK: 'فیس بک',
DISCORD: 'ڈسکورڈ',
INSTAGRAM: 'انسٹاگرام',
'LAST NAME': 'آخری نام',
LOGIN: 'لاگ ان',
'Enter the 2FA code': '2FA کوڈ درج کریں',
'Manually copy the code': 'کوڈ کا دستیاب کریں',
'Download and install the Google Authenticator app.': 'گوگل اصلیت دار بنانے کی ایپ ڈاؤن لوڈ اور انسٹال کریں۔',
'Scan the QR CODE': 'QR کوڈ اسکین کریں',
'Two-factor authentication (2FA)': 'دو چار کی تصدیق (2FA)',
'CONFIRM PASSWORD': 'پاس ورڈ کی تصدیق',
'NEW PASSWORD': 'نیا پاس ورڈ',
'CURRENT PASSWORD': 'موجودہ پاس ورڈ',
"My Wallet": "میرا والیٹ",
"FINANCIAL": "مالی",
"My Profile": "میری پروفائل",
"Change Password": "پاس ورڈ تبدیل کریں",
"Log Out": "لاگ آوٹ",
      },
    },
    it:{
      translation:{
        Dashboard: "Dashboard",
"Donate manually": "Donare manualmente",
"Donate automatically": "Donare automaticamente",
STATEMENT: "Dichiarazione",
"MAKE YOUR DONATION": "FAI LA TUA DONAZIONE",
"Choose your donation": "Scegli la tua donazione",
Donation: "Donazione",
Donate: 'Donare',
"Your donation": "La tua donazione",
"Donation Return": "Ritorno della donazione",
"Active Donations": "Donazioni attive",
Progress: "Progresso",
"Donations Received": "Donazioni ricevute",
"Donations Made": "Donazioni effettuate",
Community: "Comunità",
Donations: "Donazioni",
"Refferal link": "Link di riferimento",
Statement: "Dichiarazione",
"Showing data": "Mostrando dati",
entries: "voci",
"Donations Received": "Donazioni ricevute",
"Network Commissions": "Commissioni di rete",
"Donations Made": "Donazioni effettuate",
"Date/Time": "Data/Ora",
User: "Utente",
Access: "Accesso",
"Hash Transaction": "Transazione di hash",
Valor: "Valore",
Directs: "Diretti",
Indirect: "Indiretto",
Assets: "Attività",
Inactive: "Inattivo",
Total: "Totale",
"My Community": "La mia comunità",
User: "Utente",
Name: "Nome",
Country: "Paese",
WhatsApp: "WhatsApp",
Telegram: "Telegram",
Discord: "Discord",
Help: "Aiuto",
Reviews: "Recensioni",
All: "Tutto",
Positive: "Positivo",
Negative: "Negativo",
"Safe and reliable,Polite and friendly,Quick transaction, Patient": "Sicuro e affidabile, Educati e amichevoli, Transazione veloce, Paziente",
COIN: "MONETA",
PRICE: "PREZZO",
Payment: "Pagamento",
INFO: "INFO",
Information: "Informazioni",
"Available / Limit": "Disponibile / Limite",
"Online Sale Advertisement": "Annuncio di vendita online",
"Online Purchase Advertisement": "Annuncio di acquisto online",
ONLINE: "ONLINE",
"ENTERED IN": "ENTRATO IN",
Minutes: "Minuti",
"Average Release Time": "Tempo medio di rilascio",
"Completion Fee": "Commissione di completamento",
Trades: "Scambi",
Sell: "Vendere",
Buy: "Acquistare",
"All negotiations": "Tutte le trattative",
negatives: "negativi",
Positives: "Positivi",
"Positive Ratings": "Valutazioni positive",
back: "indietro",
Telegram: "Telegram",
Whatsapp: "Whatsapp",
Email: "E-mail",
Home: "Casa",
About: "Informazioni",
Blog: "Blog",
STATUS: "STATO",
"MANUAL DONATION": "DONAZIONE MANUALE",
"AMOUNT ENUN": "IMPORTO ENUN",
"AMOUNT USDT": "IMPORTO USDT",
LOGIN: "ACCEDI",
"WALLET ENUN": "PORTAFOGLIO ENUN",
"After completing your donation, please provide the transaction address below.": "Dopo aver completato la tua donazione, fornisci l'indirizzo di transazione qui sotto.",
"txn hash": "hash della transazione",
Finish: "Fine",
Donations: "Donazioni",
"Referral Link": "Link di riferimento",
"Average Payment Time": "Tempo medio di pagamento",
"Social Media": "Social Media",
"Online Advertisement": "Annuncio online",
Comments: "Commenti",
"Fiat Currency Name": "Nome valuta fiat",
"Fiat Currency Symbol": "Simbolo valuta fiat",
Register: "Registrati",
"Country Name": "Nome del paese",
REGISTER: "REGISTRATI",
"Available/Limit": "Disponibile/Limite",
"publish new ad": "pubblica nuovo annuncio",
"LOCAL CRYPTO": "CRYPTO LOCALE",
"LOCAL CRYPTO P2P": "CRYPTO LOCALE P2P",
"I have met the requirements": "Ho soddisfatto i requisiti",
"Social network 2": "Rete sociale 2",
orders: "ordini",
completed: "completato",
Information: "Informazione",
"Trading requirements": "Requisiti di trading",
"Minimum Donation": "Donazione minima",
"Social network 1": "Rete sociale 1",
enabled: "abilitato",
"desired amount": "importo desiderato",
ENUM: "ENUM",
BUY: "ACQUISTO",
SELL: "VENDITA",
Send: "Invia",
"Financial password": "Password finanziaria",
Password: "Password",
"My Wallet": "Il mio portafoglio",
"Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Inizia connettendoti a uno dei portafogli qui sotto. Assicurati di conservare in modo sicuro le tue chiavi private o frasi di seed. Non condividerle mai con nessuno.",
"Learn how to connect": "Scopri come connetterti",
"I still don't have a wallet": "Non ho ancora un portafoglio",
"connect wallet": "connetti portafoglio",
"FINANCIAL DATA": "DATI FINANZIARI",
Observations: 'Osservazioni',
'Define the Total Value and Payment Method': 'Definisci il Valore Totale e il Metodo di Pagamento',
'Define type and price': 'Definisci tipo e prezzo',
'Publish Regular Advertisement': 'Pubblica Annuncio Regolare',
previous: 'precedente',
Next: 'Avanti',
'Select the payment method': 'Seleziona il metodo di pagamento',
PicPay: 'PicPay',
'Transfer with specific bank': 'Trasferimento con banca specifica',
'Bank Transfer': 'Bonifico bancario',
Recommended: 'Raccomandato',
Trubit: 'Trubit',
'Itaú Brazil': 'Itaú Brasile',
Confirm: 'Conferma',
'Payment institution': 'Istituto di pagamento',
'Token NameToken contract': 'Nome token Contratto token',
REGISTER: 'REGISTRATI',
COMMUNITY: 'COMUNITÀ',
HELP: 'AIUTO',
DISCORD: 'DISCORD',
TELEGRAM: 'TELEGRAMMA',
COUNTRY: 'PAESE',
WHATSAPP: 'WHATSAPP',
NAME: 'NOME',
USER: 'UTENTE',
'Showing data': 'Mostrando dati',
entries: 'voci',
'MY COMMUNITY': 'LA MIA COMUNITÀ',
Telegram: 'Telegramma',
'Local Crypto': 'Local Crypto',
'Financial data': 'Dati finanziari',
'My community': 'La mia comunità',
Statement: 'Dichiarazione',
Dashboard: 'Dashboard',
Donation:

 'Donazione',
'Confirm donation': 'Conferma donazione',
'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': "Attenzione: confermando di seguito, sei consapevole che stai effettuando la donazione tramite il sistema Enjoy Union e accetti i termini e le condizioni.",
'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': "Per effettuare una donazione automatica, devi avere attivato il tuo Portafoglio Connect. In questo modo, con un solo clic, la tua donazione verrà effettuata automaticamente e potrai verificarla nel pannello delle dichiarazioni.",
'Chosen donation in the amount of': 'Donazione scelta per un importo di',
'Fiat Currency Name': 'Nome valuta fiat',
'Donate automatically': 'Donare automaticamente',
'Choose your donation': 'Scegli la tua donazione',
"DONATE": "DONARE",
"COMPLETED": "COMPLETATO",
"MAKE DONATION": "EFFETTUA DONAZIONE",
"LOGIN": "ACCEDI",
"AMOUNT USDT": "IMPORTO USDT",
"AMOUNT ENUN": "IMPORTO ENUN",
"MANUAL DONATION": "DONAZIONE MANUALE",
"STATUS": "STATO",
"MAKE YOUR DONATION": "FAI LA TUA DONAZIONE",
Directs: 'Diretti',
INDIRECT: 'Indiretto',
ASSETS: 'Attività',
INACTIVE: 'Inattivo',
TOTAL: 'Totale',
COMMUNITY: 'Comunità',
HELP: 'Aiuto',
DISCORD: 'Discord',
'Forgot your password?': 'Hai dimenticato la password?',
Enter: 'Inserisci',
'CREATE ACCOUNT': 'CREA ACCOUNT',
'NEW LOGIN': 'NUOVO ACCESSO',
WELCOME: 'BENVENUTO',
'LOG INTO YOUR ACCOUNT': 'ACCEDI AL TUO ACCOUNT',
SEND: 'INVIA',
'Please provide your registered email to send the password reset instructions.': 'Fornisci la tua email registrata per inviare le istruzioni per il ripristino della password.',
'Reset your password': 'Reimposta la tua password',
Registration: 'Registrazione',
QUALIFICATION: 'QUALIFICAZIONE',
'DONATIONS RECEIVED': 'DONAZIONI RICEVUTE',
'DONATIONS MADE': 'DONAZIONI EFFETTUATE',
'COMMUNITY GAINS': 'VANTAGGI DELLA COMUNITÀ',
'TOTAL WINS': 'VITTORIE TOTALI',
'BALANCE AVAILABLE': 'BILANCIO DISPONIBILE',
'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'Inizia collegandoti a uno dei portafogli qui sotto. Assicurati di conservare in modo sicuro le tue chiavi private o le frasi di seed. Non condividerle con nessuno.',
'connect wallet': 'collega portafoglio',
'Learn how to connect': 'Scopri come collegarti',
"I still don't have a wallet": "Non ho ancora un portafoglio",
'Financial password': 'Password finanziaria',
Password: 'Password',
'FINANCIAL DATA': 'DATI FINANZIARI',
Send: 'Invia',
'REGISTRATION DATA': 'DATI DI REGISTRAZIONE',
SECURITY: 'SICUREZZA',
'Start by connecting to one of the wallets below.': 'Inizia collegandoti a uno dei portafogli qui sotto.',
TIKTOK: 'TIKTOK',
PHONE: 'TELEFONO',
'validate Email': 'convalida Email',
FACEBOOK: 'FACEBOOK',
DISCORD: 'DISCORD',
INSTAGRAM: 'INSTAGRAM',
'LAST NAME': 'COGNOME',
LOGIN: 'ACCEDI',
'Enter the 2FA code': 'Inserisci il codice 2FA',
'Manually copy the code': 'Copia manualmente il codice',
'Download and install the Google Authenticator app.': 'Scarica e installa l\'app Google Authenticator.',
'Scan the QR CODE': 'Scansiona il codice QR',
'Two-factor authentication (2FA)': 'Autenticazione a due fattori (2FA)',
'CONFIRM PASSWORD': 'CONFERMA PASSWORD',
'NEW PASSWORD': 'NUOVA PASSWORD',
'CURRENT PASSWORD': 'PASSWORD ATTUALE',
"My Wallet": "Il mio portafoglio",
"FINANCIAL": "FINANZIARIO",
"My Profile": "Il mio profilo",
"Change Password": "Cambia password",
"Log Out": "Esci",
      }
    },
    kr:{
      translation:{
        Dashboard: "대시 보드",
"Donate manually": "수동으로 기부",
"Donate automatically": "자동으로 기부",
STATEMENT: "성명서",
"MAKE YOUR DONATION": "기부하십시오",
"Choose your donation": "기부금 선택",
Donation: "기부금",
Donate: '기부',
"Your donation": "귀하의 기부금",
"Donation Return": "기부금 반환",
"Active Donations": "활성 기부",
Progress: "진행",
"Donations Received": "받은 기부",
"Donations Made": "한 기부",
Community: "커뮤니티",
Donations: "기부",
"Refferal link": "추천 링크",
Statement: "성명",
"Showing data": "데이터 표시 중",
entries: "항목",
"Donations Received": "받은 기부",
"Network Commissions": "네트워크 수수료",
"Donations Made": "한 기부",
"Date/Time": "날짜/시간",
User: "사용자",
Access: "접근",
"Hash Transaction": "해시 트랜잭션",
Valor: "가치",
Directs: "직접",
Indirect: "간접적",
Assets: "자산",
Inactive: "비활성",
Total: "합계",
"My Community": "내 커뮤니티",
User: "사용자",
Name: "이름",
Country: "국가",
WhatsApp: "WhatsApp",
Telegram: "텔레그램",
Discord: "디스코드",
Help: "도움말",
Reviews: "리뷰",
All: "모두",
Positive: "긍정적인",
Negative: "부정적인",
"Safe and reliable,Polite and friendly,Quick transaction, Patient": "안전하고 신뢰할 수 있음, 예의바르고 친근함, 신속한 거래, 환자",
COIN: "코인",
PRICE: "가격",
Payment: "지불",
INFO: "정보",
Information: "정보",
"Available / Limit": "사용 가능 / 제한",
"Online Sale Advertisement": "온라인 판매 광고",
"Online Purchase Advertisement": "온라인 구매 광고",
ONLINE: "온라인",
"ENTERED IN": "입력됨",
Minutes: "분",
"Average Release Time": "평균 공개 시간",
"Completion Fee": "완료 수수료",
Trades: "거래",
Sell: "판매",
Buy: "구매",
"All negotiations": "모든 협상",
negatives: "부정적",
Positives: "긍정적인",
"Positive Ratings": "긍정적인 평가",
back: "뒤로",
Telegram: "텔레그램",
Whatsapp: "Whatsapp",
Email: "이메일",
Home: "홈",
About: "약",
Blog: "블로그",
STATUS: "상태",
"MANUAL DONATION": "수동 기부",
"AMOUNT ENUN": "금액 ENUN",
"AMOUNT USDT": "금액 USDT",
LOGIN: "로그인",
"WALLET ENUN": "지갑 ENUN",
"After completing your donation, please provide the transaction address below.": "기부를 완료한 후 아래의 거래 주소를 제공하십시오.",
"txn hash": "txn 해시",
Finish: "완료",
Donations: "기부",
"Referral Link": "추천 링크",
"Average Payment Time": "평균 결제 시간",
"Social Media": "소셜 미디어",
"Online Advertisement": "온라인 광고",
Comments: "코멘트",
"Fiat Currency Name": "법정 통화 이름",
"Fiat Currency Symbol": "법정 통화 기호",
Register: "등록",
"Country Name": "국가 이름",
REGISTER: "레지스터",
"Available/Limit": "사용 가능/한도",
"publish new ad": "새 광고 게시",
"LOCAL CRYPTO": "지역 암호",
"LOCAL CRYPTO P2P": "로컬 크립토 P2P",
"I have met the requirements": "요구 사항을 충족시켰습니다",
"Social network 2": "소셜 네트워크 2",
orders: "주문",
completed: "완료",
Information: "정보",
"Trading requirements": "거래 요구 사항",
"Minimum Donation": "최소 기부",
"Social network 1": "소셜 네트워크 1",
enabled: "활성화 됨",
"desired amount": "원하는 금액",
ENUM: "ENUM",
BUY: "구매",
SELL: "판매",
Send: "보내기",
"Financial password": "금융 비밀번호",
Password: "암호",
"My Wallet": "내 지갑",
"Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "아래 지갑 중 하나에 연결하여 시작하십시오. 개인 키 또는 시드 구문을 안전하게 보관하십시오. 아무에게도 공유하지 마십시오.",
"Learn how to connect": "연결하는 방법 배우기",
"I still don't have a wallet": "아직 지갑이 없어요",
"connect wallet": "지갑 연결",
"FINANCIAL DATA": "재무 데이터",
Observations: '관찰',
'Define the Total Value and Payment Method': '총 가치와 결제 방법 정의',
'Define type and price': '유형 및 가격 정의',
'Publish Regular Advertisement': '정기 광고 게시',
previous: '이전',
Next: '다음',
'Select the payment method': '결제 방법 선택',
PicPay: 'PicPay',
'Transfer with specific bank': '특정 은행으로 이체',
'Bank Transfer': '은행 송금',
Recommended: '추천',
Trubit: 'Trubit',
'Itaú Brazil': '이타우 브라질',
Confirm: '확인',
'Payment institution': '결제 기관',
'Token NameToken contract': '토큰 이름 토큰 계약',
REGISTER: '등록',
COMMUNITY: '커뮤니티',
HELP: '도움',
DISCORD: '디스코드',
TELEGRAM: '텔레그램',
COUNTRY: '나라',
WHATSAPP: 'WhatsApp',
NAME: '이름',
USER: '사용자',
'Showing data': '데이터 표시 중',
entries: '항목',
'MY COMMUNITY': '내 커뮤니티',
Telegram: '텔레그램',
'Local Crypto': '지역 암호',
'Financial data': '금융 데이터',
'My community': '내 커뮤니티',
Statement: '성명서',
Dashboard: '대시 보드',
Donation: '기부',
'Confirm donation': '기부 확인',
'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': "주의: 아래 확인하면 Enjoy Union 시스템을 통해 기부를 진행 중이며 약관에 동의하는 것을 인지하고 있습니다.",
'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': "자동 기부를 하려면 Connect Wallet을 활성화해야합니다. 이렇게하면 한 번의 클릭으로 기부가 자동으로 이루어지며 성명서 패널에서 확인할 수 있습니다.",
'Chosen donation in the amount of': '선택한 기부금액',
'Fiat Currency Name': '법정 통화 이름',
'Donate automatically': '자동으로 기부',
'Choose your donation': '기부금 선택',
"DONATE": "기부",
"COMPLETED": "완료됨",
"MAKE DONATION": "기부",
"LOGIN": "로그인",
"AMOUNT USDT": "금액 USDT",
"AMOUNT ENUN": "금액 ENUN",
"MANUAL DONATION": "수동 기부",
"STATUS": "상태",
"MAKE YOUR DONATION": "기부하십시오",
Directs: '직접',
INDIRECT: '간접적',
ASSETS: '자산',
INACTIVE: '비활성',
TOTAL: '합계',
COMMUNITY: '커뮤니티',
HELP: '도움',
DISCORD: '디스코드',
'Forgot your password?': '비밀번호를 잊으셨나요?',
Enter: '입력',
'CREATE ACCOUNT': '계정 만들기',
'NEW LOGIN': '새로운 로그인',
WELCOME: '환영합니다',
'LOG INTO YOUR ACCOUNT': '귀하의 계정으로 로그인',
SEND: '보내기',
'Please provide your registered email to send the password reset instructions.': '비밀번호 재설정 지침을 보내려면 등록된 이메일을 제공하십시오.',
'Reset your password': '비밀번호 재설정',
Registration: '등록',
QUALIFICATION: '자격',
'DONATIONS RECEIVED': '받은 기부',
'DONATIONS MADE': '한 기부',
'COMMUNITY GAINS': '커뮤니티 이익',
'TOTAL WINS': '총 승리',
'BALANCE AVAILABLE': '사용 가능한 잔액',
'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': '아래의 지갑 중 하나에 연결하여 시작하십시오. 개인 키 또는 시드 문구를 안전하게 보관하십시오. 아무에게도 공유하지 마십시오.',
'connect wallet': '지갑 연결',
'Learn how to connect': '연결하는 방법 배우기',
"I still don't have a wallet": "아직 지갑이 없습니다",
'Financial password': '금융 비밀번호',
Password: '비밀번호',
'FINANCIAL DATA': '재무 데이터',
Send: '보내기',
'REGISTRATION DATA': '등록 데이터',
SECURITY: '보안',
'Start by connecting to one of the wallets below.': '아래의 지갑 중 하나에 연결하여 시작하십시오.',
TIKTOK: '틱톡',
PHONE: '전화',
'validate Email': '이메일 유효성 검사',
FACEBOOK: '페이스북',
DISCORD: '디스코드',
INSTAGRAM: '인스타그램',
'LAST NAME': '성',
LOGIN: '로그인',
'Enter the 2FA code': '2FA 코드 입력',
'Manually copy the code': '코드 수동 복사',
'Download and install the Google Authenticator app.': 'Google Authenticator 앱 다운로드 및 설치',
'Scan the QR CODE': 'QR 코드 스캔',
'Two-factor authentication (2FA)': '이중 인증 (2FA)',
'CONFIRM PASSWORD': '비밀번호 확인',
'NEW PASSWORD': '새 비밀번호',
'CURRENT PASSWORD': '현재 비밀번호',
"My Wallet": "내 지갑",
"FINANCIAL": "재무",
"My Profile": "내 프로필",
"Change Password": "비밀번호 변경",
"Log Out": "로그 아웃",
      }
    },
    vt:{
      translation:{
"Dashboard": "Bảng điều khiển",
"Donate manually": "Quyên góp bằng cách thủ công",
"Donate automatically": "Quyên góp tự động",
"STATEMENT": "BẢN TUYÊN BỐ",
"MAKE YOUR DONATION": "THỰC HIỆN QUYÊN GÓP CỦA BẠN",
"Choose your donation": "Chọn quyên góp của bạn",
"Donation": "Quyên góp",
"Donate": "Quyên góp",
"Your donation": "Quyên góp của bạn",
"Donation Return": "Hoàn trả quyên góp",
"Active Donations": "Quyên góp hoạt động",
"Progress": "Tiến trình",
"Donations Received": "Quyên góp nhận được",
"Donations Made": "Quyên góp đã thực hiện",
"Community": "Cộng đồng",
"Donations": "Quyên góp",
"Referral Link": "Liên kết giới thiệu",
"Showing data": "Hiển thị dữ liệu",
"entries": "mục",
"Network Commissions": "Hoa hồng mạng lưới",
"Date/Time": "Ngày/Giờ",
"User": "Người dùng",
"Access": "Truy cập",
"Hash Transaction": "Giao dịch Hash",
"Value": "Giá trị",
"Directs": "Trực tiếp",
"Indirect": "Gián tiếp",
"Assets": "Tài sản",
"Inactive": "Không hoạt động",
"Total": "Tổng cộng",
"My Community": "Cộng đồng của tôi",
"Name": "Tên",
"Country": "Quốc gia",
"WhatsApp": "WhatsApp",
"Telegram": "Telegram",
"Discord": "Discord",
"Help": "Trợ giúp",
"Reviews": "Đánh giá",
"All": "Tất cả",
"Positive": "Tích cực",
"Negative": "Tiêu cực",
"Safe and reliable, Polite and friendly, Quick transaction, Patient": "An toàn và đáng tin cậy, Lịch thiệp và thân thiện, Giao dịch nhanh chóng, Kiên nhẫn",
"COIN": "ĐỒNG",
"PRICE": "GIÁ",
"Payment": "Thanh toán",
"INFO": "THÔNG TIN",
"Information": "Thông tin",
"Available / Limit": "Có sẵn / Giới hạn",
"Online Sale Advertisement": "Quảng cáo bán hàng trực tuyến",
"Online Purchase Advertisement": "Quảng cáo mua hàng trực tuyến",
"ONLINE": "TRỰC TUYẾN",
"ENTERED IN": "NHẬP VÀO",
"Minutes": "Phút",
"Average Release Time": "Thời gian phát hành trung bình",
"Completion Fee": "Phí hoàn thành",
"Trades": "Giao dịch",
"Sell": "Bán",
"Buy": "Mua",
"All negotiations": "Tất cả các cuộc đàm phán",
"negatives": "Tiêu cực",
"Positives": "Tích cực",
"Positive Ratings": "Đánh giá tích cực",
"back": "trở lại",
"Email": "Email",
"Home": "Trang chủ",
"About": "Về",
"Blog": "Blog",
"STATUS": "TRẠNG THÁI",
"MANUAL DONATION": "QUYÊN GÓP THỦ CÔNG",
"AMOUNT ENUN": "SỐ TIỀN ENUN",
"AMOUNT USDT": "SỐ TIỀN USDT",
"LOGIN": "ĐĂNG NHẬP",
"WALLET ENUN": "VÍ ENUN",
"After completing your donation, please provide the transaction address below.": "Sau khi hoàn tất quyên góp của bạn, vui lòng cung cấp địa chỉ giao dịch dưới đây.",
"txn hash": "hash giao dịch",
"Finish": "Hoàn thành",
"Donations": "Quyên góp",
"Referral Link": "Liên kết giới thiệu",
"Average Payment Time": "Thời gian thanh toán trung bình",
"Social Media": "Mạng xã hội",
"Online Advertisement": "Quảng cáo trực tuyến",
"Comments": "Bình luận",
"Fiat Currency Name": "Tên tiền tệ Fiat",
"Fiat Currency Symbol": "Biểu tượng tiền tệ Fiat",
"Register": "Đăng ký",
"Country Name": "Tên quốc gia",
"REGISTER": "ĐĂNG KÝ",
"Available/Limit": "Có sẵn/Giới hạn",
"publish new ad": "đăng quảng cáo mới",
"LOCAL CRYPTO": "CRYPTO ĐỊA PHƯƠNG",
"LOCAL CRYPTO P2P": "CRYPTO ĐỊA PHƯƠNG P2P",
"I have met the requirements": "Tôi đã đáp ứng yêu cầu",
"Social network 2": "Mạng xã hội 2",
"orders": "đơn đặt hàng",
"completed": "hoàn thành",
"Trading requirements": "Yêu cầu giao dịch",
"Minimum Donation": "Quyên góp tối thiểu",
"Social network 1": "Mạng xã hội 1",
"enabled": "được kích hoạt",
"desired amount": "số lượng mong muốn",
"ENUM": "ENUM",
"BUY": "MUA",
"SELL": "BÁN",
"Send": "Gửi",
"Financial password": "Mật khẩu tài chính",
"Password": "Mật khẩu",
"My Wallet": "Ví của tôi",
"Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Bắt đầu bằng cách kết nối với một trong những ví dưới đây. Hãy chắc chắn lưu trữ an toàn các khóa riêng tư hoặc cụm từ hạt giống của bạn. Không bao giờ chia sẻ chúng với bất kỳ ai.",
"Learn how to connect": "Tìm hiểu cách kết nối",
"I still don't have a wallet": "Tôi vẫn chưa có ví",
"connect wallet": "kết nối ví",
"FINANCIAL DATA": "DỮ LIỆU TÀI CHÍNH",
"Observations": "Quan sát",
"Define the Total Value and Payment Method": "Xác định Giá trị Tổng cộng và Phương thức Thanh toán",
"Define type and price": "Xác định loại và giá",
"Publish Regular Advertisement": "Xuất bản Quảng cáo Thường xuyên",
"previous": "trước",
"Next": "Tiếp theo",
"Select the payment method": "Chọn phương thức thanh toán",
"PicPay": "PicPay",
"Transfer with specific bank": "Chuyển khoản với ngân hàng cụ thể",
"Bank Transfer": "Chuyển khoản ngân hàng",
"Recommended": "Được đề xuất",
"Trubit": "Trubit",
"Itaú Brazil": "Itaú Brazil",
"Confirm": "Xác nhận",
"Payment institution": "Cơ sở thanh toán",
"Token NameToken contract": "Tên Token Hợp đồng Token",
"REGISTER": "ĐĂNG KÝ",
"COMMUNITY": "CỘNG ĐỒNG",
"HELP": "TRỢ GIÚP",
"DISCORD": "DISCORD",
"TELEGRAM": "TELEGRAM",
"COUNTRY": "QUỐC GIA",
"WHATSAPP": "WHATSAPP",
"NAME": "TÊN",
"USER": "NGƯỜI DÙNG",
"Showing data": "Hiển thị dữ liệu",
"entries": "mục",
"MY COMMUNITY": "CỘNG ĐỒNG CỦA TÔI",
"Telegram": "Telegram",
"Local Crypto": "Tiền điện tử địa phương",
"Financial data": "Dữ liệu tài chính",
"My community": "Cộng đồng của tôi",
"Statement": "Tuyên bố",
"Dashboard": "Bảng điều khiển",
"Donation": "Quyên góp",
"Confirm donation": "Xác nhận quyên góp",
"Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.": "Chú ý: Bằng cách xác nhận bên dưới, bạn hiểu rằng bạn đang thực hiện quyên góp thông qua hệ thống Enjoy Union, và bạn đồng ý với các điều khoản và điều kiện.",
"To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.": "Để thực hiện quyên góp tự động, bạn cần phải kích hoạt Ví Kết nối của mình. Điều này có nghĩa là, chỉ cần một cú nhấp chuột, quyên góp của bạn sẽ được thực hiện tự động và bạn có thể kiểm tra nó trong bảng tuyên bố.",
"Chosen donation in the amount of": "Quyên góp được chọn trong số tiền là",
"Fiat Currency Name": "Tên Tiền tệ Fiat",
"Donate automatically": "Quyên góp tự động",
"Choose your donation": "Chọn quyên góp của bạn",
"DONATE": "QUYÊN GÓP",
"COMPLETED": "ĐÃ HOÀN THÀNH",
"MAKE DONATION": "THỰC HIỆN QUYÊN GÓP",
"LOGIN": "ĐĂNG NHẬP",
"AMOUNT USDT": "SỐ TIỀN USDT",
"AMOUNT ENUN": "SỐ TIỀN ENUN",
"MANUAL DONATION": "QUYÊN GÓP THỦ CÔNG",
"STATUS": "TRẠNG THÁI",
"MAKE YOUR DONATION": "THỰC HIỆN QUYÊN GÓP CỦA BẠN",
"Directs": "Trực tiếp",
"Indirect": "Gián tiếp",
"Assets": "Tài sản",
"Inactive": "Không hoạt động",
"Total": "Tổng cộng",
"Community": "Cộng đồng",
"HELP": "Trợ giúp",
"DISCORD": "DISCORD",
'Forgot your password?': 'Quên mật khẩu?',
'Enter': 'Nhập',
'CREATE ACCOUNT': 'TẠO TÀI KHOẢN',
'NEW LOGIN': 'ĐĂNG NHẬP MỚI',
'WELCOME': 'CHÀO MỪNG',
'LOG INTO YOUR ACCOUNT': 'ĐĂNG NHẬP VÀO TÀI KHOẢN CỦA BẠN',
'SEND': 'GỬI',
'Please provide your registered email to send the password reset instructions.': 'Vui lòng cung cấp email đã đăng ký của bạn để gửi hướng dẫn đặt lại mật khẩu.',
'Reset your password': 'Đặt lại mật khẩu của bạn',
'Registration': 'Đăng ký',
'QUALIFICATION': 'ĐÁNH GIÁ',
'DONATIONS RECEIVED': 'QUYÊN GÓP NHẬN ĐƯỢC',
'DONATIONS MADE': 'QUYÊN GÓP ĐÃ THỰC HIỆN',
'COMMUNITY GAINS': 'LỢI ÍCH CỘNG ĐỒNG',
'TOTAL WINS': 'TỔNG SỐ LƯỢNG THẮNG',
'BALANCE AVAILABLE': 'SỐ DƯ CÓ SẴN',
'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'Bắt đầu bằng cách kết nối với một trong những ví dưới đây. Đảm bảo lưu trữ an toàn các khóa riêng tư hoặc cụm từ hạt giống của bạn. Không bao giờ chia sẻ chúng với bất kỳ ai.',
'connect wallet': 'Kết nối ví',
'Learn how to connect': 'Tìm hiểu cách kết nối',
"I still don't have a wallet": "Tôi vẫn chưa có ví",
'Financial password': 'Mật khẩu tài chính',
'Password': 'Mật khẩu',
'FINANCIAL DATA': 'DỮ LIỆU TÀI CHÍNH',
'Send': 'Gửi',
'REGISTRATION DATA': 'DỮ LIỆU ĐĂNG KÝ',
'SECURITY': 'BẢO MẬT',
'Start by connecting to one of the wallets below.': 'Bắt đầu bằng cách kết nối với một trong những ví dưới đây.',
'TIKTOK': 'TIKTOK',
'PHONE': 'ĐIỆN THOẠI',
'validate Email': 'xác thực Email',
'FACEBOOK': 'FACEBOOK',
'DISCORD': 'DISCORD',
'INSTAGRAM': 'INSTAGRAM',
'LAST NAME': 'HỌ',
'LOGIN': 'ĐĂNG NHẬP',
'Enter the 2FA code': 'Nhập mã 2FA',
'Manually copy the code': 'Sao chép mã thủ công',
'Download and install the Google Authenticator app.': 'Tải xuống và cài đặt ứng dụng Google Authenticator.',
'Scan the QR CODE': 'Quét mã QR',
'Two-factor authentication (2FA)': 'Xác thực hai yếu tố (2FA)',
'CONFIRM PASSWORD': 'XÁC NHẬN MẬT KHẨU',
'NEW PASSWORD': 'MẬT KHẨU MỚI',
'CURRENT PASSWORD': 'MẬT KHẨU HIỆN TẠI',
"My Wallet": "Ví của tôi",
"FINANCIAL": "TÀI CHÍNH",
"My Profile": "Hồ sơ của tôi",
"Change Password": "Thay đổi mật khẩu",
"Log Out": "Đăng xuất"

      }
    },
    tr:{
      translation:{
        "Dashboard": "Kontrol Paneli",
        "Donate manually": "Manuel Bağış Yap",
        "Donate automatically": "Otomatik Bağış Yap",
        "STATEMENT": "DURUM",
        "MAKE YOUR DONATION": "BAĞIŞ YAP",
        "Choose your donation": "Bağışınızı seçin",
        "Donation": "Bağış",
        "Donate": "Bağış Yap",
        "Your donation": "Bağışınız",
        "Donation Return": "Bağış İadesi",
        "Active Donations": "Aktif Bağışlar",
        "Progress": "İlerleme",
        "Donations Received": "Alınan Bağışlar",
        "Donations Made": "Yapılan Bağışlar",
        "Community": "Topluluk",
        "Donations": "Bağışlar",
        "Referral Link": "Referans Bağlantısı",
        "Showing data": "Verileri gösterme",
        "entries": "girişler",
        "Network Commissions": "Ağ Komisyonları",
        "Date/Time": "Tarih/Saat",
        "User": "Kullanıcı",
        "Access": "Erişim",
        "Hash Transaction": "Hash İşlemi",
        "Value": "Değer",
        "Directs": "Doğrudanlar",
        "Indirect": "Dolaylı",
        "Assets": "Varlıklar",
        "Inactive": "Etkin değil",
        "Total": "Toplam",
        "My Community": "Topluluğum",
        "Name": "İsim",
        "Country": "Ülke",
        "WhatsApp": "WhatsApp",
        "Telegram": "Telegram",
        "Discord": "Discord",
        "Help": "Yardım",
        "Reviews": "İncelemeler",
        "All": "Hepsi",
        "Positive": "Pozitif",
        "Negative": "Negatif",
        "Safe and reliable, Polite and friendly, Quick transaction, Patient": "Güvenli ve güvenilir, Kibar ve dostça, Hızlı işlem, Sabırlı",
        "COIN": "JETON",
        "PRICE": "FİYAT",
        "Payment": "Ödeme",
        "INFO": "BİLGİ",
        "Information": "Bilgi",
        "Available / Limit": "Kullanılabilir / Limit",
        "Online Sale Advertisement": "Çevrimiçi Satış İlanı",
        "Online Purchase Advertisement": "Çevrimiçi Satın Alma İlanı",
        "ONLINE": "Çevrimiçi",
        "ENTERED IN": "GİRİŞ YAPILDI",
        "Minutes": "Dakika",
        "Average Release Time": "Ortalama Serbest Bırakma Süresi",
        "Completion Fee": "Tamamlama Ücreti",
        "Trades": "İşlemler",
        "Sell": "Sat",
        "Buy": "Satın Al",
        "All negotiations": "Tüm müzakereler",
        "negatives": "negatifler",
        "Positives": "Pozitifler",
        "Positive Ratings": "Pozitif Derecelendirmeler",
        "back": "geri",
        "Telegram": "Telegram",
        "Whatsapp": "Whatsapp",
        "Email": "E-posta",
        "Home": "Ana Sayfa",
        "About": "Hakkında",
        "Blog": "Blog",
        "STATUS": "DURUM",
        "MANUAL DONATION": "MANUEL BAĞIŞ",
        "AMOUNT ENUN": "TUTAR ENUN",
        "AMOUNT USDT": "TUTAR USDT",
        "LOGIN": "GİRİŞ",
        "WALLET ENUN": "CÜZDAN ENUN",
        "After completing your donation, please provide the transaction address below.": "Bağışınızı tamamladıktan sonra lütfen aşağıdaki işlem adresini sağlayın.",
        "txn hash": "txn hash",
        "Finish": "Bitir",
        "Referral Link": "Referans Bağlantısı",
        "Average Payment Time": "Ortalama Ödeme Süresi",
        "Social Media": "Sosyal Medya",
        "Online Advertisement": "Çevrimiçi Reklam",
        "Comments": "Yorumlar",
        "Fiat Currency Name": "Fiat Para Birimi Adı",
        "Fiat Currency Symbol": "Fiat Para Birimi Sembolü",
        "Register": "Kayıt Ol",
        "Country Name": "Ülke Adı",
        "REGISTER": "KAYIT OL",
        "Available/Limit": "Kullanılabilir/Limit",
        "publish new ad": "yeni ilan yayınla",
        "LOCAL CRYPTO": "YEREL KRIPTO",
        "LOCAL CRYPTO P2P": "YEREL KRIPTO P2P",
        "I have met the requirements": "Gereksinimleri karşıladım",
        "Social network 2": "Sosyal ağ 2",
        "orders": "siparişler",
        "completed": "tamamlandı",
        "Trading requirements": "Ticaret gereksinimleri",
        "Minimum Donation": "Minimum Bağış",
        "Social network 1": "Sosyal ağ 1",
        "enabled": "etkinleştirildi",
        "desired amount": "istenen miktar",
        "ENUM": "ENUM",
        "BUY": "SATIN AL",
        "SELL": "SAT",
        "Send": "Gönder",
        "Financial password": "Finansal şifre",
        "Password": "Şifre",
        "My Wallet": "Cüzdanım",
        "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Aşağıdaki cüzdanlardan birine bağlanarak başlayın. Özel anahtarlarınızı veya tohum kelimelerinizi güvenli bir şekilde sakladığınızdan emin olun. Asla kimseyle paylaşmayın.",
        "Learn how to connect": "Nasıl bağlanılacağını öğrenin",
        "I still don't have a wallet": "Hala bir cüzdanım yok",
        "connect wallet": "cüzdan bağla",
        "FINANCIAL DATA": "FİNANSAL VERİ",
        "Observations": "Gözlemler",
        "Define the Total Value and Payment Method": "Toplam Değeri ve Ödeme Yöntemini Belirleyin",
        "Define type and price": "Tür ve fiyatı tanımla",
        "Publish Regular Advertisement": "Düzenli Reklam Yayımla",
        "previous": "önceki",
        "Next": "Sonraki",
        "Select the payment method": "Ödeme yöntemini seçin",
        "PicPay": "PicPay",
        "Transfer with specific bank": "Belirli bir bankayla transfer",
        "Bank Transfer": "Banka Transferi",
        "Recommended": "Tavsiye edilen",
        "Trubit": "Trubit",
        "Itaú Brazil": "Itaú Brezilya",
        "Confirm": "Onayla",
        "Payment institution": "Ödeme kurumu",
        "Token NameToken contract": "Jeton AdıJeton sözleşmesi",
        "COMMUNITY": "TOPLULUK",
        "HELP": "YARDIM",
        "DISCORD": "Discord",
        "TELEGRAM": "Telegram",
        "COUNTRY": "ÜLKE",
        "WHATSAPP": "WhatsApp",
        "NAME": "İSİM",
        "SHOWING DATA": "VERİLERİ GÖSTERME",
        "entries": "girişler",
        "MY COMMUNITY": "TOPLULUĞUM",
        "Local Crypto": "Local Crypto",
        "Financial data": "Finansal veri",
        "My community": "Topluluğum",
        "Confirm donation": "Bağışı onayla",
        "Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.": "Dikkat: Aşağıyı onaylayarak, bağışı Enjoy Union sistemi üzerinden yaptığınızı ve koşulları kabul ettiğinizi farkındasınız.",
        "To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.": "Otomatik bağış yapmak için Connect Wallet'ınızın etkinleştirilmiş olması gerekmektedir. Böylece sadece bir tıklamayla bağışınız otomatik olarak yapılır ve durum panelinde kontrol edebilirsiniz.",
        "Chosen donation in the amount of": "Seçilen bağış tutarı",
        "Fiat Currency Name": "Fiat Para Birimi Adı",
        "Donate automatically": "Otomatik bağış yap",
        "Choose your donation": "Bağışınızı seçin",
        "DONATE": "BAĞIŞ YAP",
        "COMPLETED": "TAMAMLANDI",
        "MAKE DONATION": "BAĞIŞ YAP",
        "LOGIN": "GİRİŞ",
        "AMOUNT USDT": "TUTAR USDT",
        "AMOUNT ENUN": "TUTAR ENUN",
        "MANUAL DONATION": "MANUEL BAĞIŞ",
        "STATUS": "DURUM",
        "MAKE YOUR DONATION": "BAĞIŞ YAP",
        "Directs": "Doğrudanlar",
        "Indirect": "Dolaylı",
        "Assets": "Varlıklar",
        "Inactive": "Etkin değil",
        "Total": "Toplam",
        "Community": "Topluluk",
        "Help": "Yardım",
        "Discord": "Discord",
        "Forgot your password?": "Şifrenizi mi unuttunuz?",
        "Enter": "Gir",
        "CREATE ACCOUNT": "HESAP OLUŞTUR",
        "NEW LOGIN": "YENİ GİRİŞ",
        "WELCOME": "HOŞGELDİNİZ",
        "LOG INTO YOUR ACCOUNT": "HESABINIZA GİRİN",
        "Send": "Gönder",
        "Please provide your registered email to send the password reset instructions.": "Şifre sıfırlama talimatlarını göndermek için lütfen kayıtlı e-postanızı sağlayın.",
        "Reset your password": "Şifrenizi sıfırlayın",
        "Registration": "Kayıt",
        "QUALIFICATION": "NİTELİK",
        "DONATIONS RECEIVED": "ALINAN BAĞIŞLAR",
        "DONATIONS MADE": "YAPILAN BAĞIŞLAR",
        "COMMUNITY GAINS": "TOPLULUK KAZANÇLARI",
        "TOTAL WINS": "TOPLAM KAZANÇLAR",
        "BALANCE AVAILABLE": "KALAN BAKİYE",
        "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Aşağıdaki cüzdanlardan birine bağlanarak başlayın. Özel anahtarlarınızı veya tohum kelimelerinizi güvenli bir şekilde sakladığınızdan emin olun. Asla kimseyle paylaşmayın.",
        "connect wallet": "cüzdan bağla",
        "Learn how to connect": "Nasıl bağlanılacağını öğrenin",
        "I still don't have a wallet": "Hala bir cüzdanım yok",
        "Financial password": "Finansal şifre",
        "Password": "Şifre",
        "FINANCIAL DATA": "FİNANSAL VERİ",
        "Send": "Gönder",
        "REGISTRATION DATA": "KAYIT VERİLERİ",
        "SECURITY": "GÜVENLİK",
        "Start by connecting to one of the wallets below.": "Aşağıdaki cüzdanlardan birine bağlanarak başlayın.",
        "TIKTOK": "TIKTOK",
        "PHONE": "TELEFON",
        "validate Email": "e-postayı doğrula",
        "FACEBOOK": "FACEBOOK",
        "DISCORD": "Discord",
        "INSTAGRAM": "INSTAGRAM",
        "LAST NAME": "SOYAD",
        "LOGIN": "GİRİŞ",
        "Enter the 2FA code": "2FA kodunu girin",
        "Manually copy the code": "Kodu manuel olarak kopyala",
        "Download and install the Google Authenticator app.": "Google Authenticator uygulamasını indirin ve kurun.",
        "Scan the QR CODE": "QR KODU tarayın",
        "Two-factor authentication (2FA)": "İki faktörlü kimlik doğrulama (2FA)",
        "CONFIRM PASSWORD": "ŞİFREYİ ONAYLA",
        "NEW PASSWORD": "YENİ ŞİFRE",
        "CURRENT PASSWORD": "MEVCUT ŞİFRE",
        "My Wallet": "Cüzdanım",
        "FINANCIAL": "FİNANSAL",
        "My Profile": "Profilim",
        "Change Password": "Şifre Değiştir",
        "Log Out": "Çıkış Yap"
      }
    },
    jp :{
      translation:{
        "Dashboard": "ダッシュボード",
        "Donate manually": "手動で寄付する",
        "Donate automatically": "自動的に寄付する",
        "STATEMENT": "声明",
        "MAKE YOUR DONATION": "寄付をする",
        "Choose your donation": "寄付を選択してください",
        "Donation": "寄付",
        "Donate": "寄付する",
        "Your donation": "あなたの寄付",
        "Donation Return": "寄付の返金",
        "Active Donations": "アクティブな寄付",
        "Progress": "進捗",
        "Donations Received": "受け取った寄付",
        "Donations Made": "行った寄付",
        "Community": "コミュニティ",
        "Donations": "寄付",
        "Referral Link": "紹介リンク",
        "Showing data": "データ表示",
        "entries": "エントリー",
        "Network Commissions": "ネットワーク手数料",
        "Date/Time": "日付/時刻",
        "User": "ユーザー",
        "Access": "アクセス",
        "Hash Transaction": "ハッシュトランザクション",
        "Value": "価値",
        "Directs": "直接",
        "Indirect": "間接",
        "Assets": "資産",
        "Inactive": "非アクティブ",
        "Total": "合計",
        "My Community": "私のコミュニティ",
        "Name": "名前",
        "Country": "国",
        "WhatsApp": "WhatsApp",
        "Telegram": "Telegram",
        "Discord": "Discord",
        "Help": "ヘルプ",
        "Reviews": "レビュー",
        "All": "全て",
        "Positive": "ポジティブ",
        "Negative": "ネガティブ",
        "Safe and reliable, Polite and friendly, Quick transaction, Patient": "安全で信頼性があり、礼儀正しくフレンドリー、迅速な取引、忍耐強い",
        "COIN": "コイン",
        "PRICE": "価格",
        "Payment": "支払い",
        "INFO": "情報",
        "Information": "情報",
        "Available / Limit": "利用可能/制限",
        "Online Sale Advertisement": "オンライン販売広告",
        "Online Purchase Advertisement": "オンライン購入広告",
        "ONLINE": "オンライン",
        "ENTERED IN": "入力されました",
        "Minutes": "分",
        "Average Release Time": "平均リリース時間",
        "Completion Fee": "完了手数料",
        "Trades": "取引",
        "Sell": "売る",
        "Buy": "買う",
        "All negotiations": "すべての交渉",
        "negatives": "ネガティブ",
        "Positives": "ポジティブ",
        "Positive Ratings": "ポジティブ評価",
        "back": "戻る",
        "Telegram": "Telegram",
        "Whatsapp": "Whatsapp",
        "Email": "Eメール",
        "Home": "ホーム",
        "About": "約",
        "Blog": "ブログ",
        "STATUS": "状態",
        "MANUAL DONATION": "手動寄付",
        "AMOUNT ENUN": "金額 ENUN",
        "AMOUNT USDT": "金額 USDT",
        "LOGIN": "ログイン",
        "WALLET ENUN": "ウォレット ENUN",
        "After completing your donation, please provide the transaction address below.": "寄付を完了したら、以下にトランザクションアドレスを提供してください。",
        "txn hash": "トランザクションハッシュ",
        "Finish": "終わり",
        "Referral Link": "紹介リンク",
        "Average Payment Time": "平均支払い時間",
        "Social Media": "ソーシャルメディア",
        "Online Advertisement": "オンライン広告",
        "Comments": "コメント",
        "Fiat Currency Name": "フィアット通貨名",
        "Fiat Currency Symbol": "フィアット通貨シンボル",
        "Register": "登録",
        "Country Name": "国名",
        "REGISTER": "登録",
        "Available/Limit": "利用可能/制限",
       
      
       "publish new ad": "新しい広告を掲載",
        "LOCAL CRYPTO": "ローカルクリプト",
        "LOCAL CRYPTO P2P": "ローカルクリプト P2P",
        "I have met the requirements": "要件を満たしました",
        "Social network 2": "ソーシャルネットワーク 2",
        "orders": "注文",
        "completed": "完了しました",
        "Trading requirements": "取引要件",
        "Minimum Donation": "最小寄付",
        "Social network 1": "ソーシャルネットワーク 1",
        "enabled": "有効",
        "desired amount": "希望金額",
        "ENUM": "ENUM",
        "BUY": "購入",
        "SELL": "売る",
        "Send": "送る",
        "Financial password": "金融パスワード",
        "Password": "パスワード",
        "My Wallet": "私のウォレット",
        "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "以下のウォレットのいずれかに接続して開始します。プライベートキーまたはシードフレーズを安全に保存し、決して他の人と共有しないでください。",
        "Learn how to connect": "接続方法を学ぶ",
        "I still don't have a wallet": "まだウォレットがありません",
        "connect wallet": "ウォレットに接続",
        "FINANCIAL DATA": "財務データ",
        "Observations": "観察",
        "Define the Total Value and Payment Method": "総額と支払い方法を定義します",
        "Define type and price": "タイプと価格を定義します",
        "Publish Regular Advertisement": "定期広告を公開",
        "previous": "前",
        "Next": "次",
        "Select the payment method": "支払い方法を選択してください",
        "PicPay": "PicPay",
        "Transfer with specific bank": "特定の銀行で送金",
        "Bank Transfer": "銀行振込",
        "Recommended": "おすすめ",
        "Trubit": "Trubit",
        "Itaú Brazil": "イタウブラジル",
        "Confirm": "確認",
        "Payment institution": "支払い機関",
        "Token NameToken contract": "トークン名トークン契約",
        "COMMUNITY": "コミュニティ",
        "HELP": "助けて",
        "DISCORD": "Discord",
        "TELEGRAM": "Telegram",
        "COUNTRY": "国",
        "WHATSAPP": "WhatsApp",
        "NAME": "名前",
        "SHOWING DATA": "データ表示",
        "entries": "エントリー",
        "MY COMMUNITY": "私のコミュニティ",
        "Local Crypto": "ローカルクリプト",
        "Financial data": "財務データ",
        "My community": "私のコミュニティ",
        "Confirm donation": "寄付を確認",
        "Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.": "注意：以下を確認することで、Enjoy Unionシステムを介して寄付を行っていることを認識し、利用規約に同意するものとします。",
        "To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.": "自動寄付を行うには、Connectウォレットをアクティブにする必要があります。 これにより、1回のクリックで寄付が自動的に行われ、ステートメントパネルで確認できます。",
        "Chosen donation in the amount of": "選択した寄付の金額",
        "Fiat Currency Name": "フィアット通貨名",
        "Donate automatically": "自動的に寄付する",
        "Choose your donation": "寄付を選択してください",
        "DONATE": "寄付する",
        "COMPLETED": "完了",
        "MAKE DONATION": "寄付をする",
        "LOGIN": "ログイン",
        "AMOUNT USDT": "金額 USDT",
        "AMOUNT ENUN": "金額 ENUN",
        "MANUAL DONATION": "手動寄付",
        "STATUS": "状態",
        "MAKE YOUR DONATION": "寄付をする",
        "Directs": "直接",
        "Indirect": "間接",
        "Assets": "資産",
        "Inactive": "非アクティブ",
        "Total": "合計",
        "Community": "コミュニティ",
        "Help": "ヘルプ",
        "Discord": "Discord",
        "Forgot your password?": "パスワードをお忘れですか？",
        "Enter": "入力する",
        "CREATE ACCOUNT": "アカウントを作成",
        "NEW LOGIN": "新しいログイン",
        "WELCOME": "ようこそ",
        "LOG INTO YOUR ACCOUNT": "アカウントにログイン",
        "Send": "送る",
        "Please provide your registered email to send the password reset instructions.": "パスワードリセットの手順を送信するために、登録されたメールアドレスを提供してください。",
        "Reset your password": "パスワードをリセットする",
        "Registration": "登録",
        "QUALIFICATION": "資格",
        "DONATIONS RECEIVED": "受け取った寄付",
        "DONATIONS MADE": "行った寄付",
        "COMMUNITY GAINS": "コミュニティの利益",
        "TOTAL WINS": "総勝利",
        "BALANCE AVAILABLE": "利用可能な残高",
        "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "以下のウォレットのいずれかに接続して開始します。プライベートキーまたはシードフレーズを安全に保存し、決して他の人と共有しないでください。",
        "connect wallet": "ウォレットに接続",
        "Learn how to connect": "接続方法を学ぶ",
        "I still don't have a wallet": "まだウォレットがありません",
        "Financial password": "金融パスワード",
        "Password": "パスワード",
        "FINANCIAL DATA": "財務データ",
        "Send": "送る",
        "REGISTRATION DATA": "登録データ",
        "SECURITY": "セキュリティ",
        "Start by connecting to one of the wallets below.": "以下のウォレットのいずれかに接続して開始します。",
        "TIKTOK": "TIKTOK",
        "PHONE": "電話",
        "validate Email": "Eメールを確認",
        "FACEBOOK": "FACEBOOK",
        "DISCORD": "Discord",
        "INSTAGRAM": "INSTAGRAM",
        "LAST NAME": "苗字",
        "LOGIN": "ログイン",
        "Enter the 2FA code": "2FAコードを入力",
        "Manually copy the code": "コードを手動でコピーする",
        "Download and install the Google Authenticator app.": "Google Authenticatorアプリをダウンロードしてインストールします。",
        "Scan the QR CODE": "QRコードをスキャン",
        "Two-factor authentication (2FA)": "二要素認証（2FA）",
        "CONFIRM PASSWORD": "パスワードを確認",
        "NEW PASSWORD": "新しいパスワード",
        "CURRENT PASSWORD": "現在のパスワード",
        "My Wallet": "私のウォレット",
        "FINANCIAL": "財務",
        "My Profile": "私のプロフィール",
        "Change Password": "パスワードを変更",
        "Log Out": "ログアウト"
      },
    },
    id: {
      translation: {
        Dashboard: "Dasbor",
        "Donate manually": "Sumbangkan secara manual",
        "Donate automatically": "Sumbangkan secara otomatis",
        STATEMENT: "Pernyataan",
        "MAKE YOUR DONATION": "Buat Sumbangan Anda",
        "Choose your donation": "Pilih sumbangan Anda",
        Donation: "Sumbangan",
        Donate: "Sumbangkan",
        "Your donation": "Sumbangan Anda",
        "Donation Return": "Pengembalian Sumbangan",
        "Active Donations": "Sumbangan Aktif",
        Progress: "Kemajuan",
        "Donations Received": "Sumbangan Diterima",
        "Donations Made": "Sumbangan Diberikan",
        Community: "Komunitas",
        Donations: "Sumbangan",
        "Referral Link": "Tautan referal",
        Statement: "Pernyataan",
        "Showing data": "Menampilkan data",
        entries: "entri",
        "Network Commissions": "Komisi Jaringan",
        "Date/Time": "Tanggal/Waktu",
        User: "Pengguna",
        Access: "Akses",
        "Hash Transaction": "Transaksi Hash",
        Valor: "Nilai",
        Directs: "Langsung",
        Indirect: "Tidak Langsung",
        Assets: "Aset",
        Inactive: "Nonaktif",
        Total: "Total",
        "My Community": "Komunitas Saya",
        Name: "Nama",
        Country: "Negara",
        WhatsApp: "WhatsApp",
        Telegram: "Telegram",
        Discord: "Discord",
        Help: "Bantuan",
        Reviews: "Ulasan",
        All: "Semua",
        Positive: "Positif",
        Negative: "Negatif",
        "Safe and reliable,Polite and friendly,Quick transaction, Patient": "Aman dan dapat diandalkan, Ramah dan bersahabat, Transaksi cepat, Sabar",
        COIN: "Mata Uang",
        PRICE: "Harga",
        Payment: "Pembayaran",
        INFO: "Informasi",
        Information: "Informasi",
        "Available / Limit": "Tersedia / Batas",
        "Online Sale Advertisement": "Iklan Penjualan Online",
        "Online Purchase Advertisement": "Iklan Pembelian Online",
        ONLINE: "Online",
        "ENTERED IN": "Dimasukkan ke",
        Minutes: "Menit",
        "Average Release Time": "Waktu Rilis Rata-rata",
        "Completion Fee": "Biaya Penyelesaian",
        Trades: "Perdagangan",
        Sell: "Jual",
        Buy: "Beli",
        "All negotiations": "Semua negosiasi",
        negatives: "Negatif",
        Positives: "Positif",
        "Positive Ratings": "Rating Positif",
        back: "Kembali",
        Email: "Email",
        Home: "Beranda",
        About: "Tentang",
        Blog: "Blog",
        STATUS: "Status",
        "MANUAL DONATION": "Sumbangan Manual",
        "AMOUNT ENUN": "Jumlah ENUN",
        "AMOUNT USDT": "Jumlah USDT",
        LOGIN: "Masuk",
        "WALLET ENUN": "Dompet ENUN",
        "After completing your donation, please provide the transaction address below.": "Setelah menyelesaikan sumbangan Anda, harap berikan alamat transaksi di bawah ini.",
        "txn hash": "hash txn",
        Finish: "Selesai",
        "Referral Link": "Tautan Referal",
        "Average Payment Time": "Waktu Pembayaran Rata-rata",
        "Social Media": "Media Sosial",
        "Online Advertisement": "Iklan Online",
        Comments: "Komentar",
        "Fiat Currency Name": "Nama Mata Uang Fiat",
        "Fiat Currency Symbol": "Simbol Mata Uang Fiat",
        Register: "Daftar",
        "Country Name": "Nama Negara",
        REGISTER: "DAFTAR",
        "publish new ad": "menerbitkan iklan baru",
        "LOCAL CRYPTO": "Kripto Lokal",
        "LOCAL CRYPTO P2P": "Kripto Lokal P2P",
        "I have met the requirements": "Saya telah memenuhi persyaratan",
        "Social network 2": "Jaringan sosial 2",
        orders: "pesanan",
        completed: "selesai",
        "Trading requirements": "Persyaratan perdagangan",
        "Minimum Donation": "Sumbangan Minimum",
        "Social network 1": "Jaringan sosial 1",
        enabled: "diaktifkan",
        "desired amount": "jumlah yang diinginkan",
        ENUM: "ENUM",
        BUY: "Beli",
        SELL: "Jual",
        Send: "Kirim",
        "Financial password": "Kata sandi keuangan",
        "My Wallet": "Dompet Saya",
        "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Mulai dengan menghubungkan ke salah satu dompet di bawah ini. Pastikan untuk menyimpan kunci pribadi atau frasa biji Anda dengan aman. Jangan pernah membagikannya kepada siapa pun.",
        "Learn how to connect": "Pelajari cara menghubungkan",
        "I still don't have a wallet": "Saya masih belum memiliki dompet",
        "connect wallet": "hubungkan dompet",
        "FINANCIAL DATA": "DATA KEUANGAN",
        Observations: "Pengamatan",
        "Define the Total Value and Payment Method": "Tentukan Nilai Total dan Metode Pembayaran",
        "Define type and price": "Tentukan jenis dan harga",
        "Publish Regular Advertisement": "Terbitkan Iklan Reguler",
        previous: "sebelumnya",
        Next: "Selanjutnya",
        "Select the payment method": "Pilih metode pembayaran",
        PicPay: "PicPay",
        "Transfer with specific bank": "Transfer dengan bank tertentu",
        "Bank Transfer": "Transfer Bank",
        Recommended: "Direkomendasikan",
        Trubit: "Trubit",
        "Itaú Brazil": "Itaú Brasil",
        Confirm: "Konfirmasi",
        "Payment institution": "Lembaga pembayaran",
        "Token NameToken contract": "Nama Token Kontrak Token",
        "CONFIRM DONATION": "KONFIRMASI SUMBANGAN",
        "Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.": "Perhatian: Dengan mengonfirmasi di bawah ini, Anda menyadari bahwa Anda melakukan sumbangan melalui sistem Enjoy Union, dan Anda menyetujui syarat dan ketentuan.",
        "To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.": "Untuk membuat sumbangan otomatis, Anda perlu mengaktifkan Dompet Terhubung Anda. Dengan cara ini, dengan hanya satu klik, sumbangan Anda akan dibuat secara otomatis, dan Anda dapat memeriksanya di panel pernyataan.",
        "Chosen donation in the amount of": "Sumbangan yang dipilih sebesar",
        "Fiat Currency Name": "Nama Mata Uang Fiat",
        "Donate automatically": "Sumbangkan secara otomatis",
        DONATE: "SUMBANGKAN",
        COMPLETED: "SELESAI",
        "MAKE DONATION": "BUAT SUMBANGAN",
        MANUAL: "MANUAL",
        LOGIN: "MASUK",
        "AMOUNT USDT": "JUMLAH USDT",
        "AMOUNT ENUN": "JUMLAH ENUN",
        STATUS: "STATUS",
        Directs: "Langsung",
        INDIRECT: "Tidak Langsung",
        ASSETS: "Aset",
        INACTIVE: "Tidak aktif",
        TOTAL: "Total",
        HELP: "Bantuan",
        DISCORD: "Discord",
        "Forgot your password?": "Lupa kata sandi Anda?",
        Enter: "Masuk",
        "CREATE ACCOUNT": "BUAT AKUN",
        "NEW LOGIN": "LOGIN BARU",
        WELCOME: "Selamat datang",
        "LOG INTO YOUR ACCOUNT": "MASUK KE AKUN ANDA",
        SEND: "Kirim",
        "Please provide your registered email to send the password reset instructions.": "Harap berikan email terdaftar Anda untuk mengirimkan petunjuk pengaturan ulang kata sandi.",
        "Reset your password": "Atur ulang kata sandi Anda",
        Registration: "Registrasi",
        QUALIFICATION: "Kualifikasi",
        "DONATIONS RECEIVED": "SUMBANGAN DITERIMA",
        "DONATIONS MADE": "SUMBANGAN DIBERIKAN",
        "COMMUNITY GAINS": "KEUNTUNGAN KOMUNITAS",
        "TOTAL WINS": "TOTAL KEUNTUNGAN",
        "BALANCE AVAILABLE": "SALDO TERSEDIA",
        "Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Mulai dengan menghubungkan ke salah satu dompet di bawah ini. Pastikan untuk menyimpan kunci pribadi atau frasa biji Anda dengan aman. Jangan pernah membagikannya kepada siapa pun.",
        "connect wallet": "hubungkan dompet",
        "Learn how to connect": "Pelajari cara menghubungkan",
        "I still don't have a wallet": "Saya masih belum memiliki dompet",
        "Financial password": "Kata sandi keuangan",
        Password: "Kata Sandi",
        "FINANCIAL DATA": "DATA KEUANGAN",
        Send: "Kirim",
        "REGISTRATION DATA": "DATA REGISTRASI",
        SECURITY: "KEAMANAN",
        "Start by connecting to one of the wallets below.": "Mulai dengan menghubungkan ke salah satu dompet di bawah ini.",
        TIKTOK: "TIKTOK",
        PHONE: "Telepon",
        "validate Email": "validasi Email",
        FACEBOOK: "Facebook",
        DISCORD: "Discord",
        INSTAGRAM: "Instagram",
        "LAST NAME": "Nama Belakang",
        LOGIN: "Masuk",
        "Enter the 2FA code": "Masukkan kode 2FA",
        "Manually copy the code": "Salin kode secara manual",
        "Download and install the Google Authenticator app.": "Unduh dan pasang aplikasi Google Authenticator.",
        "Scan the QR CODE": "Pindai KODE QR",
        "Two-factor authentication (2FA)": "Autentikasi dua faktor (2FA)",
        "CONFIRM PASSWORD": "KONFIRMASI KATA SANDI",
        "NEW PASSWORD": "KATA SANDI BARU",
        "CURRENT PASSWORD": "KATA SANDI SAAT INI",
        "My Wallet": "Dompet Saya",
        FINANCIAL: "KEUANGAN",
        "My Profile": "Profil Saya",
        "Change Password": "Ganti Kata Sandi",
        "Log Out": "Keluar"
      }
    },
    ar: {
      translation: {
        Dashboard: "لوحة القيادة",
        "Donate manually": "التبرع يدوياً",
        "Donate automatically": "التبرع تلقائياً",
        STATEMENT: "بيان",
        "MAKE YOUR DONATION": "قم بتبرعك",
        "Choose your donation": "اختر تبرعك",
        Donation: "تبرع",
        Donate: "تبرع",
        "Your donation": "تبرعك",
        "Donation Return": "استرجاع التبرع",
        "Active Donations": "التبرعات النشطة",
        Progress: "تقدم",
        "Donations Received": "التبرعات المستلمة",
        "Donations Made": "التبرعات المقدمة",
        Community: "المجتمع",
        Donations: "التبرعات",
        "Referral Link": "رابط الإحالة",
        Statement: "بيان",
        "Showing data": "عرض البيانات",
        entries: "إدخالات",
        "Network Commissions": "العمولات الشبكية",
        "Date/Time": "التاريخ/الوقت",
        User: "المستخدم",
        Access: "الوصول",
        "Hash Transaction": "عملية التجزئة",
        Valor: "قيمة",
        Directs: "مباشرة",
        Indirect: "غير مباشر",
        Assets: "الأصول",
        Inactive: "غير نشط",
        Total: "المجموع",
        "My Community": "مجتمعي",
        Name: "الاسم",
        Country: "البلد",
        WhatsApp: "واتساب",
        Telegram: "تيليجرام",
        Discord: "ديسكورد",
        Help: "مساعدة",
        Reviews: "المراجعات",
        All: "الكل",
        Positive: "إيجابي",
        Negative: "سلبي",
        "Safe and reliable,Polite and friendly,Quick transaction, Patient": "آمن وموثوق به، مهذب وودود، معاملة سريعة، مريض",
        COIN: "عملة",
        PRICE: "السعر",
        Payment: "الدفع",
        INFO: "معلومات",
        Information: "معلومات",
        "Available / Limit": "متاح / حد",
        "Online Sale Advertisement": "إعلان بيع عبر الإنترنت",
        "Online Purchase Advertisement": "إعلان الشراء عبر الإنترنت",
        ONLINE: "عبر الإنترنت",
        "ENTERED IN": "دخل في",
        Minutes: "دقائق",
        "Average Release Time": "وقت الإصدار المتوسط",
        "Completion Fee": "رسوم الإكمال",
        Trades: "الصفقات",
        Sell: "بيع",
        Buy: "شراء",
        "All negotiations": "جميع المفاوضات",
        negatives: "سلبيات",
        Positives: "إيجابيات",
        "Positive Ratings": "التقييمات الإيجابية",
        back: "عودة",
        Email: "البريد الإلكتروني",
        Home: "الرئيسية",
        About: "حول",
        Blog: "المدونة",
        STATUS: "الحالة",
        "MANUAL DONATION": "تبرع يدوي",
        "AMOUNT ENUN": "المبلغ ENUN",
        "AMOUNT USDT": "المبلغ USDT",
        LOGIN: "تسجيل الدخول",
        "WALLET ENUN": "محفظة ENUN",
        "After completing your donation, please provide the transaction address below.": "بعد إتمام التبرع الخاص بك، يرجى تقديم عنوان العملية التالي أدناه.",
        "txn hash": "هاش العملية",
        Finish: "إنهاء",
        "Referral Link": "رابط الإحالة",
        "Average Payment Time": "متوسط وقت الدفع",
        "Social Media": "وسائل التواصل الاجتماعي",
        "Online Advertisement": "الإعلان عبر الإنترنت",
        Comments: "التعليقات",
        "Fiat Currency Name": "اسم العملة القانونية",
        "Fiat Currency Symbol": "رمز العملة القانونية",
        Register: "تسجيل",
        "Country Name": "اسم البلد",
        REGISTER: "تسجيل",
        "publish new ad": "نشر إعلان جديد",
        "LOCAL CRYPTO": "عملة محلية",
        "LOCAL CRYPTO P2P": "عملة محلية P2P",
        "I have met the requirements": "لقد قمت بتلبية المتطلبات",
        "Social network 2": "الشبكة الاجتماعية 2",
        orders: "الطلبات",
        completed: "مكتمل",
        "Trading requirements": "متطلبات التداول",
        "Minimum Donation": "الحد الأدنى للتبرع",
        "Social network 1": "الشبكة الاجتماعية 1",
        enabled: "مُمكّن",
        "desired amount": "المبلغ المطلوب",
        ENUM: "ENUM",
        BUY: "شراء",
        SELL: "بيع",
        Send: "إرسال",
        "Financial password": "كلمة المرور المالية",
        Password: "كلمة السر",
        "My Wallet": "محفظتي",
        "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "ابدأ بالاتصال بأحد المحافظ المدرجة أدناه. تأكد من تخزين مفاتيحك الخاصة أو عبارات البذور بشكل آمن. لا تشاركها مع أي شخص.",
        "Learn how to connect": "تعلم كيفية الاتصال",
        "I still don't have a wallet": "لا أزال لا أملك محفظة",
        "connect wallet": "الاتصال بالمحفظة",
        "FINANCIAL DATA": "البيانات المالية",
        Observations: 'الملاحظات',
        'Define the Total Value and Payment Method': 'تعريف القيمة الإجمالية وطريقة الدفع',
        'Define type and price': 'تحديد النوع والسعر',
        'Publish Regular Advertisement': 'نشر إعلان عادي',
        previous: 'السابق',
        Next: 'التالي',
        'Select the payment method': 'حدد طريقة الدفع',
        PicPay: 'بيك باي',
        'Transfer with specific bank': 'تحويل مع بنك محدد',
        'Bank Transfer': 'تحويل بنكي',
        Recommended: 'مُوصى به',
        Trubit: 'تروبيت',
        'Itaú Brazil': 'إيتاو البرازيل',
        Confirm: 'تأكيد',
        'Payment institution': 'مؤسسة الدفع',
        'Token NameToken contract': 'اسم الرمز الرمزي عقد الرمز',
        REGISTER: 'تسجيل',
        COMMUNITY: 'المجتمع',
        HELP: 'مساعدة',
        DISCORD: 'ديسكورد',
        TELEGRAM: 'تليجرام',
        COUNTRY: 'البلد',
        WHATSAPP: 'واتساب',
        NAME: 'الاسم',
        USER: 'المستخدم',
        'Showing data': 'عرض البيانات',
        entries: 'إدخالات',
        'MY COMMUNITY': 'مجتمعي',
        Telegram: 'تليجرام',
        'Local Crypto': 'عملة محلية',
        'Financial data': 'البيانات المالية',
        'My community': 'مجتمعي',
        Statement: 'بيان',
        Dashboard: 'لوحة القيادة',
        Donation: 'تبرع',
        'Confirm donation': 'تأكيد التبرع',
        'Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.': 'تنبيه: بتأكيدك أدناه، أنت على علم بأنك تقوم بالتبرع من خلال نظام Enjoy Union، وتوافق على الشروط والأحكام.',
        'To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.': 'لتقديم تبرع تلقائي، يجب أن يكون لديك محفظة الاتصال الخاصة بك مفعلة. بهذه الطريقة، بنقرة واحدة فقط، سيتم إجراء تبرعك تلقائيًا، ويمكنك التحقق منه في لوحة البيانات.',
        'Chosen donation in the amount of': 'التبرع المختار بمقدار',
        'Fiat Currency Name': 'اسم العملة القانونية',
        'Donate automatically': 'تبرع تلقائيًا',
        'Choose your donation': 'اختر تبرعك',
        DONATE: 'تبرع',
        COMPLETED: 'اكتمل',
        'MAKE DONATION': 'قم بالتبرع',
        MANUAL: 'يدوي',
        LOGIN: 'تسجيل الدخول',
        'AMOUNT USDT': 'المبلغ USDT',
        'AMOUNT ENUN': 'المبلغ ENUN',
        "MANUAL DONATION": "تبرع يدوي",
        STATUS: "الحالة",
        "MAKE YOUR DONATION": "قم بتبرعك",
        Directs: 'مباشرة',
        INDIRECT: 'غير مباشر',
        ASSETS: 'الأصول',
        INACTIVE: 'غير نشط',
        TOTAL: 'المجموع',
        COMMUNITY: 'المجتمع',
        HELP: 'مساعدة',
        DISCORD: 'ديسكورد',
        'Forgot your password?': 'هل نسيت كلمة المرور؟',
        Enter: 'إدخال',
        'CREATE ACCOUNT': 'إنشاء حساب',
        'NEW LOGIN': 'تسجيل الدخول الجديد',
        WELCOME: 'مرحبا',
        'LOG INTO YOUR ACCOUNT': 'تسجيل الدخول إلى حسابك',
        SEND: 'إرسال',
        'Please provide your registered email to send the password reset instructions.': 'يرجى تقديم بريدك الإلكتروني المسجل لإرسال تعليمات إعادة تعيين كلمة المرور.',
        'Reset your password': 'إعادة تعيين كلمة المرور الخاصة بك',
        Registration: 'التسجيل',
        QUALIFICATION: 'المؤهلات',
        'DONATIONS RECEIVED': 'التبرعات المتلقاة',
        'DONATIONS MADE': 'التبرعات المقدمة',
        'COMMUNITY GAINS': 'مكاسب المجتمع',
        'TOTAL WINS': 'مجموع الانتصارات',
        'BALANCE AVAILABLE': 'الرصيد المتاح',
        'Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.': 'ابدأ بالاتصال بأحد المحافظ المدرجة أدناه. تأكد من تخزين مفاتيحك الخاصة أو عبارات البذور بشكل آمن. لا تشاركها مع أي شخص.',
        'connect wallet': 'الاتصال بالمحفظة',
        'Learn how to connect': 'تعلم كيفية الاتصال',
        "I still don't have a wallet": "لا أزال لا أملك محفظة",
        'Financial password': 'كلمة المرور المالية',
        Password: 'كلمة السر',
        'FINANCIAL DATA': 'البيانات المالية',
        Send: 'إرسال',
        'REGISTRATION DATA': 'بيانات التسجيل',
        SECURITY: 'الأمان',
        'Start by connecting to one of the wallets below.': 'ابدأ بالاتصال بأحد المحافظ المدرجة أدناه.',
        TIKTOK: 'تيك توك',
        PHONE: 'هاتف',
        'validate Email': 'تحقق من البريد الإلكتروني',
        FACEBOOK: 'فيسبوك',
        DISCORD: 'ديسكورد',
        INSTAGRAM: 'إنستجرام',
        'LAST NAME': 'الاسم الأخير',
        LOGIN: 'تسجيل الدخول',
        'Enter the 2FA code': 'أدخل رمز 2FA',
        'Manually copy the code': 'نسخ الرمز يدوياً',
        'Download and install the Google Authenticator app.': 'قم بتنزيل وتثبيت تطبيق Google Authenticator.',
        'Scan the QR CODE': 'مسح رمز الاستجابة السريعة',
        'Two-factor authentication (2FA)': 'المصادقة الثنائية العاملة (2FA)',
        'CONFIRM PASSWORD': 'تأكيد كلمة المرور',
        'NEW PASSWORD': 'كلمة المرور الجديدة',
        'CURRENT PASSWORD': 'كلمة المرور الحالية',
        'RETYPE NEW PASSWORD': 'أعد كتابة كلمة المرور الجديدة',
        'Welcome back! Please log in to your account': 'مرحبا بك مجددا! الرجاء تسجيل الدخول إلى حسابك',
        'Enter your email to reset your password': 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور',
        'An email with instructions on how to reset your password has been sent to your email address': 'تم إرسال بريد إلكتروني بتعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك إلى عنوان بريدك الإلكتروني',
        'Register your account': 'سجل حسابك',
        'By signing up, you agree to the terms of service and privacy policy.': 'بالتسجيل، أنت توافق على شروط الخدمة وسياسة الخصوصية.',
        'Already have an account?': 'هل لديك حساب بالفعل؟',
        'Log in': 'تسجيل الدخول',
        'Get started': 'البدء',
        'Create your account': 'أنشئ حسابك',
        'Welcome back! Please log in to your account.': 'مرحبًا مرة أخرى! يرجى تسجيل الدخول إلى حسابك.',
        'Enter your email address to reset your password. You may need to check your spam folder or unblock no-reply@domain.com.': 'أدخل عنوان بريدك الإلكتروني لإعادة تعيين كلمة المرور. قد تحتاج إلى التحقق من مجلد البريد العشوائي أو فتح no-reply@domain.com.',
        'Select a password for your account': 'حدد كلمة مرور لحسابك',
        'Enter a password that is at least 6 characters long': 'أدخل كلمة مرور تتكون من 6 أحرف على الأقل',
        'Enter a valid email address': 'أدخل عنوان بريد إلكتروني صالح',
        'Enter your email address': 'أدخل عنوان بريدك الإلكتروني',
        'Enter your password': 'أدخل كلمة المرور الخاصة بك',
        'Forgot your password?': 'هل نسيت كلمة المرور؟',
        'Create your account': 'أنشئ حسابك',
        'Welcome back!': 'مرحبًا مرة أخرى!',
        'Please log in to your account': 'يرجى تسجيل الدخول إلى حسابك',
        'New to the platform?': 'جديد على المنصة؟',
        'Create an account': 'إنشاء حساب',
        'Forgot Password?': 'هل نسيت كلمة المرور؟',
        'Submit': 'إرسال',
        'Congratulations! You have successfully registered.': 'تهانينا! لقد سجلت بنجاح.',
        'Please check your email to confirm your account.': 'يرجى التحقق من بريدك الإلكتروني لتأكيد حسابك.',
        'Terms of service': 'شروط الخدمة',
        'Privacy policy': 'سياسة الخصوصية',
        'The password must be at least 6 characters long.': 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل.',
        'Passwords must match.': 'يجب أن تتطابق كلمات المرور.',
        'Please enter a valid email address.': 'يرجى إدخال عنوان بريد إلكتروني صالح.',
        'Please fill in all the required fields.': 'يرجى ملء جميع الحقول المطلوبة.',
        'Please provide your email and password to log in.': 'يرجى تقديم بريدك الإلكتروني وكلمة المرور لتسجيل الدخول.',
        'Please provide your email address to reset your password.': 'يرجى تقديم عنوان بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك.',
        'Please provide your email to reset your password.': 'يرجى تقديم بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك.',
        'Please provide a valid email address.': 'يرجى تقديم عنوان بريد إلكتروني صالح.',
        'Invalid email or password.': 'بريد إلكتروني أو كلمة مرور غير صالحة.',
        'An email has been sent with instructions on how to reset your password.': 'تم إرسال بريد إلكتروني بتعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك.',
        'Welcome back! Please enter your email and password to log in.': 'مرحبًا مرة أخرى! الرجاء إدخال بريدك الإلكتروني وكلمة المرور لتسجيل الدخول.',
        'Welcome!': 'مرحبا!',
        'Invalid email format.': 'تنسيق البريد الإلكتروني غير صالح.',
        'Invalid credentials.': 'بيانات الاعتماد غير صالحة.',
        'User not found.': 'المستخدم غير موجود.',
        'Password reset successful.': 'تم إعادة تعيين كلمة المرور بنجاح.',
        'Password confirmation does not match.': 'تأكيد كلمة المرور لا يتطابق.',
        'The email has already been taken.': 'تم اتخاذ البريد الإلكتروني بالفعل.',
        'Registration successful.': 'تم التسجيل بنجاح.',
        'Login successful.': 'تم تسجيل الدخول بنجاح.',
        'Password reset link is invalid or has expired.': 'رابط إعادة تعيين كلمة المرور غير صالح أو منتهي الصلاحية.',
        'Login to your account': 'تسجيل الدخول إلى حسابك',
        'Forgot your password?': 'هل نسيت كلمة المرور؟',
        'Create an account': 'إنشاء حساب',
        'Forgot Password?': 'هل نسيت كلمة المرور؟',
        'Submit': 'إرسال',
        'Congratulations! You have successfully registered.': 'تهانينا! لقد سجلت بنجاح.',
        'Please check your email to confirm your account.': 'يرجى التحقق من بريدك الإلكتروني لتأكيد حسابك.',
        'Terms of service': 'شروط الخدمة',
        'Privacy policy': 'سياسة الخصوصية',
        'The password must be at least 6 characters long.': 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل.',
        'Passwords must match.': 'يجب أن تتطابق كلمات المرور.',
        'Please enter a valid email address.': 'يرجى إدخال عنوان بريد إلكتروني صالح.',
        'Please fill in all the required fields.': 'يرجى ملء جميع الحقول المطلوبة.',
        'Please provide your email and password to log in.': 'يرجى تقديم بريدك الإلكتروني وكلمة المرور لتسجيل الدخول.',
        'Please provide your email address to reset your password.': 'يرجى تقديم عنوان بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك.',
        'Please provide your email to reset your password.': 'يرجى تقديم بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك.',
        'Please provide a valid email address.': 'يرجى تقديم عنوان بريد إلكتروني صالح.',
        'Invalid email or password.': 'بريد إلكتروني أو كلمة مرور غير صالحة.',
        'An email has been sent with instructions on how to reset your password.': 'تم إرسال بريد إلكتروني بتعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك.',
        'Welcome back! Please enter your email and password to log in.': 'مرحبًا مرة أخرى! الرجاء إدخال بريدك الإلكتروني وكلمة المرور لتسجيل الدخول.',
        'Welcome!': 'مرحبا!',
        'Invalid email format.': 'تنسيق البريد الإلكتروني غير صالح.',
        'Invalid credentials.': 'بيانات الاعتماد غير صالحة.',
        'User not found.': 'المستخدم غير موجود.',
        'Password reset successful.': 'تم إعادة تعيين كلمة المرور بنجاح.',
        'Password confirmation does not match.': 'تأكيد كلمة المرور لا يتطابق.',
        'The email has already been taken.': 'تم اتخاذ البريد الإلكتروني بالفعل.',
        'Registration successful.': 'تم التسجيل بنجاح.',
        'Login successful.': 'تم تسجيل الدخول بنجاح.',
        'Password reset link is invalid or has expired.': 'رابط إعادة تعيين كلمة المرور غير صالح أو منتهي الصلاحية.',
      }
    },
    gr:{
      "translation": {
        "Dashboard": "Dashboard",
        "Donate manually": "Manuell spenden",
        "Donate automatically": "Automatisch spenden",
        "STATEMENT": "AUSSAGE",
        "MAKE YOUR DONATION": "Machen Sie Ihre Spende",
        "Choose your donation": "Wählen Sie Ihre Spende",
        "Donation": "Spende",
        "Donate": "Spenden",
        "Your donation": "Ihre Spende",
        "Donation Return": "Spendenrückkehr",
        "Active Donations": "Aktive Spenden",
        "Progress": "Fortschritt",
        "Donations Received": "Erhaltene Spenden",
        "Donations Made": "Gemachte Spenden",
        "Community": "Gemeinschaft",
        "Donations": "Spenden",
        "Referral Link": "Empfehlungslink",
        "Statement": "Aussage",
        "Showing data": "Daten anzeigen",
        "entries": "Einträge",
        "Network Commissions": "Netzwerkprovisionen",
        "Date/Time": "Datum/Uhrzeit",
        "User": "Benutzer",
        "Access": "Zugang",
        "Hash Transaction": "Hash-Transaktion",
        "Valor": "Wert",
        "Directs": "Direkt",
        "Indirect": "Indirekt",
        "Assets": "Vermögenswerte",
        "Inactive": "Inaktiv",
        "Total": "Gesamt",
        "My Community": "Meine Gemeinschaft",
        "Name": "Name",
        "Country": "Land",
        "WhatsApp": "WhatsApp",
        "Telegram": "Telegramm",
        "Discord": "Discord",
        "Help": "Hilfe",
        "Reviews": "Bewertungen",
        "All": "Alle",
        "Positive": "Positiv",
        "Negative": "Negativ",
        "Safe and reliable,Polite and friendly,Quick transaction, Patient": "Sicher und zuverlässig,Höflich und freundlich,Schnelle Transaktion, Geduldig",
        "COIN": "Münze",
        "PRICE": "Preis",
        "Payment": "Zahlung",
        "INFO": "Information",
        "Information": "Information",
        "Available / Limit": "Verfügbar / Limit",
        "Online Sale Advertisement": "Online-Verkaufsanzeige",
        "Online Purchase Advertisement": "Online-Kaufanzeige",
        "ONLINE": "Online",
        "ENTERED IN": "Eingegeben in",
        "Minutes": "Minuten",
        "Average Release Time": "Durchschnittliche Freigabezeit",
        "Completion Fee": "Abschlussgebühr",
        "Trades": "Handel",
        "Sell": "Verkaufen",
        "Buy": "Kaufen",
        "All negotiations": "Alle Verhandlungen",
        "negatives": "Negativen",
        "Positives": "Positiven",
        "Positive Ratings": "Positive Bewertungen",
        "back": "Zurück",
        "Email": "Email",
        "Home": "Zuhause",
        "About": "Über",
        "Blog": "Blog",
        "STATUS": "Status",
        "MANUAL DONATION": "MANUELLE SPENDE",
        "AMOUNT ENUN": "MENGE BENENNEN",
        "AMOUNT USDT": "MENGE USDT",
        "LOGIN": "Anmeldung",
        "WALLET ENUN": "WALLET BENENNEN",
        "After completing your donation, please provide the transaction address below.": "Nach Abschluss Ihrer Spende geben Sie bitte die Transaktionsadresse unten an.",
        "txn hash": "txn-hash",
        "Finish": "Fertig stellen",
        "DONATIONS": "SPENDEN",
        "Referral Link": "Empfehlungslink",
        "Average Payment Time": "Durchschnittliche Zahlungszeit",
        "Social Media": "Soziale Medien",
        "Online Advertisement": "Online-Werbung",
        "Comments": "Kommentare",
        "Fiat Currency Name": "Fiat-Währungsname",
        "Fiat Currency Symbol": "Fiat-Währungssymbol",
        "Register": "Registrieren",
        "Country Name": "Ländername",
        "REGISTER": "REGISTRIEREN",
        "publish new ad": "Neue Anzeige veröffentlichen",
        "LOCAL CRYPTO": "LOKALE KRYPTO",
        "LOCAL CRYPTO P2P": "LOKALE KRYPTO P2P",
        "I have met the requirements": "Ich habe die Anforderungen erfüllt",
        "Social network 2": "Soziales Netzwerk 2",
        "orders": "Bestellungen",
        "completed": "Abgeschlossen",
        "Trading requirements": "Handelsanforderungen",
        "Minimum Donation": "Mindestspende",
        "Social network 1": "Soziales Netzwerk 1",
        "enabled": "Aktiviert",
        "desired amount": "Gewünschte Menge",
        "ENUM": "ENUM",
        "BUY": "Kaufen",
        "SELL": "Verkaufen",
        "Send": "Senden",
        "Financial password": "Finanzpasswort",
        "My Wallet": "Mein Geldbeutel",
        "Start by connecting to one of the wallets below. Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Beginnen Sie, indem Sie sich mit einem der unten aufgeführten Geldbörsen verbinden. Stellen Sie sicher, dass Sie Ihre privaten Schlüssel oder Samenphrasen sicher aufbewahren. Teilen Sie sie niemals mit jemand anderem.",
        "Learn how to connect": "Erfahren Sie, wie Sie eine Verbindung herstellen können",
        "I still don't have a wallet": "Ich habe immer noch keine Geldbörse",
        "connect wallet": "Geldbörse verbinden",
        "FINANCIAL DATA": "FINANZDATEN",
        "Observations": "Beobachtungen",
        "Define the Total Value and Payment Method": "Definieren Sie den Gesamtwert und die Zahlungsmethode",
        "Define type and price": "Typ und Preis definieren",
        "Publish Regular Advertisement": "Regelmäßige Anzeige veröffentlichen",
        "previous": "Vorherige",
        "Next": "Nächste",
        "Select the payment method": "Wählen Sie die Zahlungsmethode aus",
        "PicPay": "PicPay",
        "Transfer with specific bank": "Überweisung mit spezifischer Bank",
        "Bank Transfer": "Banküberweisung",
        "Recommended": "Empfohlen",
        "Trubit": "Trubit",
        "Itaú Brazil": "Itaú Brasilien",
        "Confirm": "Bestätigen",
        "Payment institution": "Zahlungsinstitut",
        "Token NameToken contract": "Token-NameToken-Vertrag",
        "REGISTER": "REGISTRIEREN",
        "COMMUNITY": "GEMEINSCHAFT",
        "HELP": "HILFE",
        "DISCORD": "DISCORD",
        "TELEGRAM": "TELEGRAMM",
        "COUNTRY": "LAND",
        "WHATSAPP": "WHATSAPP",
        "NAME": "NAME",
        "USER": "BENUTZER",
        "Showing data": "Daten anzeigen",
        "entries": "Einträge",
        "MY COMMUNITY": "MEINE GEMEINSCHAFT",
        "Local Crypto": "Local Crypto",
        "Financial data": "Finanzdaten",
        "My community": "Meine Gemeinschaft",
        "Statement": "Aussage",
        "Dashboard": "Dashboard",
        "Donation": "Spende",
        "Confirm donation": "Spende bestätigen",
        "Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.": "Achtung: Durch die Bestätigung unten sind Sie sich bewusst, dass Sie die Spende über das Enjoy Union-System tätigen, und Sie stimmen den Geschäftsbedingungen zu.",
        "To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.": "Um eine automatische Spende zu tätigen, müssen Sie Ihre Connect Wallet aktiviert haben. Auf diese Weise wird Ihre Spende mit nur einem Klick automatisch gemacht, und Sie können sie im Aussagebereich überprüfen.",
        "Chosen donation in the amount of": "Gewählte Spende in Höhe von",
        "Fiat Currency Name": "Name der Fiat-Währung",
        "Donate automatically": "Automatisch spenden",
        "Choose your donation": "Wählen Sie Ihre Spende",
        "DONATE": "SPENDEN",
        "COMPLETED": "ABGESCHLOSSEN",
        "MAKE DONATION": "SPENDE MACHEN",
        "LOGIN": "Anmeldung",
        "AMOUNT USDT": "MENGE USDT",
        "AMOUNT ENUN": "MENGE BENENNEN",
        "MANUAL DONATION": "MANUELLE SPENDE",
        "STATUS": "Status",
        "MAKE YOUR DONATION": "Machen Sie Ihre Spende",
        "Directs": "Direkt",
        "INDIRECT": "Indirekt",
        "ASSETS": "Vermögenswerte",
        "INACTIVE": "Inaktiv",
        "TOTAL": "Gesamt",
        "COMMUNITY": "Gemeinschaft",
        "HELP": "HILFE",
        "DISCORD": "DISCORD",
        "Forgot your password?": "Passwort vergessen?",
        "Enter": "Eingeben",
        "CREATE ACCOUNT": "KONTO ERSTELLEN",
        "NEW LOGIN": "NEUE ANMELDUNG",
        "WELCOME": "WILLKOMMEN",
        "LOG INTO YOUR ACCOUNT": "Melden Sie sich bei Ihrem Konto an",
        "SEND": "Senden",
        "Please provide your registered email to send the password reset instructions.": "Geben Sie bitte Ihre registrierte E-Mail-Adresse an, um die Anweisungen zum Zurücksetzen des Passworts zu senden.",
        "Reset your password": "Setzen Sie Ihr Passwort zurück",
        "Registration": "Registrierung",
        "QUALIFICATION": "QUALIFIKATION",
        "DONATIONS RECEIVED": "ERHALTENE SPENDEN",
        "DONATIONS MADE": "GEMACHTE SPENDEN",
        "COMMUNITY GAINS": "GEWINNE DER GEMEINSCHAFT",
        "TOTAL WINS": "GESAMTGEWINNE",
        "BALANCE AVAILABLE": "VERFÜGBARES GLEICHGEWICHT",
        "Start by connecting to one of the wallets below.Make sure to securely store your private keys or seed phrases. Never share them with anyone.": "Beginnen Sie, indem Sie sich mit einem der unten aufgeführten Geldbörsen verbinden. Stellen Sie sicher, dass Sie Ihre privaten Schlüssel oder Samenphrasen sicher aufbewahren. Teilen Sie sie niemals mit jemand anderem.",
        "connect wallet": "Geldbörse verbinden",
        "Learn how to connect": "Erfahren Sie, wie Sie eine Verbindung herstellen können",
        "I still don't have a wallet": "Ich habe immer noch keine Geldbörse",
        "Financial password": "Finanzpasswort",
        "Password": "Passwort",
        "FINANCIAL DATA": "FINANZDATEN",
        "Send": "Senden",
        "REGISTRATION DATA": "REGISTRIERUNGSDATEN",
        "SECURITY": "SICHERHEIT",
        "Start by connecting to one of the wallets below.": "Beginnen Sie, indem Sie sich mit einer der unten aufgeführten Geldbörsen verbinden.",
        "TIKTOK": "TIKTOK",
        "PHONE": "TELEFON",
        "validate Email": "E-Mail überprüfen",
        "FACEBOOK": "FACEBOOK",
        "DISCORD": "DISCORD",
        "INSTAGRAM": "INSTAGRAMM",
        "LAST NAME": "NACHNAME",
        "LOGIN": "Anmeldung",
        "Enter the 2FA code": "Geben Sie den 2FA-Code ein",
        "Manually copy the code": "Code manuell kopieren",
        "Download and install the Google Authenticator app.": "Laden Sie die Google Authenticator-App herunter und installieren Sie sie.",
        "Scan the QR CODE": "Scannen Sie den QR-CODE",
        "Two-factor authentication (2FA)": "Zwei-Faktor-Authentifizierung (2FA)",
        "CONFIRM PASSWORD": "PASSWORT BESTÄTIGEN",
        "NEW PASSWORD": "NEUES PASSWORT",
        "CURRENT PASSWORD": "DERZEITIGES PASSWORT",
        "My Wallet": "Mein Geldbeutel",
        "FINANCIAL": "FINANZIELL",
        "My Profile": "Mein Profil",
        "Change Password": "Passwort ändern",
        "Log Out": "Ausloggen"
      }
    },    
    

        // :{translation:}
        // :{translation:}
    },
    react: {
      transEmpty: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },
  });
export default i18next;
