import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async ({reference: referredBy, login: username, name, lastName, email, mobileNumber: whatsapp, country, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(referredBy, username, name, lastName, email, whatsapp, country, password);
      thunkAPI.dispatch(setMessage("Cadastro realizado com sucesso"));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ userLogin: username, password}, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      return { user: data };
      
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      if (message === '406') {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginAuth = createAsyncThunk(
  "auth/login",
  async ({ email, accessToken: idToken}, thunkAPI) => {
    try {
      const data = await AuthService.loginAuth(email, idToken);
      return { user: data };
      
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      if (message === '406') {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginRefreshToken = createAsyncThunk(
  "auth/login",
  async ({refreshToken}, thunkAPI) => {
    try {
      const data = await AuthService.loginRefreshToken(refreshToken);
      return { user: data };
      
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      if (message === '406') {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const auth = createAsyncThunk(
  "auth/auth",
  async ({ userLogin: username, password, mfaCode: code }, thunkAPI) => {
    try {
      const data = await AuthService.auth(username, password, code);
      return { user: data };
      
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      if (message === '406') {
        return thunkAPI.rejectWithValue(error.message);
      }
      return thunkAPI.rejectWithValue();
    }
  }
);



export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(
      register.fulfilled, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.token = null;
      })
      .addCase(auth.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(auth.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
      })
  },
});

const { reducer } = authSlice;
export default reducer;