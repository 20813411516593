import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import paymentService from '../../services/payment.service';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/auth';
import { useTranslation } from 'react-i18next';






export default function Page() {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const formatDate = (dateStr) => {
    const date = new Date(dateStr + '-03:00');
    return date.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
  };   
  const [donationList, setDonationList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();

  const PAGE_SIZE = 10;
  const TOTAL_RECORDS = donationList.length;

  useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('user')))    
  }, []);


  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(TOTAL_RECORDS / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, TOTAL_RECORDS);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeList = (page) => {
    if (page === 'RECEIVED') {
      getReceivedDonations()
    } else if(page === 'MADE') {
      getMadeDonations()
    } else {
      getBonusReceived()
    }
  }

  const getReceivedDonations = () => {
    paymentService.getReceivedDonationsList(user.userId).then(
      (response) => {
          setDonationList(response)
      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          if (error.response && error.response.status === 401) {
              dispatch(logout());
            }
      }
      );  
  }

  const getMadeDonations = () => {
    paymentService.getMadeDonationsList(user.userId).then(
      (response) => {
        setDonationList(response)
      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          if (error.response && error.response.status === 401) {
              dispatch(logout());
            }
      }
      );  
  }

  const getBonusReceived = () => {
    paymentService.getBonusReceivedList(user.userId).then(
      (response) => {
          setDonationList(response)
      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          if (error.response && error.response.status === 401) {
              dispatch(logout());
            }
      }
      );  
  }

  useEffect(() => {
    if (user) {
      getReceivedDonations()
    }     
  }, [user]);

  const currencyFormatter = (value, digits) => {
    if (!Number(value)) return '';
      const amount = new Intl.NumberFormat('en', {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,                      
      }).format(value);
      return `${amount}`;
  };

  return (
    <div className="min-h-screen p-lg-5 p-3">
      <div className="blackCardLG rounded-3 pb-3 tableOverflow">
        <p className="text-center w-100 text-white font64">{t('STATEMENT')}</p>
        <div className="d-block justify-content-start w-100 px-lg-5 px-0">
          <div className="px-lg-5 px-3">
            <ul className="nav nav-pills  border tableNavBG" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button 
                  onClick={() => handleChangeList('RECEIVED')}
                  className="first nav-link active tablenavButton" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{t('DONATIONS RECEIVED')}</button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => handleChangeList('MADE')}
                  className="nav-link tablenavButton" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">{t('DONATIONS MADE')}</button>
              </li>
              <li className="nav-item" role="presentation">
                <button 
                  onClick={() => handleChangeList('BONUS')}
                  className="nav-link tablenavButton" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">{t('NETWORK BONUS')}</button>
              </li>              
            </ul>
          </div>
          <div className="tab-content w-100" id="pills-tabContent">
            <div className="tab-pane fade show active w-100" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0"><div className="p-lg-5 p-4 pt-3 w-100">
              <table className="table w-100">
                <thead>
                  <tr className="headingrow">
                    <th scope="col">{t('DATE/TIME')}</th>
                    <th scope="col">{t('AMOUNT USDT')}</th>
                    <th scope="col">{t('AMOUNT ENUN')}</th>
                    <th scope="col">{t('HASH')}</th>                    
                  </tr>
                </thead>
                <tbody>
                  {donationList?.slice(startIndex, endIndex).map((data, index) => (
                    <tr key={index}>
                      <td>{formatDate(data.donatedAt)}</td>
                      <td>{currencyFormatter(data.fiatPayedAmount, 2)}</td>                      
                      <td>{currencyFormatter(data.payedAmount, 6)}</td>
                      <td><a href={`https://bscscan.com/tx/${data.transactionAddress}`} className="access-badge" target='blank'   >{t('DETAILS')}</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between w-100 gap-3">
                {/* Pagination section */}
                <p className="text-white m-0">
                {t('Showing data')} {TOTAL_RECORDS > 0 ? startIndex + 1 : 0} {t('to')} {endIndex} {t('of')} {TOTAL_RECORDS} {t('entries')}
                </p>
                <div className="d-flex gap-lg-4 gap-2">
                  <button
                    className="custom-Pagination  border-0"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  {Array.from({ length: Math.min(totalPages, currentPage + 2) }).map((_, index) => {
                    const pageNumber = currentPage + index;
                    return (
                      pageNumber > 0 && pageNumber <= totalPages && (
                        <button
                          key={index}
                          className={`border-0 custom-Pagination ${currentPage === pageNumber ? 'active' : ''}`}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      )
                    );
                  })}


                  <button
                    className="custom-Pagination border-0"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </div>
            </div>
            <div className="  tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0"><div className="p-lg-5 p-4 pt-3 w-100">
              <table className="table w-100">
              <thead>
                  <tr className="headingrow">
                  <th scope="col">{t('DATE/TIME')}</th>
                    <th scope="col">{t('AMOUNT USDT')}</th>
                    <th scope="col">{t('AMOUNT ENUN')}</th>
                    <th scope="col">{t('HASH')}</th>                      
                  </tr>
                </thead>
                <tbody>
                  {donationList?.slice(startIndex, endIndex).map((data, index) => (
                    <tr key={index}>
                      <td>{formatDate(data.donatedAt)}</td>
                      <td>{currencyFormatter(data.fiatPayedAmount, 2)}</td>                      
                      <td>{currencyFormatter(data.payedAmount, 6)}</td>
                      <td><a href={`https://bscscan.com/tx/${data.transactionAddress}`} className="access-badge" target='blank'   >{t('DETAILS')}</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between w-100 gap-3">
                {/* Pagination section */}
                <p className="text-white m-0">
                  {t('Showing data')} {TOTAL_RECORDS > 0 ? startIndex + 1 : 0} {t('to')} {endIndex} {t('of')} {TOTAL_RECORDS} {t('entries')}
                </p>
                <div className="d-flex gap-lg-4 gap-2">
                  <button
                    className="custom-Pagination  border-0"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  {Array.from({ length: Math.min(totalPages, currentPage + 2) }).map((_, index) => {
                    const pageNumber = currentPage + index;
                    return (
                      pageNumber > 0 && pageNumber <= totalPages && (
                        <button
                          key={index}
                          className={`border-0 custom-Pagination ${currentPage === pageNumber ? 'active' : ''}`}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      )
                    );
                  })}


                  <button
                    className="custom-Pagination border-0"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </div></div>
            <div className="  tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex="0"><div className="p-lg-5 p-4 pt-3 w-100">
              <table className="table w-100">
                <thead>
                  <tr className="headingrow">
                    <th scope="col">{t('DATE/TIME')}</th>
                    <th scope="col">{t('USERNAME')}</th>
                    <th scope="col">{t('AMOUNT USDT')}</th>
                    <th scope="col">{t('LEVEL')}</th>
                  </tr>
                </thead>
                <tbody>
                  {donationList?.slice(startIndex, endIndex).map((data, index) => (
                    <tr key={index}>
                      <td>{formatDate(data.createdAt)}</td>
                      <td>{data.fromUsername}</td>
                      <td>{currencyFormatter(data.balance, 4)}</td>
                      <td>{data.level}</td>                      
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between w-100 gap-3">
                {/* Pagination section */}
                <p className="text-white m-0">
                  {t('Showing data')} {TOTAL_RECORDS > 0 ? startIndex + 1 : 0} {t('to')} {endIndex} {t('of')} {TOTAL_RECORDS} {t('entries')}
                </p>
                <div className="d-flex gap-lg-4 gap-2">
                  <button
                    className="custom-Pagination  border-0"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  {Array.from({ length: Math.min(totalPages, currentPage + 2) }).map((_, index) => {
                    const pageNumber = currentPage + index;
                    return (
                      pageNumber > 0 && pageNumber <= totalPages && (
                        <button
                          key={index}
                          className={`border-0 custom-Pagination ${currentPage === pageNumber ? 'active' : ''}`}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      )
                    );
                  })}
                  <button
                    className="custom-Pagination border-0"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </div></div>
          </div>
        </div>
      </div>
    </div>
  )
}
