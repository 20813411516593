import React from 'react';
import { t } from 'i18next'
import { GoogleLogin } from '@react-oauth/google';
import { signInWithGooglePopup } from "../../../utils/firebase.utils"
import { Button } from 'bootstrap';
import { GoogleAuthProvider } from 'firebase/auth';
const CustomGoogleLoginButton = ({ onClick, loginSuccess }) => {

  return (
    <button onClick={onClick} className='border-0 CustomLoginBtn d-flex px-0 m-0 rounded-3 align-items-center mb-2'>
      <p className='bg-white p-2 rounded-start-3'> <img src='/assets/images/googleicon.png' className='googleloginIcon' /> </p>
      <p className='px-6'>{t('Continue with Google')}</p>
    </button>
  );
};

const GoogleLoginButton = ({ clientId, loginSuccess }) => {
  const logGoogleUser = async () => {
    await signInWithGooglePopup()
    .then((result) => {
      // The signed-in user info.
      const user = result.user;
      loginSuccess(user);
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
    
}

  return (    
      <CustomGoogleLoginButton onClick={logGoogleUser} loginSuccess={loginSuccess} />
  );
};

export default GoogleLoginButton;
