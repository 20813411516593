import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { logout } from '../../slices/auth';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import paymentService from '../../services/payment.service';

export default function DonatioCounter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatDate = (dateStr) => {
    const date = new Date(dateStr + '-03:00');
    return date.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
  };  
  const [userPackageList, setUserPackageList] = useState([]);
  const [fiatValue , setFiatValue] = useState(0);
  const [enunValue , setEnunValue] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [packageList, setPackageList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [user, setUser] = useState();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))    
  }, []);


  useEffect(() => {
    if (user) {
      paymentService.getUserPackageList(user.userId).then(
        (response) => {
            setUserPackageList(response)
  
        },
        (error) => {
            const _content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            if (error.response && error.response.status === 401) {
                dispatch(logout());
              }
        }
        );  
    }     
  }, [user]);  

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % packageList.length);
  };


  const handlePrevious = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + packageList.length) % packageList.length);
  };
  
  const automaticDonation = () =>{
   
    navigate('/donation/automatic'); 
  }

  const manualDonation = (donationTransactionId) =>{
    navigate(`/donation/manual/${donationTransactionId}`);
  }

  const goToDonateTransactions = (userPackageId) => {
    navigate(`/donation/donationTransaction/${userPackageId}`);
  };

  const getPrice = () => {    
    const packageValue = packageList[currentIndex].packageValue
    setFiatValue(packageValue);
    paymentService.usdtGetPrice().then(
      (response) => {        
          setUsdtPrice(response);
          setEnunValue(currencyFormatterUSDT(packageValue / response, 8))
      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          setUsdtPrice(0.00);
          if (error.response && error.response.status === 401) {
              dispatch(logout);
            }
      }
      ); 
  }

  const createPackage = () => {
    setIsLoading(true);
    const packageId = packageList[currentIndex].packageId    
    paymentService.createPackage(user.userId, packageId).then(
      (response) => {
          setIsLoading(false);          
          manualDonation(response.donationTransactionId);
      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          setIsLoading(false);
          if (error.response && error.response.status === 401) {
              dispatch(logout);
            }
      }
      ); 
  }

  useEffect(() => {    
        paymentService.listAvailablePackages().then(
            (response) => {
                setPackageList(response);
            },
            (error) => {
                const _content =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                  }
            }
            );     
  }, []);

  const currencyFormatter = (value, digits) => {
    if (!Number(value)) return '';    
    const amount = (value / usdtPrice).toFixed(digits);    
    const formattedAmount = amount.replace(',', '.');
    
    return `${formattedAmount}`;
};

const currencyFormatterUSDT = (value, digits) => {
  if (!Number(value)) return '';    
  const amount = (value).toFixed(digits);    
  const formattedAmount = amount.replace(',', '.');
  
  return `${formattedAmount}`;
};


  
  return (
    <div>
      <p className="font-48 text-white my-5 ">{t('MAKE YOUR DONATION')}</p>
      <div className='cad-card px-3 rounded-3  h-100'>
        <div className='d-flex justify-content-start flex-column'>
          <p className="font-24 text-white my-3 text-start">{t('Choose your donation')}</p>
          <div className='d-flex gap-3'>
            <div className=''>
              <span
              // onClick={()=>subtractCounter()}
              onClick={()=>handlePrevious()}
              className='font-48 text-white bb-white rounded-circle roun sts'>-</span></div>
            <div className='bb-white rounded-pill w-25-custom text-center '>
              <span className='font-38 text-white'>$ {packageList[currentIndex]?.packageValue}</span>
            </div>
            <div className=''>
              <span
              // onClick={()=>addCounter()} 
              onClick={()=>handleNext()}
              className='font-48  text-white bb-white rounded-circle roun sts'>+</span>
            </div>
          </div>
          <div className='d-flex gap-4 w-100'>
            <button 
              data-bs-toggle="modal" data-bs-target="#staticBackdrop"
              type="button"
              className="align-button font-16 mt-3 text-white validate-email rounded-pill wid200 py-2 border-0 w-50-custom"
              onClick={getPrice}>{t('Create New Package')}</button>
            {/* <button 
              data-bs-toggle="modal" data-bs-target="#staticBackdrop2"
              type="button" 
              className="font-16 mt-3 text-white validate-email rounded-pill wid200 py-2 border-0">{t('Donate automatically')}</button> */}
          </div>
        </div>
        {/* --- Start Table --- */}
        <div className='mt-5'>

          <div className='blackCardLG rounded-4 tableOverflow px-2'>
            <p className='font64 text-white mb-4'>{t('MY PACKAGES')}</p>
            <table className="table w-100">
              <thead>
                <tr  >
                  <th scope="col">{t('DATE/TIME')}</th>
                  <th scope="col">{t('NEXT DONATION AT')}</th>
                  <th scope="col">{t('ACTIVE')}</th>
                  <th scope="col">{t('AMOUNT USDT')}</th>                  
                  <th scope="col">{t('PROFIT')}</th> 
                  <th scope="col">{t('BONUS')}</th> 
                  <th scope="col">{t('ACTION')}</th>
                  <th scope="col">{t('DETAILS')}</th>
                  <th scope="col">{t('STATUS')}</th>
                </tr>
              </thead>
              <tbody>
                {userPackageList?.map((data, index) => (
                  <tr key={index}>
                    <td>{formatDate(data.chosenDate)}</td>
                    <td>{data.nextDonationAt ? formatDate(data.nextDonationAt) : null}</td>
                    <td>{data.active === true ? t('Yes') : t('No')}</td>
                    <td>{currencyFormatterUSDT(data.packageValue, 2)}</td> 
                    <td style={{width:'100px'}}>
                      <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700" style={{width: '100px'}}>
                        <div class="bg-green-600 text-xs font-medium text-gray-900 text-center p-0.5 leading-none rounded-full" style={{width: `${data.packageProfit ? data.packageProfit / data.packageValue * 25 : 0 }%`}}> {data.packageProfit ? currencyFormatterUSDT( data.packageProfit / data.packageValue * 100, 2): '0.00' }%</div>
                      </div>
                    </td>
                    <td style={{width:'100px'}}>
                      <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700" style={{width: '100px'}}>
                        <div class="bg-green-600 text-xs font-medium text-gray-900 text-center p-0.5 leading-none rounded-full" 
                          style={{width: `${data.packageBonus ? data.packageBonus / data.packageValue * 100 : 0 }%`}}> {data.packageBonus ? currencyFormatterUSDT(data.packageBonus / data.packageValue * 100, 2 ): 0.00}%</div>
                      </div>
                    </td>                     
                    <td>
                      {data.status === "PENDING" || data.action === 'DONATE' ? (
                        <button onClick={() => goToDonateTransactions(data.userPackageId)} className='donateBTN ' >{t('DONATE')}</button>) : (null)}
                    </td>
                    <td>
                      <button onClick={() => goToDonateTransactions(data.userPackageId)} className='donateBTN ' >{t('DETAILS')}</button>
                    </td>
                    <td ><p className={
                      data.status === 'COMPLETED' ? 'CompletedStatus mb-0' :
                        data.status === 'PARTIAL' ? 'PartialStatus mb-0' :
                          data.status === 'PENDING' ? 'PendingStatus mb-0' :
                            ''}>{data.status === 'PARTIAL' ? (`${t(data.status)}-1/2`) : ( t(data.status)) }</p></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>      
        </div>
        {/* --- End Table --- */}
      </div>
      {/* //////////////////////////////////////modals///////////////////////////////// */}
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content bg-transparent position-relative">
            <button 
              data-bs-toggle="modal" data-bs-target="#staticBackdrop"
              className='reset-back-btn'
            >
              X
            </button>
            <div className="modal-body modal-gradient modalBG">
              <div className='sts pt-5 flex-column'>
                <p className='font-16 text-white'>{t('Amount USDT')}:</p>      
                <input  value={`$ ${fiatValue}`} type='text' placeholder='$ 0' readOnly className="input-rounded rounded-pill py-3 my-3 w-25 text-center"/>
                <p className='font-16 text-white'>{t('Amount ENUN')}:</p>
                <input value={`${enunValue} ENUN`}  type='text' placeholder='2.489 ENUN' readOnly className="input-rounded rounded-pill py-3 my-3 w-50 text-center"/>
              </div>
              <p className='font-20 text-white mt-3'>{t('Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.')}</p>
              <button
                onClick={createPackage}
                data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                disabled={isLoading}
                type="button" className="font-24 mt-5 text-white validate-email rounded-pill w-50 border-0  py-2">
                  {isLoading ? 
                (<div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                  <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Loading...</span>
                </div>) :
                t('Confirm donation')}
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content bg-transparent position-relative">
            <button 
              data-bs-toggle="modal" data-bs-target="#staticBackdrop2"
              className='reset-back-btn'
            >
              X
            </button>
            <div className="modal-body modal-gradient modalBG">
              <div className='sts pt-5 flex-column'>
                <p className='font-16 text-white'>{t('Chosen donation in the amount of')}
                </p>        
                <input  type='text' placeholder='$ 5' className="input-rounded rounded-pill py-3 my-3 w-25 text-center"/>
                {/* <p className='font-16 text-white'>Fiat Currency Name:</p> */}
                <input  type='text' placeholder='2.489 ENUM' className="input-rounded rounded-pill py-3 my-3 w-50 text-center"/>

              </div>
              <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M21.1221 4.62374C20.462 3.27366 18.538 3.27366 17.8778 4.62374L3.80148 33.4164C3.21504 34.6159 4.08837 36.015 5.42359 36.015H33.5764C34.9116 36.015 35.785 34.6159 35.1985 33.4164L21.1221 4.62374ZM14.6336 3.03767C16.6137 -1.01255 22.3862 -1.01257 24.3664 3.03767L38.4428 31.8304C40.202 35.4289 37.5821 39.6262 33.5764 39.6262H5.42359C1.41797 39.6262 -1.20207 35.4289 0.557248 31.8304L14.6336 3.03767Z" fill="#FAFF13"/>
              <path d="M17.6944 13.4462C17.6944 12.449 18.5028 11.6406 19.5 11.6406C20.4972 11.6406 21.3056 12.449 21.3056 13.4462V23.377C21.3056 24.3742 20.4972 25.1826 19.5 25.1826C18.5028 25.1826 17.6944 24.3742 17.6944 23.377V13.4462Z" fill="#FAFF13"/>
              <path d="M22.2085 30.599C22.2085 32.0948 20.9961 33.3074 19.5001 33.3074C18.0044 33.3074 16.7917 32.0948 16.7917 30.599C16.7917 29.1033 18.0044 27.8906 19.5001 27.8906C20.9961 27.8906 22.2085 29.1033 22.2085 30.599Z" fill="#FAFF13"/>
              </svg>
              <p className='font-20 text-white mt-3'>{t('To make an automatic donation, you need to have your Connect Wallet activated. This way, with just one click, your donation will be made automatically, and you can check it in the statement panel.')}</p>
              <p className='font-20 text-white mt-3'>{t('Attention: By confirming below, you are aware that you are making the donation through the Enjoy Union system, and you agree to the terms and conditions.')}</p>
              <button type="button" className="font-24 mt-5 text-white validate-email rounded-pill w-50 border-0 py-2"
                onClick={()=>{automaticDonation()}}
                data-bs-toggle="modal" data-bs-target="#staticBackdrop2"
              >
                {t('Confirm donation')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
