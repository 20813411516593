import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@mui/material/Button';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const Clipboard = (props) => {
  const [clipboard, setClipboard] = useState(props.text);
  const { t } = useTranslation();

  const copyText = t('Copied')+'!';

  function copyToClipboard() {
    
    if (props.displayText){
      setClipboard(copyText);
      const clipTime = setTimeout(() => {
        setClipboard(props.text);
        clearTimeout(clipTime);
      }, 2500);
    }
    
  }

  

  return (
    <CopyToClipboard text={props.dataToCopy} onCopy={copyToClipboard}>
      <Button
        variant="contained"
        color="info"
        className="small mx-auto"
        onClick={copyToClipboard}
      >
        <FontAwesomeIcon icon={faCopy}
          sx={{
            fontSize: 25,
          }}
          color="white"
          aria-label="recipe"
          pointer="hand"
        />
         <label className='ml-2'>{clipboard}</label>
      </Button>
    </CopyToClipboard>
  );
};

export default Clipboard;
