import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ButtonWithTimer from '../charts/timerbutton';
import { t } from 'i18next'
import paymentService from '../../services/payment.service';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export default function Page() {
  const MySwal = withReactContent(Swal)
    const newAlert = (title, message, icon) => {
        MySwal.fire({
            title: '<strong>' + title + '</strong>',
            html: '<i>' + message + '</i>',
            icon
            });
  };
  
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [transactionAddr, setTransactionAddr] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Função para abrir o modal
  const openModal = () => setIsOpen(true);

  // Função para fechar o modal
  const closeModal = () => setIsOpen(false);

  const [user, setUser] = useState();
  const [expired, setExpired] = useState(false);
  const [donations, setDonations] = useState();
  const [donationTransaction, setDonationTransaction] = useState('');
  
  useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('user')))    
    }, []);

  const { donationTransactionId } = useParams();

  const [donationInfo, setDonationInfo] = useState({
    totalAmount: 0,
    paymentAmount: 0,
    walletId: '',
    username: '',
    donationContributionId: ''
  })

  const handleCopy = () => {
    const input = document.querySelector('input[type="text"]');
    input.select();
    document.execCommand('copy');
    const copiedText = input.value;
    newAlert(t('Copied!'), t(`${copiedText}`), 'success');
  };

  const handleChangelink = (event) => {
    setTransactionAddr(event.target.value)
  };

  const handleChangePayment = (index) => {
    setIsOpen(true);
    const selectedDonation = donations[index]
    setDonationInfo({
      totalAmount: selectedDonation?.totalAmount,
      paymentAmount: selectedDonation?.paymentAmount,
      walletId: selectedDonation?.walletId,
      username: selectedDonation?.recipientUsername,
      donationContributionId: selectedDonation?.donationContributionId
    })
    
  }

  function isValidTransactionHash(txHash) {
    // Verifica se a hash é uma string e se tem 66 caracteres (64 + '0x')
    if (typeof txHash !== 'string' || txHash.length !== 66) {
      return false;
    }
    
    // Verifica se começa com '0x'
    if (!txHash.startsWith('0x')) {
      return false;
    }
    
    // Verifica se o restante da string é hexadecimal
    const hexPart = txHash.slice(2);
    const hexRegExp = /^[0-9a-fA-F]+$/;
    return hexRegExp.test(hexPart);
  }

  const handleSubmitTransaction = () => {    
    setIsLoading(true)
    if (isValidTransactionHash(transactionAddr)) {
      paymentService.sendTxn(donationTransactionId, donationInfo.donationContributionId, transactionAddr).then(
        (response) => {
            setDonations(response)
            setIsOpen(false);
            setIsLoading(false)
            setTransactionAddr('')
            newAlert(t('Success!'), t('Transaction validated successfully!'), 'success');
  
        },
        (error) => {
            setIsLoading(false)
            const _content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            setTransactionAddr('')
            newAlert(t('Error!'), t(_content), 'error');
            if (error.response && error.response.status === 401) {
                dispatch(logout());
              }
        }
        );  
     } else {
      setTransactionAddr('')
      setIsLoading(false)
      newAlert(t('Error!'), t('Invalid hash! Please type or paste only the transaction hash!'), 'error');
     }
    
  }

  const getReceivers = () => {
    setExpired(false);
    paymentService.getReceivers(donationTransactionId, user.userId).then(
      (response) => {
          setDonations(response)

      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          if (error.response && error.response.status === 401) {
              dispatch(logout());
            }
      }
      ); 
  }

  useEffect(() => {
    if(user) {
      getReceivers()  
    }
  }, [donationTransactionId, user]);

  useEffect(() => {
    paymentService.getDonationTransaction(donationTransactionId).then(
      (response) => {
          setDonationTransaction(response)

      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
          if (error.response && error.response.status === 401) {
              dispatch(logout());
            }
      }
      );   
  }, [donationTransactionId, donations]);

  const navigate = useNavigate();
  


  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const currencyFormatter = (value, digits) => {
    if (!Number(value)) return '';    
    const amount = (value).toFixed(digits);    
    const formattedAmount = amount.replace(',', '.');
    
    return `${formattedAmount}`;
  };
  return (
    <>
    <div className='p-lg-5 p-3'>

      <div className='blackCardLG rounded-4 tableOverflow'>
        <p className='font64 text-white mb-4'>{t('DONATIONS')}</p>
        <table className="table w-100">
          <thead>
            <tr  >
              <th scope="col">{t('Username')}</th>
              <th scope="col">{t('AMOUNT USDT')}</th>
              <th scope="col">{t('AMOUNT ENUN')}</th>
              <th scope="col">{t('RECEIVED AMOUNT ENUN')}</th>
              <th scope="col">{t('ACTION')}</th>
              <th scope="col">{t('STATUS')}</th>
            </tr>
          </thead>
          <tbody>
            {donations?.map((donation, index) => (
              <tr key={index}>
                <td>{donation.recipientUsername}</td>
                <td>{currencyFormatter(donation.totalAmount, 2)}</td>
                <td>{currencyFormatter(donation.paymentAmount, 8)}</td>
                <td>{donation.payedAmount ? currencyFormatter(donation.payedAmount, 8) : '0.00000000'}</td>
                <td>
                {donation.status === 'PENDING' && !expired ? (
                  <button className='HashBTN '
                  onClick={() => handleChangePayment(index)}  
                  // data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                  >{t('DONATE')}</button>
                ): null}                  
                </td>
                {donation.status === 'PENDING' ? (
                  <td >
                  {expired ? 
                    <p className='ExpiredStatus mb-0'>
                    {t('EXPIRED')  }</p> :
                    <p className='PendingStatus mb-0'>
                      {t(donation.status)}
                    </p>}
                  </td>
                ): donation.status === 'COMPLETED' ? (
                  <td ><p className='CompletedStatus mb-0'

                >{t(donation.status)}</p></td>
                ): donation.status === 'PARTIAL' ? (
                  <td ><p className='PartialStatus mb-0'
                >{t(donation.status)}</p></td>
                ): (
                  <td ><p className='ExpiredStatus mb-0'
                  >{t(donation.status)}</p></td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='d-flex justify-content-center align-items-center flex-column mb-3'>
      
              <ButtonWithTimer startedAt = {donationTransaction?.startedAt+'-03:00'} status={donationTransaction?.status} setExpired={setExpired} getReceivers={getReceivers}/>
        </div>
      </div>

    </div>

    {/* // modals */}
  <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content bg-transparent position-relative">
        <button 
          onClick={closeModal}
            className='reset-back-btn'
            >
            X
          </button>
        <div className="modal-body modal-gradient modalBG">
          <div className='text-start px-2 pt-5 flex-column'>
            <p className='font-24 text-white'>{t('LOGIN')}: {donationInfo.username} </p>
            <p className='font-24 text-white'>{t('AMOUNT USDT')}: $ {currencyFormatter(donationInfo.totalAmount, 2)}</p>
            <p className='font-24 text-white'>{t('AMOUNT ENUN')}: {currencyFormatter(donationInfo.paymentAmount, 8)} ENUN </p>
            <span className='d-flex justify-content-between align-items-center mb-5'>
              <p className='font-24 text-white'>{t('WALLET ENUN')}: </p>
              <input readOnly type='text' value={donationInfo.walletId} className='input-rounded rounded-pill py-1 my-2 w-100 text-center'/>
              <svg 
              onClick={handleCopy}
              className='ms-4' width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.6666 1.29102H5.16659C3.74575 1.29102 2.58325 2.45352 2.58325 3.87435V21.9577H5.16659V3.87435H20.6666V1.29102ZM24.5416 6.45768H10.3333C8.91242 6.45768 7.74992 7.62018 7.74992 9.04102V27.1243C7.74992 28.5452 8.91242 29.7077 10.3333 29.7077H24.5416C25.9624 29.7077 27.1249 28.5452 27.1249 27.1243V9.04102C27.1249 7.62018 25.9624 6.45768 24.5416 6.45768ZM24.5416 27.1243H10.3333V9.04102H24.5416V27.1243Z" fill="white"/>
              </svg>
            </span>        
            <span className='d-flex gap-3'>
              <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.1221 4.62374C20.462 3.27366 18.538 3.27366 17.8778 4.62374L3.80148 33.4164C3.21504 34.6159 4.08837 36.015 5.42359 36.015H33.5764C34.9116 36.015 35.785 34.6159 35.1985 33.4164L21.1221 4.62374ZM14.6336 3.03767C16.6137 -1.01255 22.3862 -1.01257 24.3664 3.03767L38.4428 31.8304C40.202 35.4289 37.5821 39.6262 33.5764 39.6262H5.42359C1.41797 39.6262 -1.20207 35.4289 0.557248 31.8304L14.6336 3.03767Z" fill="#FAFF13"/>
                <path d="M17.6944 13.4462C17.6944 12.449 18.5028 11.6406 19.5 11.6406C20.4972 11.6406 21.3056 12.449 21.3056 13.4462V23.377C21.3056 24.3742 20.4972 25.1826 19.5 25.1826C18.5028 25.1826 17.6944 24.3742 17.6944 23.377V13.4462Z" fill="#FAFF13"/>
                <path d="M22.2085 30.599C22.2085 32.0948 20.9961 33.3074 19.5001 33.3074C18.0044 33.3074 16.7917 32.0948 16.7917 30.599C16.7917 29.1033 18.0044 27.8906 19.5001 27.8906C20.9961 27.8906 22.2085 29.1033 22.2085 30.599Z" fill="#FAFF13"/>
              </svg>
              <p className='font-24 text-white'>{t('After completing your donation, please provide the transaction address below.')}</p>
            </span>

            <p className='font-24 text-white'>{t('txn hash')}:</p>
            <div className='sts gap-5'>
              <input
                type="text"
                value={transactionAddr}
                onChange={handleChangelink}
                placeholder={t('txn hash')}
                className='input-rounded rounded-pill py-3 my-3 w-100 text-center'
              />
              <span className={transactionAddr === '' ? 'sts modal-tick-disable' : 'modal-tick sts'}>

              <svg  width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.6648 0.636719L6.80204 10.7813L3.51445 7.39977L0.226861 10.7813L6.80204 17.5443L19.9524 4.01824L16.6648 0.636719Z" fill="white"/>
              </svg>
              </span>
            </div>

          </div>

          {transactionAddr === '' || !donationInfo ? 
          <>              
            <button
                type="button"
                className="font-24 mt-5 text-white disabledButton rounded-pill px-5 border-0 py-2"
            >
                {t('Finish')}
            </button>
          </> :
          isLoading ? (
            <>
                
                <button
                  disabled={isLoading}
                  type="button"
                  className="font-24 mt-5 text-white validate-email rounded-pill px-5 border-0 py-2">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                  </svg>
                  {t('Processing...')}
                </button>
          </>
          ) : (
            <>
                
            <button
              type="button"
              className="font-24 mt-5 text-white validate-email rounded-pill px-5 border-0 py-2"
              onClick={handleSubmitTransaction}
            >
              {t('Finish')}
            </button>
          </>
          )
          }
      </div>
    </div>
  </div>
</div>
</>
  )
}
