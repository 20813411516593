import axios from "axios";

import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_BASE_API_URL;

const getMembers = (username) => {

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + 'users/hierarchy/'+ username,
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {        
        if (error.code === 'ERR_NETWORK') {
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getMembersByLevel = (username, level) => {

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + 'users/hierarchy/'+ username + '/' + level,
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getNotifications = (userId) => {

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + 'notifications/' + userId,
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getUserDetails = (username) => {

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + 'auth/user/details/'+ username,
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getMfaDetails = (username) => {

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + 'auth/mfa/details/'+ username,
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};


const getUserStatus = (userId) => {

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + 'users/status/' + userId,
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const updateUserDetails = (username, name, lastName, email, whatsapp, country, walletId) => {

  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + 'auth/user/update/'+ username,
      {name, lastName, email, whatsapp, country, walletId},
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const updateUserPassword = (username, currentPassword, newPassword) => {

  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + 'auth/updatePassword/'+ username,
      {currentPassword, newPassword},
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {          
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 401) {
          reject(new Error('Invalid password. Try again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const passwordRecovery = (search, language) => {

  return new Promise((resolve, reject) => {
    axios
    .post(API_URL + 'auth/login/passwordRecovery',
      {search, language},
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {          
          reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 404) {
          reject(new Error('Email not found!'));
        } else if (error.response.status === 401) {
          reject(new Error('Invalid password. Try again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const passwordRecoveryUpdate = (userId, recoveryCode, password) => {

  return new Promise((resolve, reject) => {
    axios
    .post(API_URL + `auth/login/passwordRecovery/${userId}/${recoveryCode}` ,      
      {password},
      { headers: authHeader() })
      .then((response) => {
        if (response.data) {          
          resolve(response.data); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.code === 'ERR_NETWORK') {          
          reject(new Error('Unauthorized! Please login again.'));
        }
        else if (error.response.status === 422) {
          reject(new Error('Invalid or expired recovery code.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const enableMFA = (mfaCode) => {
  return axios.post(API_URL + "auth/mfa/enable" , {code: mfaCode}, { headers: authHeader() })    
};

const disableMFA = (mfaCode) => {
  return axios.post(API_URL + "auth/mfa/disable" , {code: mfaCode}, { headers: authHeader() })    
};


const membersService = {
    getMembers,
    getMembersByLevel,
    getUserDetails,
    getMfaDetails,
    updateUserPassword,
    updateUserDetails,
    getUserStatus,
    passwordRecovery,
    passwordRecoveryUpdate,
    getNotifications,
    enableMFA,
    disableMFA,
  };

export default membersService;