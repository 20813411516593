import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const ButtonWithTimer = (props) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const totalDuration = 1500; // 25 minutes in seconds

  const calculateTimeLeft = (startedAt) => {
    const endTime = new Date(startedAt).getTime() + totalDuration * 1000; // endTime em UTC
    const now = new Date().getTime(); 
    const difference = endTime - now;
    return Math.max(0, Math.floor(difference / 1000));
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props.startedAt));
  const [progressWidth, setProgressWidth] = useState((1 - timeLeft / totalDuration) * 100);
  const [timerExpired, setTimerExpired] = useState(timeLeft === 0);

  useEffect(() => {
      setTimeLeft(calculateTimeLeft(props.startedAt));
      setTimerExpired(false);

      const timer = setInterval(() => {
          const newTimeLeft = calculateTimeLeft(props.startedAt);
          setTimeLeft(newTimeLeft);
          if (newTimeLeft === 0) {
              clearInterval(timer);
              setTimerExpired(true);
              if (props.setExpired) {
                  props.setExpired(true);
              }
          }
      }, 1000);

      return () => clearInterval(timer);
  }, [props.startedAt]);

  useEffect(() => {
      setProgressWidth((1 - timeLeft / totalDuration) * 100);
  }, [timeLeft]);

  const gotodonate = () => {
      navigate('/Donation');
  };

  const formattedTime = () => {
      if (isNaN(timeLeft)) {
          return "00:00";
      }
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
      <div>
        {props.status === 'COMPLETED' ? (
          <div>
            <button 
              type="button" 
              className="font-20 my-5 text-white transaction-completed rounded-pill wid200 py-2 border-0"
              onClick={gotodonate}
            >
                {t('COMPLETED')}
            </button>
          </div>
        ) : 
        (
          timerExpired ? (
            <div>
              <button 
                type="button" 
                className="font-20 my-5 text-white validate-email rounded-pill wid200 py-2 border-0"
                onClick={props.getReceivers}
            >
                {t('Reload')}
              </button>
              <button 
              type="button" 
              className="font-20 my-5 text-white validate-email rounded-pill wid200 py-2 border-0 ml-4"
              onClick={gotodonate}
              >
                  {t('Back')}
              </button>
            </div>
          ) : (
              <div className='d-flex flex-column justify-content-center w-100 align-items-center'>                  
                  <p className='font-36 w-75 px-5 text-center text-white mb-3'>
                      {t('You must complete your donation within a maximum of 25 minutes.')}
                  </p>
                  <button className="button-with-timer">
                        <div className="button-text">{formattedTime()}</div>
                        <div className="progress-bar" style={{ width: `${progressWidth}%` }}></div>
                    </button>
              </div>
          ))}
      </div>
    )
};

export default ButtonWithTimer;