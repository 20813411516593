import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { register  } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { Formik } from 'formik';
import { Link, Navigate, useParams } from 'react-router-dom';
import * as Yup from "yup";

export default function Page(history) {

  const MySwal = withReactContent(Swal)

    const newAlert = (title, message, icon) => {
        MySwal.fire({
            title: '<strong>' + title + '</strong>',
            html: '<i>' + message + '</i>',
            icon
          });
    };

    const { t } = useTranslation();

    let { reference } = useParams();
    let isReference = true;    

  const [loading, setLoading] = useState(false);

  const [mobileNumber, setMobileNumber] = useState('');

  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  useEffect(() => {
        if (message && !successful) {
          newAlert(t('Error!'), t(message), 'error');
          clearMessage();
        }
        
      }, [message]);
  const dispatch = useDispatch();

  const initialValues = {
    login: '',
    name: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirm: '',
    mobileNumber: '',
    country: 'Brazil'
  };

  const validationSchema = Yup.object().shape({
    // reference: Yup.string().required(t('This field is required!')),
    login: Yup.string()
        .required(t('This field is required!'))
        .matches(/^[A-Za-z0-9]*[A-Za-z0-9][A-Za-z0-9]*$/,
            t('Only letters and numbers are accepted.') )
        .test(
            "len",
            t('The username must have between 3 and 30 characters.'),
            (val) =>
            val &&
            val.toString().length >= 3 &&
            val.toString().length <= 30
        ),
    name: Yup.string().required(t('This field is required!'))
        .test(
            "len",
            t('The name must have between 3 and 100 characters.'),
            (val) =>
            val &&
            val.toString().length >= 3 &&
            val.toString().length <= 30
        ),
    lastName: Yup.string().required(t('This field is required!'))
        .test(
            "len",
            t('The name must have between 3 and 100 characters.'),
            (val) =>
            val &&
            val.toString().length >= 3 &&
            val.toString().length <= 30
        ),
    country: Yup.string().required(t('This field is required!')),
    email: Yup.string()
      .email(t('Invalid email.'))
      .required(t('This field is required!')),
    password: Yup.string()
        .test(
            "len",
            t('The password must be between 6 and 20 characters.'),
            (val) =>
            val &&
            val.toString().length >= 6 &&
            val.toString().length <= 20
        )
        .required(t('This field is required!')),
    passwordConfirm: Yup.string()
        .test(
            "len",
            t('The password must be between 6 and 20 characters.'),
            (val) =>
            val &&
            val.toString().length >= 6 &&
            val.toString().length <= 20
        )
        .oneOf([Yup.ref('password'), null], t('The passwords do not match.'))
        .required(t('This field is required!')),
    mobileNumber: Yup.string()
        // .matches(/^\(\d{2}\)\s\d{5}-?\d{4}$/, 'Invalid Mobile Number. (00) 00000-0000')
        .required(t('This field is required!'))
        .test(
            "len",
            t('The phone must be between 6 and 25 characters.'),
            (val) =>
            val &&
            val.toString().length >= 6 &&
            val.toString().length <= 25
        )
  });

  const handleRegister = (formValue, resetForm) => {  
    const { login, name, lastName, email, mobileNumber, country, password } = formValue;
    setLoading(true);
    setSuccessful(false);
    dispatch(register({ reference, login, name, lastName, email, mobileNumber, country, password }))
      .unwrap()
      .then((response) => {
        setSuccessful(true);
        newAlert(t('Success!'), t('Registration successfully completed!'), 'success');        
        setLoading(false);        
        resetForm();
        setMobileNumber('');
        clearMessage();
        history.history.push("/dashboard");
        window.location.reload();
        <Navigate to="/login" />
      })
      .catch(() => {
        setSuccessful(false);        
        setLoading(false);
      });    
  };

  if (!reference){
    newAlert(t('Oops!'), t('Registration allowed only with referral.'), 'error');
    reference = null
    isReference = false;
    return <Navigate to="/login" />;
}
 
  return (
    <div className=''>
      <div className='rounded cad-card pt-lg-5 pt-3 px-lg-0 px-3'>
        <div className='d-flex w-full justify-content-center mt-5'>
          <img 
        src='/assets/images/logo.png'
        alt='logo image'
        /></div>
        <p className='font-48 text-white'>{t('Registration')}</p>
        <p className='font-24 text-white'>{t('SPONSOR')}: {reference} </p>

      <Formik
       initialValues={initialValues}
       validationSchema={validationSchema}
       validateOnChange = {false}
       validateOnBlur = {false}
       onSubmit={(values, {resetForm})=>{
        handleRegister(values, resetForm);                                            
    }}
     >
       {props => (
      <form onSubmit={props.handleSubmit}>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <input
            name='name' 
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.name}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('NAME')}/>
          {props.errors.name && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.name)}</div>}
          <input 
            name='lastName' 
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.lastName}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('LAST NAME')}/>
          {props.errors.lastName && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.lastName)}</div>}
          <input 
            name='login' 
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.login}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('LOGIN')}/>
          {props.errors.login && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.login)}</div>}
          <input 
            name='country' 
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.country}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('COUNTRY')} type='text'/>
          {props.errors.country && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.country)}</div>}
          <input 
            name='email' 
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.email}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('EMAIL')} type='email'/>
          {props.errors.email && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.email)}</div>}
          <input 
            name='mobileNumber' 
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.mobileNumber}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('WHATSAPP')} type='number'/>
          {props.errors.mobileNumber && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.mobileNumber)}</div>}
          <input 
            name='password' 
            autoComplete = 'on'
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.password}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('PASSWORD')}  type='password'/>
          {props.errors.password && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.password)}</div>}
          <input 
            name='passwordConfirm' 
            autoComplete = 'on'
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.passwordConfirm}
            className='inputWidth gray-pill py-3 rounded-pill my-3  border-0 text-white' placeholder={t('CONFIRM PASSWORD')} type='password'/>
          {props.errors.passwordConfirm && <div className='small' style={{color: 'red'}} id="feedback">{t(props.errors.passwordConfirm)}</div>}
          <div className="rog-bottom">
            <div className="reg-check">
              <label className="containers">{t('I accept the')} <a href={`https://app.enjoyunion.club/assets/docs/enjoy-union-terms-and-conditions_${t('lang')}.txt`} target="_blank" rel="noreferrer">{t('terms and conditions')}</a>
                  <input name="confirm" type="checkbox" required />
                  <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <button type='submit' disabled={loading} className='rounded-5 bg-green my-4 px-5  border-0  py-2'>{t('REGISTER')}</button>          
        </div>
        <div className="reg-text">
            <p style={{color: 'white'}}>{t('Already have an account?')} <Link to='/login'>{t('Sign in')}</Link>.</p>
        </div>
          </form>
          )}
      </Formik>
      </div>
    </div>
  )
}
