import { useEffect, useState } from "react";
import SidebarMobile from '../sideBar/page2'
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../slices/auth";
import { setLanguage } from "../../slices/language";
import { DateTime } from 'luxon';
import membersService from "../../services/members.service";


export default function Page() {
  // const formatDate = (date) => DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);  

  const formatDate = (dateStr) => {
    const date = new Date(dateStr + '-03:00');
    return date.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
};
// language translator
const { i18n } = useTranslation();

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const handleLogoutClick = () => {
    dispatch(logout());
  };
  
  const [notiShow, setNotiShow] = useState(false);
  const [sideShow, setSideShow] = useState(false);

  const [ flag, setFlag] = useState(useSelector(state => state.language));
  const [ showflag, setShowFlag] = useState('false');
  const [ showlogin, setShowLogin] = useState('false');
  const [user, setUser] = useState();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))    
  }, []);

  const fetchNotifications = () => {
    if (user) {
      membersService.getNotifications(user.userId).then(
        (response) => {
          setNotifications(response);
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 401) {
            dispatch(logout());
          }
        }
      );
    }
  };

  useEffect(() => {
    fetchNotifications();

    const interval = setInterval(fetchNotifications, 2 * 60 * 1000); // 2 minutes

    return () => clearInterval(interval);
  }, [user, location.pathname]);

  const handleButtonClick = (value) => {
    setSideShow(value); // Update state in parent component when button is clicked
  };
  const handleurl=(url)=>{
    navigate(url);
    openLogin();
}
  const handleNotification=()=>{
    setNotiShow(!notiShow);
}
  const handleSidebar=()=>{
    setSideShow(!sideShow);
}
const openFlag = () => {
  {showflag === true ? setShowFlag(false) : setShowFlag(true)}  
}
const openLogin = () => {
  {showlogin === true ? setShowLogin(false) : setShowLogin(true)}  
}
const selectFlag = (src , lang) =>{
  changeLanguage(lang);
  setFlag(src);
  setShowFlag(false);
}

  let flags = useSelector(state => state.languages)  
  const currentLanguage = useSelector(state => state.language);

  useEffect(() => {
    const currentFlag = flags.find(flag => currentLanguage.startsWith(flag.lang));
    if (currentFlag) {
      selectFlag(currentFlag.src, currentLanguage);
    } else {
      selectFlag('/assets/flags/flag2.png', 'en');
    }    
  }, [currentLanguage])
  const [notifications, setNotifications] = useState([])

  const requestNotificationPermission = async () => {
    if (!('Notification' in window)) {
      console.log("This browser do not support notifications.");
      return;
    }
    try {
      const permission = await Notification.requestPermission();
      console.log(`Permission status: ${permission}`);
      if (permission === 'granted') {
        console.log('Notification granted!');
      } else {
        console.log('Notification denied!');
      }
    } catch (error) {
      console.error('Error requesting notification permission.', error);
    }
  };

// Função para enviar uma notificação
const sendNotification = (title, options) => {
  if (Notification.permission === 'granted') {
      new Notification(title, options);
  }
};

useEffect(() => {
  requestNotificationPermission();
}, []);

useEffect(() => {
  notifications.forEach(noti => {
      if (noti.status === 'N') {
          sendNotification(noti.type === "BONUS" ? t('BONUS RECEIVED') :
                            noti.type === "PENDING_DONATION" ? t('PENDING DONATION') :
                            noti.type === "DONATION_RECEIVED" ? t('DONATION RECEIVED') : t('NEW USER'), {
              body: noti.type === "BONUS" ? (t('You received $ ') + currencyFormatter(noti.amount, 2) + t(' for referring ') + noti.fromUsername) :
                    noti.type === "PENDING_DONATION" ? t('PENDING DONATION') :
                    noti.type === "DONATION_RECEIVED" ? (t('You received $ ') + currencyFormatter(noti.amount, 2)) : null,
              icon: '/assets/assetsdashboard/favicon-32x32.png' // Substitua pelo caminho do ícone real
          });

          // Opcional: atualizar o status para evitar notificações repetidas
          setNotifications(prevNotifications =>
              prevNotifications.map(n =>
                  n.id === noti.id ? { ...n, status: 'R' } : n
              )
          );
      }
  });
}, [notifications]);


    let icons = [
    { name : 'BITCOIN', id:1,image:'/assets/bitcoin images/logo 1.png' , coin : 'USD' , value : '804' ,profit : '2.5'},
    { name : 'USDT', id:1,image:'/assets/bitcoin images/logo 2.png' , coin : 'USD' , value : '804' ,profit : '2.5'},
    { name : 'IN A', id:1,image:'/assets/bitcoin images/signal.png' , coin : 'USD' , value : '804' ,profit : '2.5'},
    { name : 'BNB', id:1,image:'/assets/bitcoin images/logo 3.png' , coin : 'USD' , value : '804' ,profit : '2.5'},
    { name : 'ETH', id:1,image:'/assets/bitcoin images/logo 4.png' , coin : 'USD' , value : '804' ,profit : '2.5'},
    { name : 'SUN', id:1,image:'/assets/bitcoin images/solana.png' , coin : 'USD' , value : '804' ,profit : '2.5'},
    { name : 'THERE IS', id:1,image:'/assets/bitcoin images/image 1.png' , coin : 'USD' , value : '804' ,profit : '2.5'},
]

const currencyFormatter = (value, digits) => {
  if (!Number(value)) return '';    
  const amount = (value).toFixed(digits);    
  const formattedAmount = amount.replace(',', '.');
  
  return `${formattedAmount}`;
};


  return (
<>

<div className="desktop-nav">
<div>
  {sideShow === true  ? <SidebarMobile onButtonClick={handleButtonClick} /> : null}
  
  </div>

    <div className="w-100 d-flex top-justify align-items-center py-3 top-nav">
      <div className="w-2/3 top-crypto top-hide gap-1">
      {/* <div  className="top-bit d-flex gap-1 align-items-center w-100">
    
    {icons.map((item) => (
    <div className="bitcoin br-gray">
              <img
            src={item.image}
            alt="bitcoin "
            className="bt-img"
            />

          <div className="d-flex flex-column gap-1 align-items-center  px-1">
            <div className="w-100">
            <p className="text-white tp-f1 text-start">{item.name}</p>
            </div>
            <div className="sts gap-1"><p className="text-white tp-f2">{item.coin}</p>
            <p className="text-white tp-f2 d-flex gap-1">
              {item.value} 
              <span className=" ml-2 d-flex gap-1 tp-f2 align-items-center text-red">
                {item.profit}
                <svg
                  width="9"
                  height="5"
                  viewBox="0 0 9 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.92445 4.83714C4.93004 4.83114 4.93228 4.82314 4.93787 4.81714L8.84228 0.960028C9.06037 0.743021 9.06037 0.391011 8.84228 0.174005L8.83557 0.170005C8.78704 0.117459 8.72627 0.0750206 8.6575 0.0456566C8.58873 0.0162925 8.51361 0.000710714 8.43741 0H0.627473C0.549869 0.00112125 0.473485 0.0174652 0.40372 0.0478777C0.333955 0.0782902 0.272509 0.122029 0.223723 0.176005L0.221487 0.174005C0.115941 0.283194 0.0578613 0.422696 0.0578613 0.567016C0.0578613 0.711337 0.115941 0.850839 0.221487 0.960028L4.13484 4.83714C4.18356 4.8882 4.24409 4.9292 4.31225 4.95732C4.38041 4.98544 4.45458 5 4.52964 5C4.60471 5 4.67888 4.98544 4.74704 4.95732C4.8152 4.9292 4.87573 4.8882 4.92445 4.83714Z"
                    fill="#FF0000"
                    fill-opacity="0.6"
                  />
                </svg>
              </span>
            </p></div>
          </div>

    </div>
    ))}    </div> */}
      </div>
      <div className=" ps-lg-2 pe-lg-4 pe-0 ps-0">
      <div className="w-100  d-flex gap-lg-2 gap-1 align-items-center justify-content-between">
     <div className="w-100  d-flex gap-lg-2 gap-1 align-items-center justify-content-center ">
      <button className="ham-hide"
      onClick={()=>handleSidebar()}
      >

      <div className="hamburgbtn"></div>
      <div className="hamburgbtn"></div>
      <div className="hamburgbtn"></div>
      </button>
     {/* <div className="top-btn btn-gradient py-2 px-4 rounded-3 d-flex gap-2 w-60">
        <select className=" rounded-3 w-full ">
        <option>0x5a5a...567C</option>
        <option>0x5a5a...567C</option>
        <option>0x5a5a...567C</option>
        </select>
        <div>
          <img
          src='/assets/bitcoin images/Ellipse 5.png'
          alt="eps"
          />
        </div>
      </div> */}
        <div className="selected-flag position-relative" >
        <img

        onClick={()=>openFlag()}
          src={flag}
          alt="eps" className="d-lg-block d-none ms-3 flag-desk-2"
          />
                    {showflag === true ? (
  <div className="flag-grid">
    {flags.map(f => (
      <div
      key={f.id} 
      onClick={() => dispatch(setLanguage(f.lang))}   
      className="single-flag d-flex align-items-center mb-2 gap-2" 
      style={{cursor: 'pointer'}}          
      >
        <div style={{backgroundImage: `url(${f.src})`}} className="flag-desk"></div>
        <p className="font-16 text-white">{f.name}</p>
      </div>
    ))}
  </div>
) : null}

        </div>
       <button 
       
       className="border-0 bg-transparent"
       >
       <img 
        onClick={handleNotification}
          src='/assets/bitcoin images/notification.png'
          alt="eps"
          />  
       </button>
          <button className="btn-gradient py-0 font-12 text-white rounded-3 px-4"
          style={{height:' 25px'}}
          onClick={()=>openLogin()}
          >
            { user ? t('Hello') + ', ' + user.username : t('Hello') + ', ' + t('Visitor')} 
          </button>
          {showlogin === true ? (
  <div className="d-flex flex-column gap-2 login-dropdown" 
  >
    <button className="btn-gradient py-0 font-12 text-white rounded-3 px-4 w-100" style={{ height: '25px' }}
    onClick={()=>handleurl('/profile')}
    >
      {t('My Profile')}
    </button>
    <button className="btn-gradient py-0 font-12 text-white rounded-3 px-4 w-100" style={{ height: '25px' }}
    onClick={()=>handleurl('/profile')}
    >
      {t('Change Password')}
    </button> 
    <a href={`http://app.enjoyunion.club/assets/docs/enjoy-union-terms-and-conditions_${t('lang')}.pdf`} target="blank" className="btn-gradient font-12 text-white rounded-3 px-4 w-100 text-decoration-none" style={{ height: '25px' }}>
      {t('Terms and Conditions')}
    </a>
    <button className="btn-gradient py-0 font-12 text-white rounded-3 px-4 w-100" style={{ height: '25px' }}
    onClick={handleLogoutClick}
    >
      {t('Log Out')}
    </button>
  </div>
) : null}

          

     </div>
      </div>
      {/* if notification clicked */}
      {notiShow ? 
    <div className="notification-card rounded-3 position-absolute" style={{maxHeight: '500px', overflowY:'scroll'}}>
      <div className="d-flex justify-content-start pt-3 px-4" >
            <a className="text-white font-14 text-start pb-3 no-underline"><b>{t('Notifications')}</b></a>
</div>
    <div>
    {notifications.map((noti, index)=> 
    <div className={index % 2 !== 0 ?  'py-3 px-4' : 'px-4 py-3 notification-dark' }>
      <p className="font-12 text-white text-start">
        {noti.type === "BONUS" ? t('BONUS RECEIVED') :
         noti.type === "PENDING_DONATION" ? t('PENDING DONATION') :
         noti.type === "DONATION_RECEIVED" ? t('DONATION RECEIVED') : t('NEW USER')}
      </p>
      <p className="font-12 text-white py-2 text-start">
        {noti.type === "BONUS" ? (t('You received $ ') + currencyFormatter(noti.amount, 2) + t(' for referring ') + noti.fromUsername) :
         noti.type === "PENDING_DONATION" ? t('PENDING DONATION') :
         noti.type === "DONATION_RECEIVED" ? (t('You received $ ') + currencyFormatter(noti.amount, 2)):null}</p>
      <p className="font-10 text-white text-start">{formatDate(noti.eventTime)}</p>
    </div>
    )}
    </div>
</div>
: ''}
      </div>
    </div>
</div>


</>


  );
}
