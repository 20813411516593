import Flip from './flip/flip'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ChartComponent from '../charts/page';
import CircleChartComponent from '../charts/circlechart';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import paymentService from '../../services/payment.service';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/auth';
import membersService from '../../services/members.service';
import ModalBanner from './components/ModaBanner';



export default function Page() {    
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal)
    const newAlert = (title, message, icon) => {
        MySwal.fire({
            title: '<strong>' + title + '</strong>',
            html: '<i>' + message + '</i>',
            icon
            });
    };
    const navigate = useNavigate();
    const {t} = useTranslation();
    
    const formatDate = (dateStr) => {
        const date = new Date(dateStr + '-03:00');
        return date.toLocaleString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const bannerUrl = t('Banner_URL')

    const [user, setUser] = useState();
    const [openBanner, setOpenBanner] = useState(true);
    const [hasBanner, setHasBanner] = useState(true);
    const [receivedDonations, setReceivedDonations] = useState(0.00);
    const [madeDonations, setMadeDonations] = useState(0.00);
    const [bonusReceived, setBonusReceived] = useState(0.00);
    const [bonusDonationReceived, setBonusDonationReceived] = useState(0.00);
    const [userStatus, setUserStatus] = useState('vip-1');
    const [nextDonation, setNextDonation] = useState();

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')))    
      }, []);

    const handleurl=(url)=>{
        navigate(url);
    }

    useEffect(() => {
        if(user) {
            paymentService.getReceivedDonations(user.userId).then(
                (response) => {
                  setReceivedDonations(response)
          
                },
                (error) => {
                    const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                    if (error.response && error.response.status === 401) {
                        dispatch(logout());
                      }
                }
                );
                paymentService.getMadeDonations(user.userId).then(
                    (response) => {
                      setMadeDonations(response)
              
                    },
                    (error) => {
                        const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                        if (error.response && error.response.status === 401) {
                            dispatch(logout());
                          }
                    }
                ); 
                paymentService.getBonusReceived(user.userId).then(
                    (response) => {
                        setBonusReceived(response)
                
                    },
                    (error) => {
                        const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                        if (error.response && error.response.status === 401) {
                            dispatch(logout());
                            }
                    }
                );
                paymentService.getBonusDonationReceived(user.userId).then(
                    (response) => {
                        setBonusDonationReceived(response)
                
                    },
                    (error) => {
                        const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                        if (error.response && error.response.status === 401) {
                            dispatch(logout());
                            }
                    }
                );
                membersService.getUserStatus(user?.userId).then(
                    (response) => {
                        setUserStatus(response)
            
                    },
                    (error) => {
                        const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                        if (error.response && error.response.status === 401) {
                            dispatch(logout());
                        }
                    }
                    );
                paymentService.getUserNextDonation(user.userId).then(
                (response) => {
                    setNextDonation(response)
            
                },
                (error) => {
                    const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                    if (error.response && error.response.status === 401) {
                        dispatch(logout());
                        }
                }
                );
        }
         
      }, [user]);

    const handleCopy = () => {
        const input = document.querySelector('input[type="text"]');
        input.select();
        document.execCommand('copy');
        const copiedText = input.value;
        newAlert(t('Copied!'), t(`${copiedText}`), 'success');
      };




      const remainingDays = 10;
      const [days, setDays] = useState(15);
    
      const daysManage = () => {
        if (days > remainingDays) {
          setDays(days - 1);
        }
      };
    
      useEffect(() => {
        const intervalId = setInterval(daysManage, 3 * 1000); // 3 seconds
    
        // Clear interval on component unmount or when days reaches remainingDays
        return () => {
          clearInterval(intervalId);
        };
      }, [days]);


      const currencyFormatter = (value, digits) => {
        if (!Number(value)) return '';
          const amount = new Intl.NumberFormat('en', {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits,                      
          }).format(value);
          return `${amount}`;
      };
    return (
    <div className="min-h-screen ">
          <div className="main-wrapper">
        <div className="p-4">
             
                <div className='d-lg-flex d-block mb-4'>
                    <div className='col-lg-6 col-12 text-white rounded-4 pe-lg-4 pe-0 mb-lg-0 mb-4'>
                   <a href='https://pancakeswap.finance/swap?outputCurrency=0x34cB446e56386DA32E0447B84AE09487A0011368' target='_blank' rel="noreferrer">
                    <img src="/assets/assetsdashboard/dashboardtopleft.jpeg" alt="" className='w-100 h-100' />
                   </a>
                    </div>
                    {1>2 && (
                    <div className='col-lg-6 col-12 text-white ps-lg-4 ps-0 '>                        
                    <div className='toprightCard rounded-4 h-100'>                    
                        <div className="p-4  h-100  test-1">
                         
                        <ChartComponent/>
                    </div>
                                      
                    </div>
                    </div>
                    )}  
                </div>
{/* <LanguageSelector /> */}
                <div className='row mb-3 mt-5'>              
                    <div className='col-lg-2 col-md-6 mb-3 col-12  text-white rounded-4'>
                    <div className="blkcardHeadings mb-4">
                        {t('NEXT DONATION')}
                    </div>
                    <div className="rounded-4 blackCard onecardimg d-flex">
                    <span className='d-flex font-12 mb-2'>
                                    <b>{t('NEXT DONATION AT')}:</b>
                                </span>
                                <div className='d-flex justify-content-between align-items-center'>
                                <div className='text-left'>
                                    <p className='font-16 pt-2'>
                                    {nextDonation ? formatDate(nextDonation.nextDonationAt) : null}
                                    </p>
                                </div>
                                <button 
                                    onClick={()=>handleurl('/Donation')}
                                    className='rounded-pill px-4 py-1 primary-blueBTN mt-3 text-center ms-3'
                                    >
                                        {t('Donate')}
                                    </button>
                                </div>
                      
                    </div>  

                    </div>    
                    <div className='col-lg-2 col-md-6 mb-3 col-12  text-white rounded-4 '>
                    <div className="blkcardHeadings mb-4">
                        {t('QUALIFICATION')}
                    </div>
                    <div className="rounded-4 blackCard onecardimg d-flex align-items-center ">
                        <div className="p-4">
                            <img src={`/assets/assetsdashboard/${userStatus}.png`} alt="" />
                       </div>
                      
                    </div>  

                    </div>                    
                    <div className='col-lg-2 col-md-6 mb-3 col-12   text-white rounded-4  toprightCard'>
                    <div className="blkcardHeadings mb-4">
                    {t('DONATIONS RECEIVED')}
                    </div>
                    <div className="rounded-4 blackCard smallImg">
                        <div className="p-4">
                            <img src="/assets/assetsdashboard/card2img.png" alt="" />
                       </div>
                       <p>$ {receivedDonations ? currencyFormatter(receivedDonations, 2) : '0.00'} </p>
                    </div>                    

                    </div>
                    <div className='col-lg-2 col-md-6 mb-3 col-12   text-white rounded-4 '>
                    <div className="blkcardHeadings mb-4">
                    {t('DONATIONS MADE')}
                    </div>
                    <div className="rounded-4 blackCard smallImg">
                        <div className="p-4">
                            <img src="/assets/assetsdashboard/card3img.png" alt="" />
                       </div>
                       <p>$ {madeDonations ? currencyFormatter(madeDonations, 2) : '0.00'}</p>
                    </div>
 
                    </div>
                    <div className='col-lg-2 col-md-6 mb-3 col-12   text-white rounded-4 '>
                    <div className="blkcardHeadings mb-4">
                    {t('COMMUNITY GAINS')}
                                        </div>
                    <div className="rounded-4 blackCard smallImg">
                        <div className="p-4">
                            <img src="/assets/assetsdashboard/card4img.png" alt="" />
                       </div>
                       <p>$ {bonusReceived ? currencyFormatter(bonusReceived, 2) : '0.00'}</p>
                    </div>
 
                    </div>
                    <div className='col-lg-2 col-md-6 mb-3 col-12   text-white rounded-4 '>
                    <div className="blkcardHeadings mb-4">
                    {t('PENDING BONUS')}
                    </div>
                    <div className="rounded-4 blackCard smallImg">
                        <div className="p-4">
                            <img src="/assets/assetsdashboard/card6img.png" alt="" />
                            {/* <img src="/assets/assetsdashboard/card5img.png" alt="" /> */}
                       </div>
                       <p>$ {bonusDonationReceived || bonusReceived ? 
                                bonusDonationReceived > 0 ? 
                                    currencyFormatter(bonusReceived - bonusDonationReceived, 2) : 
                                    bonusReceived > 0 ? currencyFormatter(bonusReceived, 2)  : '0.00' : '0.00'}</p>
                    </div>
 
                    </div>
                    <div className='col-lg-2 col-md-6 mb-3 col-12   text-white rounded-4 '>
                    <div className="blkcardHeadings mb-4">
                    {t('TOTAL WINS')}
                    </div>
                    <div className="rounded-4 blackCard smallImg">
                        <div className="p-4">
                            <img src="/assets/assetsdashboard/card6img.png" alt="" />
                            {/* <img src="/assets/assetsdashboard/card5img.png" alt="" /> */}
                       </div>
                       <p>$ {bonusReceived ? currencyFormatter(bonusReceived + receivedDonations, 2) : 0.00}</p>
                    </div>
 
                    </div>
                    {/* <div className='col-lg-2 col-md-6 mb-3 col-12   text-white rounded-4 '>
                        <div className="blkcardHeadings mb-4">
                        {t('BALANCE AVAILABLE')}
                        </div>
                        <div className="rounded-4 blackCard smallImg">
                            <div className="p-4">
                                <img src="/assets/assetsdashboard/card6img.png" alt="" />
                            </div>
                            <p>$ 0,00</p>
                        </div>

                    </div> */}
                </div>


<div className=''>
                <div className='row'>
                    <div className='col-12 col-lg-4 text-white rounded-4'>
                            <div className='d-lg-flex d-block justify-content-between'>
                            <div className='blackCardLG p-4 w-100 rounded-4 mb-lg-0 mb-3'>
                                <span className='d-flex font-12 mb-2'>
                                    <b>{t('NEXT DONATION AT')}:</b>
                                </span>
                                <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <p className='font-16 pt-2'>
                                    {nextDonation ? formatDate(nextDonation.nextDonationAt) : null}
                                    </p>
                                </div>
                                <button 
                                    onClick={()=>handleurl('/Donation')}
                                    className='rounded-pill px-4 py-1 primary-blueBTN mt-3 text-center ms-3'
                                    >
                                        {t('Donate')}
                                    </button>
                                </div>
                            </div>              
                        </div>                             
                    </div>
                    
                    <div className='col-12 col-lg-8 text-white rounded-4 blackCardLG p-4'>
                    <div className='row px-0'>
                        <div className="col-12 col-lg-12 text-white rounded-4 px-0">                             

                            <div className="invitelinkcard mt-5 px-6 p-3">
                               <span> {t('Referral Link')}</span>
                               <div className="position-relative">          
                                    <div className="flex mt-3">            
                                        <input
                                        readOnly
                                        type="text"
                                        value={`https://app.enjoyunion.club/register/${user?.username}`} id="referralLink" className="text-center rounded-none rounded-s-lg block flex-1 min-w-0 w-full text-sm p-2.5  bg-gray-900 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" />
                                        <span className="inline-flex items-center px-3 text-sm border rounded-e-0 border-e-0 rounded-e-md bg-gray-900 text-gray-400 border-gray-900">
                                        <div 
                                            className="small" 
                                            onClick={handleCopy}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <FontAwesomeIcon icon={faCopy} className="text-gray-400" />
                                        </div>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
</div>       



</div>
</div>
        </div>
    </div>
    {hasBanner ? (
                <ModalBanner 
                    open={openBanner} 
                    setOpenBanner={setOpenBanner} 
                    bannerUrl= {bannerUrl}
                />) : null}
    </div>
  )
}
