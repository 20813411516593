import React , { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function Page() {
  const API_LOCAL_URL = process.env.REACT_APP_AUTH_LOCAL_URL;

  const {t} = useTranslation();
  const [user, setUser] = useState();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))    
  }, []);


  const [selectedIcon, setSelectedIcon] = useState('false');
  const location = useLocation();

  // let icons = [
  //         { path:'/dashboard' ,name : 'Dashboard', id:1,image:'/assets/sidebar/icon1.png' },
          
  //         { path:'/donation' ,name : 'Donation', id:2,image:'/assets/sidebar/icon2.png' },
  //         { path:'/statement' ,name : 'Statement', id:3,image:'/assets/sidebar/icon3.png' },
  //         { path:'/community' ,name : 'My community', id:4,image:'/assets/sidebar/icon4.png' },
  //         // { path:'/Financial' ,name : 'Financial data', id:5,image:'/assets/sidebar/icon5.png' },
  //         { path:'/financialPage' ,name : 'Financial data', id:5,image:'/assets/sidebar/icon5.png' },
  //         { path:'/localCrypto' ,name : 'Local Crypto', id:6,image:'/assets/sidebar/icon6.png' },
  //         { path:'/telegram' ,name : 'Telegram', id:7,image:'/assets/sidebar/icon7.png' },
  //         // { name : 'Dashboard', id:8,image:'/assets/sidebar/icon7.png' },
  // ]

  let icons = [
    { path:'/dashboard' ,name : 'Dashboard', id:1,image:'/assets/sidebar/icon1.png' },
    { path:'/community' ,name : 'My community', id:2,image:'/assets/sidebar/icon4.png' },
    { path:'/donation' ,name : 'Donation', id:3,image:'/assets/sidebar/icon2.png' },
    { path:'/donation/donationRequest' ,name : 'RECEIVE', id:4,image:'/assets/sidebar/icon8.png' },
    { path:'/statement' ,name : 'Statement', id:5,image:'/assets/sidebar/icon3.png' },
    { path:'/profile' ,name : 'My Profile', id:6,image:'/assets/sidebar/icon5.png' },
    { path:'/localCrypto' ,name : 'Local Crypto', id:7,image:'/assets/sidebar/icon6-v2.png' },
    { path:'/p2pon' ,name : 'P2P On', id:9,image:'/assets/sidebar/icon9-v2.png' },
]

  const handleIcon = (iconid , path) => {
    {iconid === selectedIcon ? setSelectedIcon(0) : setSelectedIcon(iconid)}
    navigateTo(path)
  };

  const navigate = useNavigate();
  const navigateTo = (link) => {
          navigate(link);
  }

  useEffect(() => {
    navigateTo(location.pathname)
  }, [])


  return (
    <div className="min-h-screen side-adjust sidebar-box d-lg-flex">
      <div className="sidebar pb-100">
          <div className="d-flex flex-column gap-5 align-items-center justify-content-center">
          <div className='d-flex w-full justify-content-center mt-5'>
          <img 
          className="sidebar-logo"
        src='/assets/images/logo.png'
        alt='logo image'
        />
        </div>
        <div>
    
    
        {icons.map((item) => (
          item.id !== 7 && item.id !== 9 ?
          <div
            key={item.id}
            className={`everyIcon sts gap-1 w-100 ${selectedIcon === item.id ? 'selected' : ''}`}
            onClick={() => handleIcon(item.id , item.path)}
          >
            <img className="dashboard-icon" src={item.image} alt={item.name} />
            {selectedIcon === item.id ? <p className="font-14 text-white capitalize">{t(item.name)}</p> : null}
          </div>
          :
          item.id === 7 ? 
          <a href={`${API_LOCAL_URL}/login?refreshToken=${user?.refreshToken}`} target="_blank">
            <div
              key={item.id}
              className={`everyIcon sts gap-1 w-100 ${selectedIcon === item.id ? 'selected' : ''}`}            
            >
              <img className="dashboard-icon" src={item.image} alt={item.name} />
              {selectedIcon === item.id ? <p className="font-14 text-white capitalize">{t(item.name)}</p> : null}            
            </div>
          </a>
          :
          <a href='https://p2pon.online' target="_blank">
          <div
            key={item.id}
            className={`everyIcon sts gap-1 w-100 ${selectedIcon === item.id ? 'selected' : ''}`}            
          >
            <img className="dashboard-icon" src={item.image} alt={item.name} />
            {selectedIcon === item.id ? <p className="font-14 text-white capitalize">{t(item.name)}</p> : null}            
          </div>
        </a>
      ))}   
          
      </div>            
          </div>
      </div>
    </div>
  );
}
