import React from 'react'
import { Route, Routes } from 'react-router-dom';

import Registration from '../auth/cadastro/page';
import Reset from '../auth/cadastro/Reset';
import Login from '../auth/login/page'


export default function LoginRoutes() {
  return (
    <div>
      <Routes>
        <Route path="/" exact element={<Login /> } />
        <Route path="/login" exact element={<Login /> } />
        <Route path="/register" exact element={<Registration /> } />
        <Route path="/register/:reference" exact element={<Registration /> } />
        <Route path="/login/passwordRecovery" exact element={<Reset /> } />
        <Route path="/login/passwordRecovery/:userIdParam/:recoveryCodeParam" exact element={<Reset /> } />
        <Route path="*" element={<Login /> } />
      </Routes>
    </div>
  )
}
