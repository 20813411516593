import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
// import GoogleLoginButton from './googleLogin/page';
// import FacebookLoginButton from './googleLogin/fblogin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { t } from 'i18next'
import { useDispatch, useSelector } from "react-redux";
import { login, loginAuth, loginRefreshToken } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import GoogleLoginButton from "./googleLogin/page";

export default function Page() {
  const MySwal = withReactContent(Swal)
  const newAlert = (title, message, icon) => {
      MySwal.fire({
          title: '<strong>' + title + '</strong>',
          html: '<i>' + message + '</i>',
          icon
        });
  };

  const isValidJWT = (token) => {
    const parts = token.split('.');
    return parts.length === 3;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refreshToken = urlParams.get('refreshToken');

    if (refreshToken) {
      if (isValidJWT(refreshToken)) {
        handleRefreshTokenLogin(refreshToken);
      } 
    }
  }, []);


  const handleRefreshTokenLogin = ((refreshToken) => {  
    setLoading(true); 
    dispatch(loginRefreshToken({ refreshToken }))
      .unwrap()
      .then(() => {        
          navigate("/dashboard");
      })
      .catch((error) => {
          if (error === '406') {
              setAuthorize(true);
              dispatch(clearMessage());
          }
          newAlert(t('Oops!'), t(error), 'error');  
          dispatch(clearMessage());
          setLoading(false);
      });
    });

  const gClientId = process.env.REACT_APP_GOOGLE_ID;
  const navigate = useNavigate();
  const [userLogin, setUserLogin] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [authorize, setAuthorize] = useState(false);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const initialValues = {
    userLogin: "",
    password: "",
    mfaCode: ""
};

const validationSchema = Yup.object().shape({
    userLogin: Yup.string()
        .required(t('This field is required!'))
        .matches(/^[a-zA-Z0-9-_@.]+$/,
        t('Only letters, numbers, periods (.), hyphens (-), underscore (_) and @ are accepted.') ),
    password: Yup.string().required(t('The password is required.')),
});

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
      if (!isAuthenticated) {
        navigate('/login');
      }
    };
    checkAuthentication();
  }, []); // Call checkAuthentication only once when the component mounts

  const gotoRegister = () => {
    navigate('/register/EnjoyUnion');
  };

  const gotoReset = () => {
    navigate('/login/passwordRecovery');
  };

  const handleLogin = ((formValue) => {  
    const { userLogin, password } = formValue;
    setLoading(true); 
    dispatch(login({ userLogin, password }))
      .unwrap()
      .then(() => {        
          navigate("/dashboard");
      })
      .catch((error) => {
          if (error === '406') {
              setAuthorize(true);
              dispatch(clearMessage());
          }
          newAlert(t('Oops!'), t(error), 'error');  
          dispatch(clearMessage());
          setLoading(false);
      });
    });

    const handleGoogleLogin = ((user) => {  
      const { email, accessToken } = user;
      setLoading(true); 
      dispatch(loginAuth({ email, accessToken }))
        .unwrap()
        .then(() => {        
            navigate("/dashboard");
        })
        .catch((error) => {
            if (error === '406') {
                setAuthorize(true);
                dispatch(clearMessage());
            }
            newAlert(t('Oops!'), t(error), 'error');  
            dispatch(clearMessage());
            setLoading(false);
        });
      });

  return (
    <div className="d-flex align-items-center vh-100-custom w-100 justify-content-center">
      <div className="rounded cad-card login-width px-lg-0 px-3">
        <div className=" text-center">
          <div className="d-lg-flex d-md-block d-block">
            <div className="col-lg-4 col-md-4 col-12">
              <div className="d-flex flex-column justify-content-center align-items-center br-white position-relative px-4 py-5">
                <div className="position-absolute abs-logo rounded-pill d-lg-flex  align-items-center justify-content-center w-full">
                  <img src="/assets/images/logo1.png" alt="logo image" />
                </div>
                <div className="d-flex w-full justify-content-center mt-5">
                  <img src="/assets/images/logo.png" alt="logo image" />
                </div>
                <p className="font-36 text-white mt-5 mb-4">{t('WELCOME')}</p>                                
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-12">
              <div className="text-center">
                <p className="font-36 text-white my-5" >{t('LOG INTO YOUR ACCONT')}</p>

                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                    >
                      <Form className="row mx-3">
                        <Field placeholder={t('USER')} name="userLogin" autoComplete="true" className="inputWidth border-0 gray-pill py-3 rounded-pill my-3 text-white" />
                        <Field placeholder={t('PASSWORD')} name="password" autoComplete="true"  type="password" className="border-0 inputWidth gray-pill py-3 rounded-pill my-3 text-white" />
                        <button
                          type="submit"
                          disabled={loading}
                          className="rounded-3 font-22 bg-green my-4 px-5 py-3 border-0 ">{t('Enter')}</button>                                                                                              
                    </Form>
                  </Formik>
                  <button className="font-15 text-white border-0 bg-transparent mb-4" onClick={gotoReset}>{t('Forgot your password?')}</button>                    
                  <GoogleLoginButton loginSuccess={handleGoogleLogin}  />
                    
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
