import axios from "axios";
import { useTranslation } from "react-i18next";

import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_BASE_API_URL;



const usdtGetPrice = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get('https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/0x34cB446e56386DA32E0447B84AE09487A0011368')      
      .then((response) => {
        if (response.data.data.attributes.token_prices) {
          resolve(response.data.data.attributes.token_prices["0x34cB446e56386DA32E0447B84AE09487A0011368"]);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          reject(new Error('Session expired! Please log in again.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });
};

const listAvailablePackages = async (userCode) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + 'packages/active', 
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const createPackage = async (userId, packageId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + 'userPackages/create',
      {userId, packageId} ,
      { headers: authHeader() })
      .then((response) => { 
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getReceivers = async (donationTransactionId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donationTransaction/donationList/${userId}/${donationTransactionId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getDonors = async (donationRequestId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donationRequest/donationList/${donationRequestId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getReceivedDonations = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/balance/received/${userId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getMadeDonations = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/balance/made/${userId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getBonusReceived = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/balance/bonus/${userId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getBonusDonationReceived = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/balance/bonusReceived/${userId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getDonationTransaction = async (donationTransactionId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donationTransaction/${donationTransactionId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getUserPackageList = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `userPackages/${userId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error('WalletId not found.'));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getUserNextDonation = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `userPackages/nextDonation/${userId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error('WalletId not found.'));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getUserDonationRequest = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donationRequest/${userId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.code && error.code === 'ERR_NETWORK') {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(error);          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getDonationTransactionList = async (userPackageId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donationTransaction/transactions/${userPackageId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getReceivedDonationsList = async (userPackageId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/received/${userPackageId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getMadeDonationsList = async (userPackageId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/made/${userPackageId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const getBonusReceivedList = async (userPackageId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/bonus/${userPackageId}`,
      { headers: authHeader() })
      .then((response) => {    
          resolve(response.data); 
      })
      .catch((error) => {
        if (error.response.status === 401) {
            reject(new Error('Unauthorized! Please login again.'));
        } else if (error.response.status === 403) {
            reject(new Error('Invalid MFA code.'));
        } else if (error.response.status === 406) {
            reject(new Error(error.response.status));
        } else if (error.response.status === 422) {
            reject(new Error(error.response.data.fields[0].message));          
        } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const sendTxn = async (donationTransactionId, donationContributionId, transactionAddress) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `donations/updateDonation/${donationTransactionId}/${donationContributionId}/${transactionAddress}`,
      { headers: authHeader() })
    .then((response) => {    
        resolve(response.data); 
    })
    .catch((error) => {
      if (error.response.status === 401) {
          reject(new Error('Unauthorized! Please login again.'));
      } else if (error.response.status === 403) {
          reject(new Error('Invalid MFA code.'));
      } else if (error.response.status === 406) {
          reject(new Error(error.response.status));
      } else if (error.response.status === 422) {
          reject(new Error(error.response.data.fields ?error.response.data.fields[0].message : error.response.data.title));          
      } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
      }
      reject(new Error('Connection error. Please try again in a few moments.'));
    });
});  
};




const paymentService = {
    usdtGetPrice,
    listAvailablePackages,
    createPackage,
    getReceivers,
    getDonors,
    getDonationTransaction,
    getUserPackageList,
    getUserNextDonation,
    getDonationTransactionList,
    sendTxn,
    getReceivedDonations,
    getReceivedDonationsList,
    getMadeDonations,
    getMadeDonationsList,
    getBonusReceivedList,
    getBonusReceived,
    getBonusDonationReceived,
    getUserDonationRequest,
  };

export default paymentService;