import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { t } from 'i18next'
import membersService from '../../services/members.service';
import { useTranslation } from 'react-i18next';


export default function Reset() {
  const {userIdParam, recoveryCodeParam } = useParams();
  const { i18n } = useTranslation();
  const MySwal = withReactContent(Swal)
  const newAlert = (title, message, icon) => {
      MySwal.fire({
          title: '<strong>' + title + '</strong>',
          html: '<i>' + message + '</i>',
          icon
        });
  };
  const navigate = useNavigate();
  const [search, setSearch] = useState('')
  const gotoLogin = () => {
    navigate('/');
  }

  const [ loading, setLoading ] = useState(false);
  const [userId, setUserId ] = useState('');
  const [recoveryCode, setRecoveryCode ] = useState('');
  const [password, setPassword ] = useState('');
  const [passwordConfirm, setPasswordConfirm ] = useState('');

  useEffect(() => {
    setUserId(userIdParam);
    setRecoveryCode(recoveryCodeParam);
  }, [userIdParam, recoveryCodeParam])

  const language = i18n.language;

  const handleChange = (value) => {
    setSearch(value.target.value)
  }

  const handleChangeRecoveryCode = (value) => {
    setRecoveryCode(value.target.value)
  }

  const handleChangePassword = (value) => {
    setPassword(value.target.value)
  }

  const handleChangePasswordConfirm = (value) => {
    setPasswordConfirm(value.target.value)
  }

  const resetForm = () => {
    setSearch('');
    setRecoveryCode('');
    setPassword('');
    setPasswordConfirm('');
  }

  const handlePasswordRecovery = () => {  
    setLoading(true);
    membersService.passwordRecovery(search, language?.slice(0, 2)).then(
      (response) => {
          setUserId(response.userId);
          setLoading(false);
          setSearch('');
          newAlert(t('Success!'),  t('Password recovery link sent to the registered email!') +' <br /> ' +
                    t('(If you don\'t find the email, please check your Spam/Junk folder.)'), 'success');                 
      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();   
          setSearch('');
          setLoading(false);
          newAlert(t('Error!'), t(error.message) , 'error');             
      }
    )
  };

  const handlePasswordRecoveryUpdate = () => {  
    if (!recoveryCode || !password) {
      return newAlert(t('Error!'),  t('All fields are required!'), 'error');
    }
    if (!password || password.length < 6 || password.length > 20) {
      return newAlert(t('Error!'),  t('The password must be between 6 and 20 characters.'), 'error');
    }
    if (password !== passwordConfirm) {
      return newAlert(t('Error!'),  t('Las contraseñas no coinciden.'), 'error');
    }
    setLoading(true);
    membersService.passwordRecoveryUpdate(userId, recoveryCode, password).then(
      (response) => {
          setLoading(false);
          resetForm();
          newAlert(t('Success!'),  t('Password updated successfully!'), 'success');
          gotoLogin()

      },
      (error) => {
          const _content =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();  
          setLoading(false);
          resetForm(); 
          newAlert(t('Error!'), t(error.message) , 'error');   
          
      }
    )
  };

  return (
    <div className='d-flex align-items-center vh-100 w-100'>
      <div className='col-lg-12 w-100'>
        <div className='rounded-5 cad-card py-5 position-relative'>
          <button 
          onClick={()=>gotoLogin()}
          className='reset-back-btn'
          >
            X
          </button>
          
          {!userId ? (
            <form>
              <p className='font-48 text-white'>{t('Reset your password')}</p>
              <p className='font-18 text-white mx-3'>{t('Please provide your registered email to send the password reset instructions.')}</p>
              <div className='d-flex flex-column justify-content-center align-items-center mx-3'>
                <input  autoComplete="off" placeholder={t('EMAIL')} value={search} onChange={handleChange} className='gray-pill py-2 rounded-pill my-3 border-0 text-white w-full ' />
                <button type="submit" disabled={loading} onClick={handlePasswordRecovery} className='rounded-3 border-0 bg-green my-4 px-5 py-2'>{loading ? t('Processing...') : t('SEND')}</button>
              </div>
            </form>
            ) : (
              <form>
                <p className='font-48 text-white'>{t('Reset your password')}</p>
                <div className='d-flex flex-column justify-content-center align-items-center mx-3'>
                  <input autoComplete="off"  name="recoveryCode" disabled={recoveryCodeParam}  placeholder={t('Recovery Code')} value={recoveryCode} onChange={handleChangeRecoveryCode} className='gray-pill py-2 rounded-pill my-3 border-0 text-white w-full ' />
                  <input autoComplete="off" name="password" type="password" placeholder={t('New Password')} value={password} onChange={handleChangePassword} className='gray-pill py-2 rounded-pill my-3 border-0 text-white w-full ' />
                  <input autoComplete="off" name="passwordConfirm" type="password" placeholder={t('Confirm New Password')} value={passwordConfirm} onChange={handleChangePasswordConfirm} className='gray-pill py-2 rounded-pill my-3 border-0 text-white w-full ' />
                  <button type="submit" disabled={loading} onClick={handlePasswordRecoveryUpdate} className='rounded-3 border-0 bg-green my-4 px-5 py-2'>{loading ? t('Processing...') : t('SEND')}</button>
                </div>
              </form>   
            )}  
                        
        </div>
      </div>
    </div>
  )
}
