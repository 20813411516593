import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { logout } from '../../slices/auth';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import paymentService from '../../services/payment.service';

export default function DonationRequest() {
  const MySwal = withReactContent(Swal);
  const goToProfileAlert = (title, message, icon) => {
    MySwal.fire({
        title: '<strong>' + title + '</strong>',
        html: '<i>' + message + '</i>',
        icon
    }).then((result) => {
        if (result.isConfirmed) {
            navigate('/profile'); 
        }
    });
};
const newAlert = (title, message, icon) => {
  MySwal.fire({
      title: '<strong>' + title + '</strong>',
      html: '<i>' + message + '</i>',
      icon
      });
};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatDate = (date) => DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);  
  const [currentIndex, setCurrentIndex] = useState(0)
  const [donationRequestList, setDonationRequestList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [user, setUser] = useState();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))    
  }, []);
  
  useEffect(() => {
    if (user) {
      paymentService.getUserDonationRequest(user.userId).then(
        (response) => {
            setDonationRequestList(response)
        },
        (error) => {
            const _content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            console.log(error.response)
            if (error.response && error.response.status === 422) {
              goToProfileAlert(t('Error!'), t('Please fill in your ENUN wallet address.'), 'error');
            }       
            if (error.toString() === 'Error: Unauthorized! Please login again.') {
              newAlert(t('Error!'), t('Unauthorized! Please login again.'), 'error');
              dispatch(logout());
              }
        }
        );  
    }     
  }, [user]);  

  

  const goToDonateTransactions = (userPackageId) => {
    navigate(`/donation/donationRequest/${userPackageId}`);
  };  

  const gotoDonations = () => {
    navigate(`/dashboard`);
  };  
  
  const currencyFormatter = (value, digits) => {
    if (!Number(value)) return '';    
    const amount = (value ).toFixed(digits);    
    const formattedAmount = amount.replace(',', '.');
    
    return `${formattedAmount}`;
};
  return (
    <div>

      
      <div className='cad-card px-3 rounded-3  h-100'>        
        {/* --- Start Table --- */}
        <div className='mt-5'>

          <div className='blackCardLG rounded-4 tableOverflow px-2'>
            <p className='font64 text-white mb-4'>{t('Donations Received')}</p>
            <table className="table w-100">
              <thead>
                <tr  >
                  <th scope="col">{t('DATE/TIME')}</th>
                  <th scope="col">{t('AMOUNT USDT')}</th>                                   
                  <th scope="col">{t('DETAILS')}</th>
                  <th scope="col">{t('STATUS')}</th>
                </tr>
              </thead>
              <tbody>
                {donationRequestList?.map((data, index) => (
                  <tr key={index}>
                    <td>{formatDate(data.createdAt)}</td>
                    <td>{currencyFormatter(data.donationAmount, 2)}</td>                                     
                    <td>
                      <button onClick={() => goToDonateTransactions(data.donationRequestId)} className='donateBTN ' >{t('DETAILS')}</button>
                    </td>
                    <td ><p className={
                      data.status === 'COMPLETED' ? 'CompletedStatus mb-0' :
                        data.status === 'PARTIAL' ? 'PartialStatus mb-0' :
                          data.status === 'PENDING' ? 'PendingStatus mb-0' :
                            ''}>
                              
                              {data.status === 'PROCESSED' ? t('COMPLETED') :
                        data.status === 'PROCESSING' ? t('PARTLY RECEIVED') :
                          data.status === 'PENDING' ? t('AWAITING DONOR') : t(data.status)}</p></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='d-flex justify-content-center align-items-center flex-column mb-3'>  
        <button className="font-24 mt-5 text-white disabledButton rounded-pill px-5 border-0 py-2" onClick={gotoDonations}     >{t('BACK')}</button>             
      </div>      
        </div>
        {/* --- End Table --- */}
      </div>
    </div>
  )
}
