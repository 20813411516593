import React , { useEffect, useState } from "react";
import { t } from "i18next";
import { useTranslation } from 'react-i18next';

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../slices/language";

export default function Page2({ onButtonClick }) {
const API_LOCAL_URL = process.env.REACT_APP_AUTH_LOCAL_URL;

// language translator
const { i18n } = useTranslation();
const dispatch = useDispatch();

const [user, setUser] = useState();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))    
  }, []);

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};
const [ flag, setFlag ] = useState();
const [ showflag,setShowFlag ] = useState('false');

const openFlag = () => {
  showflag === true ? setShowFlag(false) : setShowFlag(true)
}
const selectFlag = (num , lang) =>{
  changeLanguage(lang);
  setFlag(num);
  setShowFlag(false);
}

let flags = useSelector(state => state.languages)  
const currentLanguage = useSelector(state => state.language);

useEffect(() => {
  const currentFlag = flags.find(flag => currentLanguage.startsWith(flag.lang));
  if (currentFlag) {
    selectFlag(currentFlag.src, currentLanguage);
  } else {
    selectFlag('/assets/flags/flag2.png', 'en');
  }    
}, [currentLanguage])


  const handleClick = () => {
    onButtonClick(false); // Pass true to parent component when button is clicked
  };

        const [selectedIcon, setSelectedIcon] = useState('false');
     
  //       let icons = [
  //         { path:'/dashboard' ,name : 'Dashboard', id:1,image:'/assets/sidebar/icon1.png' },
                
  //         // { path:'/donation' ,name : 'Donation', id:2,image:'/assets/sidebar/icon2.png' },
  //         // { path:'/statement' ,name : 'Statement', id:3,image:'/assets/sidebar/icon3.png' },
  //         { path:'/community' ,name : 'My community', id:4,image:'/assets/sidebar/icon4.png' },
  //         // { path:'/financialPage' ,name : 'Financial data', id:5,image:'/assets/sidebar/icon5.png' },
  //         // { path:'/localCrypto' ,name : 'Local Crypto', id:6,image:'/assets/sidebar/icon6.png' },
  //         // { path:'/telegram' ,name : 'Telegram', id:7,image:'/assets/sidebar/icon7.png' },
  // ]
        let icons = [
          { path:'/dashboard' ,name : 'Dashboard', id:1,image:'/assets/sidebar/icon1.png' },
          { path:'/community' ,name : 'My community', id:2,image:'/assets/sidebar/icon4.png' },
          { path:'/donation' ,name : 'Donation', id:3,image:'/assets/sidebar/icon2.png' },
          { path:'/donation/donationRequest' ,name : 'RECEIVE', id:4,image:'/assets/sidebar/icon8.png' },
          { path:'/statement' ,name : 'Statement', id:5,image:'/assets/sidebar/icon3.png' },
          { path:'/profile' ,name : 'My Profile', id:6,image:'/assets/sidebar/icon5.png' },
          { path:'/localCrypto' ,name : 'Local Crypto', id:7,image:'/assets/sidebar/icon6-v2.png' },
          { path:'/p2pon' ,name : 'P2P On', id:9,image:'/assets/sidebar/icon9-v2.png' },
      ]

        const handleIcon = (iconid , path) => {
          {iconid === selectedIcon ? setSelectedIcon(0) : setSelectedIcon(iconid)}
          navigateTo(path);
          handleClick();
        };

        const navigate = useNavigate();
        const navigateTo = (link) => {
                navigate(link);
        }


  return (
    <div className="min-h-screen side-adjust d-lg-flex sidebar2 animate__animated animate__fadeInLeft">
      
      <div className="sidebarx px-5 pb-100">
          <div className="d-flex flex-column gap-5 align-items-center justify-content-center">
          <div className='d-flex w-100 align-items-center justify-content-between mt-5'>
            
          <img 
          className="sidebar-logo"
        src='/assets/images/logo.png'
        alt='logo image'
        />
        <button className="fs-2 text-white border-0 bg-transparent" onClick={handleClick}>X</button>
        </div>
        <div className="d-flex flex-column justify-content-start w-100">
    
    
        {icons.map((item) => (
          item.id !== 7 && item.id !== 9 ?
          <div
            key={item.id}
            className={`everyIcon d-flex align-items-center gap-2 w-100 ${selectedIcon === item.id ? 'selected' : ''}`}
            onClick={() => handleIcon(item.id , item.path)}
          >
            <img className="dashboard-icon" src={item.image} alt={t(item.name)} />
            <p className="font-14 text-white capitalize">{t(item.name)}</p> 
          </div>
          : 
          item.id === 7 ?
          <a style={{textDecoration:"none"}} href={`${API_LOCAL_URL}/login?refreshToken=${user?.refreshToken}`} target="_blank">
          <div
            key={item.id}
            className={`everyIcon d-flex align-items-center gap-2 w-100 ${selectedIcon === item.id ? 'selected' : ''}`}          
          >            <img className="dashboard-icon" src={item.image} alt={t(item.name)} />
            <p className="font-10 text-white capitalize">{t(item.name)}</p> 
          </div>
          </a>
          :
          <a style={{textDecoration:"none"}} href='https://p2pon.online' target="_blank">
          <div
            key={item.id}
            className={`everyIcon d-flex align-items-center gap-2 w-100 ${selectedIcon === item.id ? 'selected' : ''}`}          
          >            <img className="dashboard-icon" src={item.image} alt={t(item.name)} />
            <p className="font-10 text-white capitalize">{t(item.name)}</p> 
          </div>
          </a>

      ))}
      

      <div className="everyIcon d-flex flex-column gap-2 justify-content-center gap-1 w-100 selected-flag position-relative"
         onClick={()=>openFlag()}
       >
        <div className="d-flex align-items-center gap-2">

        <img
                

          src={flag}
          alt="eps" className="d-lg-block flag-desk"
          />
          <p className="font-14 text-white capitalize">{t('language')}</p>
        </div>
                    {showflag === true ? (
  <div className="flag-grid2 animate__animated animate__fadeInUp">
    {flags.map(f => (
      <div
      key={f.id} 
      onClick={()=> dispatch(setLanguage(f.lang))}
      className="single-flag d-flex align-items-center mb-2 gap-2"
      >
        <div style={{backgroundImage: `url(${f.src})`}} className="mob-flag"></div>
        <p className="font-16 text-white">{f.name}</p>
        
      </div>
    ))}
  </div>
) : null}

        </div>

          </div>
    
    
    

          </div>
      </div>
    </div>
  );
}
