// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCxtopj6abJSNttSpyto5P2jfUX4KUBRHY",
  authDomain: "auth.enjoyunion.club",
  projectId: "enjoyunion-303e2",
  storageBucket: "enjoyunion-303e2.appspot.com",
  messagingSenderId: "239639450293",
  appId: "1:239639450293:web:416b45b388159aa97ea4fc",
  measurementId: "G-H8TFTBEGYZ"
};
// authDomain: "enjoyunion-303e2.firebaseapp.com",

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);