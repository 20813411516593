import React, { useEffect} from 'react';
import './App.css';
import './css/app.css';
import './css/custom.css';
import UserRoutes from './Routes/user';
import LoginRoutes from './Routes/login';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLanguage } from './slices/language';

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch()  ;
  const { isLoggedIn } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(setLanguage(i18n.language))
  }, []);
  

  return (
    <div className="App">
      <div className='main-wrapper p-lg-0 p-2 '>
        {/* Conditionally render Login or Dashboard based on isLoggedIn state */}                  
          {isLoggedIn ? (
            <UserRoutes />          
          ) : (
            <LoginRoutes/>
          )}       
      </div>
    </div>
  );
}

export default App;
