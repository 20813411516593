import axios from "axios";
import jwtDecode from "jwt-decode";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_AUTH_API_URL;
const USER_REGISTER_URL = API_URL + '/register';
const CORE_AUTH_URL = API_URL + '/login';
const CORE_AUTH_RECOVERY_URL = API_URL + '/forgot';


const register = (referredBy, username, name, lastname, email, whatsapp, country, password) => {
  return new Promise((resolve, reject) => {
    axios.post(USER_REGISTER_URL + '/' + referredBy, {       
      username, 
      password, 
      email, 
      name, 
      lastname, 
      whatsapp,
      country,
      type: 'USER'
    })
    .then((response) => {
      if (response.data.accessToken) {            
        const user = {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            username: response.data.username,
            userId: response.data.userId,
            referredBy: response.data.referredBy,            
            type: response.data.type,
        }
        localStorage.setItem("token", JSON.stringify(user));
        localStorage.setItem("isLoggedIn", true);
        resolve(user);                   
      } else {
        reject('An unexpected error has occurred. Please try again.');
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        reject(new Error('Incorrect username and/or password.'));
      }else if (error.response.status === 422) {        
        reject(new Error(error.response.data.title));
      }else if (error.response.status >= 500) {
        reject(new Error('An unexpected error has occurred. Please try again.'));
      }
      reject(new Error(error.response.data));
    });
  })};

const login = (username, password) => {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return new Promise((resolve, reject) => {
      axios
        .post(CORE_AUTH_URL, 
         { username,
          password})
        .then((response) => {
          if (response.data.accessToken) {            
            const user = {
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
              username: response.data.username,
              userId: response.data.userId,
              referredBy: response.data.referredBy,            
              type: response.data.type,
            }
            localStorage.setItem("user", JSON.stringify(user));
            resolve(user); 
          } else {
            reject('An unexpected error has occurred. Please try again.');
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            reject(new Error('Incorrect username and/or password.'));            
          } else if (error.response.status === 403) {
            reject(new Error('Invalid 2FA code.'));
          }else if (error.response.status === 406) {
            reject(new Error('User with active 2FA.'));
          } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
          }
          reject(new Error('Connection error. Please try again in a few moments.'));
        });
    });  
};

const loginAuth = (email, idToken) => {

  return new Promise((resolve, reject) => {
    axios
      .post(CORE_AUTH_URL + '-auth', 
       { email,
        idToken})
      .then((response) => {
        if (response.data.accessToken) {            
          const user = {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            username: response.data.username,
            userId: response.data.userId,
            referredBy: response.data.referredBy,            
            type: response.data.type,
          }
          localStorage.setItem("user", JSON.stringify(user));
          resolve(user); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          reject(new Error('Incorrect username and/or password.'));            
        } else if (error.response.status === 403) {
          reject(new Error('Invalid 2FA code.'));
        }else if (error.response.status === 406) {
          reject(new Error('User with active 2FA.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};

const loginRefreshToken = (refreshToken) => {

  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/token/refresh?refreshToken=${refreshToken}`)
      .then((response) => {
        if (response.data.accessToken) {            
          const user = {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            username: response.data.username,
            userId: response.data.userId,
            referredBy: response.data.referredBy,            
            type: response.data.type,
          }
          localStorage.setItem("user", JSON.stringify(user));
          resolve(user); 
        } else {
          reject('An unexpected error has occurred. Please try again.');
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          reject(new Error('Incorrect username and/or password.'));            
        } else if (error.response.status === 403) {
          reject(new Error('Invalid 2FA code.'));
        }else if (error.response.status === 406) {
          reject(new Error('User with active 2FA.'));
        } else if (error.response.status >= 500) {
          reject(new Error('An unexpected error has occurred. Please try again.'));
        }
        reject(new Error('Connection error. Please try again in a few moments.'));
      });
  });  
};


const passwordRecovery = (search) => {  
  return new Promise((resolve, reject) => {
    axios
        .post(CORE_AUTH_RECOVERY_URL + 'login/password/recovery/', {
          search,          
         })
        .then((response) => {
          if (response.data.recoveryResponse) {                        
            resolve(response);
          } else {
            reject('An unexpected error has occurred. Please try again.');
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            reject(new Error('Incorrect username and/or password.'));            
          } else if (error.response.status === 404) {
            reject(new Error('User/Email not found!'));
          }else if (error.response.status === 406) {
            reject(new Error(error.response.status));
          } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
          }
          reject(new Error('Connection error. Please try again in a few moments.'));
        });
    });  
};

const passwordRecoveryUpdate = (password, userCode, recoveryCode) => {  
  return new Promise((resolve, reject) => {
    axios
        .post(CORE_AUTH_RECOVERY_URL + 'login/password/recovery/' + userCode + '/' + recoveryCode, {
          password        
         })
        .then((response) => {
          if (response.data.recoveryResponse) {                        
            resolve(response);
          } else {
            reject('An unexpected error has occurred. Please try again.');
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            reject(new Error('Incorrect username and/or password.'));            
          } else if (error.response.status === 404) {
            reject(new Error('Recovery Link Expired!'));
          }else if (error.response.status === 406) {
            reject(new Error(error.response.status));
          } else if (error.response.status >= 500) {
            reject(new Error('An unexpected error has occurred. Please try again.'));
          }
          reject(new Error('Connection error. Please try again in a few moments.'));
        });
    });  
};

const logout = () => {    
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {  
      const headers = authHeader();
      localStorage.removeItem("user");
    // return axios
    //     .post(CORE_AUTH_URL + "logout", 
    //     {refreshToken: token.refreshToken}, 
    //     {headers} ) 
    };       
};

const decodeJwt = (jwt) => {
    try {
        const decodedJwt = jwtDecode(jwt);
        const user = {
            username: decodedJwt.sub,
            userCode: decodedJwt.uc,
            email: decodedJwt.email,
        }
        return  user;
      } catch (e) {
        return null;
      }
}

const authService = {
    register,
    login,
    loginAuth,
    logout,
    passwordRecovery,
    passwordRecoveryUpdate,
    loginRefreshToken,
  };

export default authService;